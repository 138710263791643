$themes: (light: (ui-modal-bg-color: #ffffff,
        ui-modal-close-icon-color: #1d2333,
        ui-modal-title-color: #070d17,
        ui-modal-body-text-color: #070d17,
        bulletin-modal-bg-color: #eceff1,
    ),
    dark: (ui-modal-bg-color: #1d2333,
        ui-modal-close-icon-color: #ffffff,
        ui-modal-title-color: #ffffff,
        ui-modal-body-text-color: #ffffff,
        bulletin-modal-bg-color: #191d29,
    ),
);

@import "./../../assets/css/base/theme";

$modalPadding: 16px;
$modalBorderRadios: 10px;
$modalBodyMargin: 8px 0 30px;
$modalShadow: 0 0 9px rgba(0, 0, 0, 0.3);

.ui-select-size {
    &.sm {
        left: calc(50% - 350px / 2) !important;
        width: 350px !important;
    }

    &.md {
        left: calc(50% - 500px / 2) !important;
        width: 500px !important;
    }

    &.lg {
        left: calc(50% - 700px / 2) !important;
        width: 700px !important;
    }

    &.xl {
        left: calc(50% - 900px / 2) !important;
        width: 900px !important;
    }

    &.xxl {
        left: calc(50% - 1200px / 2) !important;
        width: 1200px !important;
    }

    &.full {
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
    }

}

.ui-modal-root {
    .ui-modal-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .ui-modal-scroll {
        overflow: auto;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;

        .ui-modal {
            position: absolute;
            top: 20%;
            z-index: 1000000;
            padding: $modalPadding;
            border-radius: $modalBorderRadios;
            opacity: 0;
            box-shadow: $modalShadow;

            @include themify() {
                background-color: themed("ui-modal-bg-color");
            }

            &.ui-modal-open {
                opacity: 1;
                animation: ui-modal-open 0.3s !important;
            }

            .ui-modal-header {
                display: flex;
                justify-content: space-between;

                &.draggable {
                    cursor: move;
                }

                .ui-modal-icon {
                    font-size: 20px;
                    display: flex;
                    color: #ff5722;
                }

                .ui-modal-close-icon {
                    cursor: pointer;

                    @include themify() {
                        color: themed("ui-modal-close-icon-color");
                    }
                }

                .ui-modal-title {
                    text-align: right;
                    font-size: 16px;
                    width: 100%;
                    padding: 0 8px;

                    @include themify() {
                        color: themed("ui-modal-title-color");
                    }
                }
            }

            .ui-modal-body {
                margin: $modalBodyMargin;

                .text {
                    @include themify() {
                        color: themed("ui-modal-body-text-color");
                    }
                }
            }

            .ui-modal-footer {
                text-align: center;
            }

            &.no-padding {
                padding: 0;

                .ui-modal-body {
                    margin: 0;
                }
            }

            .scroll {
                @include themify() {
                    background-color: themed("bulletin-modal-bg-color");
                }

                height: 200px;
                border-radius: 20px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;

                &::-webkit-scrollbar-track {
                    margin-top: 25px !important;
                    margin-bottom: 25px !important;
                }

                &:hover::-webkit-scrollbar {
                    width: 8px !important;
                }
            }

            @extend .ui-select-size;
        }
    }

    @keyframes ui-modal-open {
        from {
            opacity: 0;
            margin-top: 30px;
        }

        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    @-moz-keyframes ui-modal-open {
        from {
            opacity: 0;
            margin-top: 30px;
        }

        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    @-webkit-keyframes ui-modal-open {
        from {
            opacity: 0;
            margin-top: 30px;
        }

        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    @-ms-keyframes ui-modal-open {
        from {
            opacity: 0;
            margin-top: 30px;
        }

        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    &.light {
        .ui-modal-mask {
            background-color: rgba(0, 0, 0, 0.3);
        }

        .ui-modal-scroll {
            .ui-modal {
                background-color: #ffffff !important;

                .ui-modal-header {
                    .ui-modal-close-icon {

                        color: #1d2333;

                    }

                    .ui-modal-title {

                        color: #070d17
                    }
                }

                .ui-modal-body {
                    .text {
                        color: #070d17;
                    }
                }

                .scroll {

                    background-color: #eceff1
                }
            }
        }
    }

    &.dark {
        .ui-modal-mask {
            background-color: rgba(0, 0, 0, 0.3);
        }

        .ui-modal-scroll {
            .ui-modal {
                background-color: #1d2333;

                .ui-modal-header {
                    .ui-modal-close-icon {

                        color: #ffffff
                    }

                    .ui-modal-title {

                        color: #ffffff
                    }
                }

                .ui-modal-body {
                    .text {

                        color: #ffffff
                    }
                }

                .scroll {

                    background-color: #191d29
                }
            }
        }
    }
}