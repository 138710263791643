.ui-input-range {
    .ui-input-range-container {
        position: relative;
        width: 100%;
    }
    .ui-input-range-rail-outer-style {
        position: absolute;
        width: 100%;
        height: 4px;
        transform: translate(0%, -50%);
        border-radius: 7px;
        cursor: pointer;
    }
    .ui-input-range-rail-inner-style {
        position: absolute;
        width: 100%;
        height: 4px;
        transform: translate(0%, -50%);
        border-radius: 4px;
        pointer-events: none;
        background-color: #d1d1d1;
    }
    .ui-input-range-handle {
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 5;
        width: 28px;
        height: 28px;
        cursor: pointer;
        &.disabled {
            cursor: not-allowed;
        }
        background-color: none;
        border-radius: 50%;
        &:hover {
            border: 9px solid rgba(29, 140, 248, 0.3) !important;
            border-radius: 50%;
        }
    }
    .ui-input-range-handle-slider {
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 2px solid #1d8cf8;
        &.disabled {
            background-color: #eceff1;
            border: 2px solid #707070;
        }
    }
    .ui-input-range-keyboard-handle {
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #ffffff;
        &.disabled {
            background-color: #d1d1d1;
        }
    }
    .ui-input-range-track {
        position: absolute;
        transform: translate(0%, -50%);
        height: 4px;
        z-index: 1;
        background-color: #1890ff;
        cursor: pointer;
        border-radius: 7px;
        &.disabled {
            background-color: #9e9e9e;
            cursor: not-allowed;
        }
    }
    .ui-input-range-tick {
        position: absolute;
        margin-top: 14;
        width: 1;
        height: 5;
        background-color: rgb(200, 200, 200);
    }
    .ui-input-range-tick-value {
        position: absolute;
        margin-top: 22px;
        font-size: 10px;
        text-align: center;
    }
}
