$themes: (
    light: (
        ui-tooltip-box-bg-color: #070d17,
        ui-tooltip-box-color: #eceff1,
        ui-tooltip-content-color: #070d17,
    ),
    dark: (
        ui-tooltip-box-bg-color: #ffffff,
        ui-tooltip-box-color: #070d17,
        ui-tooltip-content-color: #eceff1,
    ),
);

@import "./../../assets/css/base/theme";

.tooltip-placement-after {
    &.top {
        top: 100%;
        left: calc(50% - 5px);
    }
    &.topRight {
        top: 100%;
        right: 10px;
    }
    &.topLeft {
        top: 100%;
        left: 10px;
    }

    &.left {
        left: 100%;
        transform: rotate(-90deg);
        top: calc(50% - 5px);
    }
    &.leftTop {
        left: 100%;
        transform: rotate(-90deg);
        top: 10px;
    }
    &.leftBottom {
        left: 100%;
        transform: rotate(-90deg);
        bottom: 10px;
    }

    &.bottom {
        transform: rotate(180deg);
        bottom: 100%;
        left: calc(50% - 5px);
    }
    &.bottomRight {
        transform: rotate(180deg);
        bottom: 100%;
        right: 10px;
    }
    &.bottomLeft {
        transform: rotate(180deg);
        bottom: 100%;
        left: 10px;
    }

    &.right {
        right: 100%;
        transform: rotate(90deg);
        top: calc(50% - 5px);
    }
    &.rightTop {
        right: 100%;
        transform: rotate(90deg);
        top: 10px;
    }
    &.rightBottom {
        right: 100%;
        transform: rotate(90deg);
        bottom: 10px;
    }
}

.ui-tooltip {
    min-width: 60px;
    max-width: 350px;
    width: max-content;
    position: fixed;
    z-index: 999999999;
    opacity: 0;

    &.ui-tooltip-active {
        opacity: 1;
        transition: all 0.2s;
    }

    .ui-tooltip-content {
        @include themify() {
            background-color: themed("ui-tooltip-box-bg-color");
            color: themed("ui-tooltip-box-color") !important;
        }
        span {
            @include themify() {
                color: themed("ui-tooltip-box-color") !important;
            }
        }
        font-size: 12px;
        text-align: center;
        padding: 5px 10px;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            border-width: 5px;
            border-style: solid;

            @include themify() {
                border-color: themed("ui-tooltip-box-bg-color") transparent transparent transparent;
            }

            @extend .tooltip-placement-after;
        }
    }

    &.light {
        .ui-tooltip-content {
            background-color: #ffffff;
            box-shadow: 2px 0 9px rgba(0, 0, 0, 0.2);
            color: #333;
            span {
                color: #333 !important;
            }
            &:after {
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }

    &.dark {
        .ui-tooltip-content {
            background-color: #070d17;
            color: #eceff1;
            span {
                color: #eceff1 !important;
            }
            &:after {
                border-color: #070d17 transparent transparent transparent;
            }
        }
    }
}
