.mini-box-stats {
  margin-top: -10px;
  .ant-table:first-child:before {
    background-color: transparent;
  }
  .ant-table {
    li {
      padding: 2px 0;
    }
  }
  .main {
    height: 100%;
  }

  .wrapper-list {
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 0;
    margin: 0;
    direction: rtl;
    list-style-type: none;
    background-color: transparent; /*rgba(47, 52, 71, 0.4)*/
    border-bottom: none;
    position: relative;
    color: $white;
    &:last-child {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    &.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    &:hover:not(.ant-table-expanded-row) > td,
    &.ant-table-row-selected td {
      background: #1f273e;
    }
    li {
      .row-list {
        margin-top: 5px;
        margin-bottom: 5px;
        height: 50px;
        font-size: 14px;
        padding-right: 10px;
        line-height: 50px;
        @include themify() {
          background: themed("minibox-stats-row-bg-color");
          color: themed("minibox-stats-row-color");
        }
        border-radius: 10px;
        .row {
          padding-right: 10px;
          text-align: right;
          &.row1 {
            text-align: right;
          }
          &.row2 {
            text-align: left;
            padding-left: 20px;
            color: $main-color !important;
          }
        }
      }
    }
  }
}
