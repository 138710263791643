@import "./../../assets/css/base/variables";
@import "./../../assets/css/base/mixins";
.breadcrumb-wrapper {
    .breadcrumb-container {
        @include themify() {
            background-color: themed("breadcrumb-container-bg-color");
            border-bottom: solid 1px themed("breadcrumb-container-bb-color");
        }
        display: flex;
        flex-wrap: wrap;
        padding: 10px 16px 10px 0px;
        align-items: center;

        .breadcrumb-item {
            font-size: 10px;
            @include themify() {
                color: themed("breadcrumb-item-color");
            }
            cursor: pointer;
        }
        .breadcrumb-item-separator {
            @include themify() {
                color: themed("breadcrumb-seperator-color");
            }
            margin-right: 3px;
            margin-left: 5px;
            margin-top: 7px;
        }
        div:last-child {
            cursor: default;
            @include themify() {
                color: themed("breadcrumb-last-item-color");
            }
        }
    }
}
