$themes: (
    light: (
        ui-select-selection-bg-color: transparent,
        ui-select-selection-title-color: #070d17,
        ui-select-list-bg-color: white,
        ui-select-list-hover-color: #eceff1,
        ui-select-list-item-color: #070d17,
    ),
    dark: (
        ui-select-selection-bg-color: transparent,
        ui-select-selection-title-color: white,
        ui-select-list-bg-color: #070d17,
        ui-select-list-hover-color: #1d2333,
        ui-select-list-item-color: white,
    ),
);

@import "./../../assets/css/base/theme";
$xs: 25px;
$sm: 30px;
$md: 35px;
$lg: 40px;
$xl: 45px;
$xxl: 60px;

.ui-select-size {
    &.xs {
        height: $xs;
        .ui-select-selection {
            height: $xs;
            border-radius: $xs/2;
            .ui-select-close {
                width: $xs;
            }
            .ui-select-arrow {
                width: $xs;
            }
            .marquee {
                margin-right: $xs/3 !important;
            }
        }
    }
    &.sm {
        height: $sm;
        .ui-select-selection {
            height: $sm;
            border-radius: $sm/2;
            .ui-select-close {
                width: $sm;
            }
            .ui-select-arrow {
                width: $sm;
            }
            .marquee {
                margin-right: $sm/3 !important;
            }
        }
    }
    &.md {
        height: $md;
        .ui-select-selection {
            height: $md;
            border-radius: $md/2;
            .ui-select-close {
                width: $md;
            }
            .ui-select-arrow {
                width: $md;
            }
            .marquee {
                margin-right: $md/3 !important;
            }
        }
    }
    &.lg {
        height: $lg;

        .ui-select-selection {
            height: $lg;
            border-radius: $lg/2;
            .ui-select-close {
                width: $lg;
            }
            .ui-select-arrow {
                width: $lg;
            }
            .marquee {
                margin-right: $lg/3 !important;
            }
        }
    }
    &.xl {
        height: $xl;
        .ui-select-selection {
            height: $xl;
            border-radius: $xl/2;
            .ui-select-close {
                width: $xl;
            }
            .ui-select-arrow {
                width: $xl;
            }
            .marquee {
                margin-right: $xl/3 !important;
            }
        }
    }
    &.xxl {
        height: $xxl;
        .ui-select-selection {
            height: $xxl;
            border-radius: $xxl/2;
            .ui-select-close {
                width: $xxl;
            }
            .ui-select-arrow {
                width: $xxl;
            }
            .marquee {
                margin-right: $xxl/3 !important;
            }
        }
    }
}

.ui-select-theme {
    &.dark {
        .ui-select-selection {
            color: #070d17 !important;
            background-color: transparent !important;
        }
        &.force {
            .ui-select-selection {
                color: white !important;
                background-color: transparent !important;
            }
            .ui-select-list {
                background-color: #070d17 !important;

                .ui-select-list-item {
                    color: white !important;
                    &:hover {
                        background-color: #1d2333 !important;
                    }
                    &.selected-item {
                        background-color: #1d2333 !important;
                        color: #1d8cf8 !important;
                    }
                }
            }
        }
    }
    &.light {
        .ui-select-selection {
            color: white !important;
            background-color: transparent !important;
        }
        &.force {
            .ui-select-list {
                background-color: white !important;
                .ui-select-list-item {
                    color: #070d17 !important;
                    &:hover {
                        background-color: #eceff1 !important;
                    }
                    &.selected-item {
                        background-color: #eceff1 !important;
                    }
                }
            }
        }
    }
}

.ui-select-disabled {
    &.disabled {
        .ui-select-selection {
            cursor: not-allowed;
            * {
                color: #9e9e9e !important;
            }
            border-color: #d1d1d1 !important;
        }
    }
}

.ui-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    outline: 0;
    width: 100%;

    .ui-select-selection {
        display: flex;
        cursor: pointer;
        width: 100%;
        border: 1px solid #1d8cf8;
        @include themify() {
            color: themed("ui-select-selection-title-color");
            background-color: themed("ui-select-selection-bg-color");
        }
        align-items: center;
        &.open {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        .ui-select-image {
            height: 100%;
            width: auto;
            border-radius: 50%;
        }
        .marquee {
            position: unset !important;
            z-index: 1;
            .ui-select-label {
                display: flex;
                padding-right: 5px;
                flex: 1;
                &.animate {
                    animation: marquee 5s linear infinite;
                }
            }
        }
        .ui-select-arrow {
            text-align: center;
            color: #1890ff;
            display: flex;
            justify-content: space-around;
        }
        .ui-select-close {
            position: absolute;
            left: 0;
            text-align: center;
            display: flex;
            justify-content: space-around;
            z-index: 3;

            &:after {
                position: absolute;
                content: "";
                left: 0px;
                top: 3px;
                width: 14px;
                height: 14px;
                background: transparent;
                z-index: 3;
                border-radius: 50%;
            }
        }
        &:hover .ui-select-close {
            display: block;
        }
    }

    .ui-select-list {
        width: 100%;
        @include themify() {
            background-color: themed("ui-select-list-bg-color");
        }
        border: 1px solid #1d8cf8;
        position: absolute;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 4;
        right: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        border-radius: 0 0 5px 5px;
        cursor: pointer;
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        top: 100%;

        .ui-select-list-item {
            padding: 5px;
            margin: 5px;
            display: flex;
            align-items:center;
            @include themify() {
                color: themed("ui-select-list-item-color");
            }
            .ui-select-list-item-img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-left: 10px;
            }
            .ui-select-list-item-image-icon {
                width: 15px;
                height: 15px;
                margin-left: 10px;
            }

            &:hover {
                @include themify() {
                    background-color: themed("ui-select-list-hover-color");
                }
                color: #1d8cf8;
                border-radius: 3px;
            }
            &.selected-item {
                @include themify() {
                    background-color: themed("ui-select-list-hover-color");
                    color: #1d8cf8;
                }
                color: #1d8cf8;
            }
        }

        .ui-select-data-empty {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;
            @include themify() {
                color: themed("ui-select-list-item-color");
            }
        }

        .ui-select-list-item-footer {
            .add {
                background-color: #c2dbf3;
                color: #1d8cf8;
                padding: 10;
                padding: 5px;
                text-align: center;
                border-radius: 0 0 5px 5px;
                display: block;
            }
        }
    }

    .ui-select-click-area {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 2;
    }

    @extend .ui-select-disabled;

    @extend .ui-select-size;

    @extend .ui-select-theme;
}

.ui-modal {
    .ui-select.light {
        .ui-select-selection {
            .ui-select-title {
                color: #333 !important;
            }
        }
    }
}
