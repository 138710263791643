@import "./../../../assets/css/base/variables";
@import "./../../../assets/css/base/mixins";
.resource-smart-search {
    label {
        color: #7f7f7f !important;
    }
    .smart-search {
        display: flex;
        justify-content: space-between;
        .section-switch {
            display: flex;
            flex: 1;
            .ui-switch {
                margin-right: 20px;
            }
        }
    }

    .smart-limit {
        float: left;
        &.full-limit {
            font-weight: bold;
            color: #c00;
        }
    }

    .smart-search-button {
        direction: rtl;
        position: absolute !important;
        z-index: 2;
        top: 0px;
        //margin-top: 8px !important;
        height: 44px !important;
        border-radius: 4px !important;
        width: auto;
        max-width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;
        padding: 0 10px;
        min-width: 120px;
        line-height: 4;
        &.entity {
            left: 130px;
        }
        &.no-entity {
            left: 10px;
        }
        &.active {
            color: $main-color;
            cursor: pointer;
        }
        &.deactive {
            color: $sonic-silver;
        }
        &.result-page {
            line-height: 2.5;
        }
    }
    .section {
        &.visible {
            display: block;
        }
        &.invisible {
            display: none;
        }
        &.result-page {
            top: 45px;
            z-index: 3;
            direction: rtl;
        }

        &-radio-group {
            margin: 5px 0 15px 0px;
        }
        &-result {
            width: 100%;
            height: 150px;
            //border: 1px solid $white;
            @include borderRadius(10px);
            background: #e9e9e9;
            padding: 10px;
            overflow-y: auto;

            .ant-spin-nested-loading {
                height: 100%;
                .ant-spin {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .smart-item {
                // width: 107px;
                width: auto;
                border-radius: 15px;
                display: inline-block;
                margin: 2px 3px;
                box-sizing: content-box;
                padding: 2px 13px;
                color: #fff;
                background-color: #9a9595;
                position: relative;
                text-align: center;
                user-select: none;

                &.selected {
                    // border: 2px solid $spring-green;
                    box-shadow: 0 0 0 2px $spring-green;
                    &:after {
                        content: "";
                        position: absolute;
                        top: -7px;
                        right: -6px;
                        height: 19px;
                        width: 19px;
                        border-radius: 10px;
                        text-align: center;
                        font-family: "fontello";
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        font-size: 15px;
                        line-height: 20px;
                        color: #fff;
                        background: $spring-green;
                        margin: 0;
                        padding: 0;
                    }
                }
                &.manual {
                    cursor: pointer;
                    background-color: $ebony-clay;
                }
                & > div {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100px;
                }
            }

            .auto {
                cursor: auto;
            }
            &.auto {
                opacity: 0.7;
            }

            .error-msg {
            }
        }

        &-switch-button {
            margin: 10px 0px 10px 0;

            .ant-switch {
                margin: 0 10px 0 0;
            }
        }
    }
}
