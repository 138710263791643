.ui-loading {
    &.loading {
        position: relative;
        cursor: not-allowed;
        opacity: 0.5;
        user-select: none;
        &:after {
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
            content: "";
            border: 3px solid #f3f3f3;
            border-radius: 50%;
            border-top: 3px solid #3498db;
            width: 20px;
            height: 20px;
            -webkit-animation: ui-loading-spin 2s linear infinite; /* Safari */
            animation: ui-loading-spin 2s linear infinite;
        }
    }

    @keyframes ui-loading-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes ui-loading-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @-moz-keyframes ui-loading-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @-ms-keyframes ui-loading-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.height {
    &.xxxs {
        height: 15px;
    }
    &.xxs {
        height: 20px;
    }
    &.xs {
        height: 25px;
    }
    &.sm {
        height: 30px;
    }
    &.md {
        height: 35px;
    }
    &.lg {
        height: 40px;
    }
    &.xl {
        height: 45px;
    }
    &.xxl {
        height: 60px;
    }
}

.ui-button-icon-size {
    display: flex;
    place-items: center;
    &.xxxs {
        height: 15px;
        width: 15px;
        .icon{
            transform: scale(0.8);
        }
    }
    &.xxs {
        height: 20px;
        width: 20px;
        .icon{
            transform: scale(1);
        }
    }
    &.xs {
        height: 25px;
        width: 25px;
        .icon{
            transform: scale(1.3);
        }
    }
    &.sm {
        height: 30px;
        width: 30px;
        .icon{
            transform: scale(1.5);
        }
    }
    &.md {
        height: 35px;
        width: 35px;
        .icon{
            transform: scale(1.8);
        }
    }
    &.lg {
        height: 40px;
        width: 40px;
        .icon{
            transform: scale(2.1);
        }
    }
    &.xl {
        height: 45px;
        width: 45px;
        .icon{
            transform: scale(2.4);
        }
    }
    &.xxl {
        height: 60px;
        width: 60px;
        .icon{
            transform: scale(3);
        }
    }
}

.font-size {
    &.xxxs {
        font-size: 8px;
    }
    &.xxs {
        font-size: 10px;
    }
    &.xs {
        font-size: 12px;
    }
    &.sm {
        font-size: 12px;
    }
    &.md {
        // font-size: 12px;
    }
    &.lg {
        font-size: 14px;
    }
    &.xl {
        font-size: 14px;
    }
    &.xxl {
        font-size: 14px;
    }
}

.radius {
    &.xxxs {
        border-radius: 5px;
    }
    &.xxs {
        border-radius: 10px;
    }
    &.xs {
        border-radius: 15px;
    }
    &.sm {
        border-radius: 20px;
    }
    &.md {
        border-radius: 25px;
    }
    &.lg {
        border-radius: 35px;
    }
    &.xl {
        border-radius: 35px;
    }
    &.xxl {
        border-radius: 40px;
    }
}
@mixin borderRadius($tl, $tr: $tl, $br: $tl, $bl: $tr) {
    border-radius: $tl $tr $br $bl !important;
    -moz-border-radius: $tl $tr $br $bl !important;
    -webkit-border-radius: $tl $tr $br $bl !important;
}