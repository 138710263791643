//@include borderRadius(10px)
//@include borderRadius(10px,5px)

@mixin borderRadius($tl, $tr: $tl, $br: $tl, $bl: $tr) {
    border-radius: $tl $tr $br $bl !important;
    -moz-border-radius: $tl $tr $br $bl !important;
    -webkit-border-radius: $tl $tr $br $bl !important;
}

//@include gradient( red, blue, vertical)
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color !important;
    @if $orientation == "verticalTop" {
        //VERTICAL ↓
        background: -webkit-linear-gradient(top, $start-color, $end-color) !important;
        background: linear-gradient(to bottom, $start-color, $end-color) !important;
    } @else if $orientation == "verticalBottom" {
        //VERTICAL ↓
        background: -webkit-linear-gradient(bottom, $start-color, $end-color) !important;
        background: linear-gradient(to top, $start-color, $end-color) !important;
    } @else if $orientation == "horizontalLeft" {
        // HORIZONTAL →
        background: -webkit-linear-gradient(left, $start-color, $end-color) !important;
        background: linear-gradient(to right, $start-color, $end-color) !important;
    } @else if $orientation == "horizontalRight" {
        // HORIZONTAL →
        background: -webkit-linear-gradient(right, $start-color, $end-color) !important;
        background: linear-gradient(to left, $start-color, $end-color) !important;
    } @else if $orientation == "diag-topbottom" {
        // DIAG ↘
        background: -webkit-linear-gradient(-45deg, $start-color 0%, $end-color 100%) !important;
        background: linear-gradient(135deg, $start-color 0%, $end-color 100%) !important;
    } @else if $orientation == "diag-bottomtop" {
        // DIAG  ↗
        background: -webkit-linear-gradient(-45deg, $start-color 0%, $end-color 100%) !important;
        background: linear-gradient(135deg, $start-color 0%, $end-color 100%) !important;
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color) !important;
        background: radial-gradient(ellipse at center, $start-color, $end-color) !important;
    }
}

// @include boxShadow(3px, 3px, 0, red)
@mixin boxShadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

// @include alignCenter('vertical')
@mixin alignCenter($position) {
    position: absolute;
    @if $position == "vertical" {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    } @else if $position == "horizontal" {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
    } @else if $position == "both" {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// @extend %clearfix
%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// @include transition(color .3s ease);
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// @include opacity(0.8);
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); // IE8
}

// @include relative;
// @include absolute(top 100% left 0);
// @include fixed(top 0 left 0);
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}

@mixin scrollbar($val) {
    /* width */
    &::-webkit-scrollbar {
        width: 4px !important;
        @include themify() {
            color: themed("scrollbar-bg-color") !important;
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 100px $white !important;
        @if $val {
            margin-bottom: $val + px;
        }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        @include themify() {
            background: themed("scrollbar-thumb-bg-color") !important;
        }
        border-radius: 4px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        @include themify() {
            background: themed("scrollbar-hover-bg-color") !important;
        }
    }
}
// login
@mixin loginAnimate {
    opacity: 0;
    margin-top: 0;
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        margin-top: 50px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

/* نمایش باکس ها در پلنینگ به صورت انیمیشن فید این*/
$count: 20;
@mixin fadeIns() {
    @for $o from 1 through $count {
        .fade-in-#{$o} {
            animation: boxFadeIn #{$o * 0.5}s linear;
            //   animation-delay: #{$o*.5}s;
        }
    }
}
@keyframes boxFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes boxFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes boxFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes boxFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
// responsive
// Used to evaluate Sass maps like  grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
    $prev-key: null;
    $prev-num: null;
    @each $key, $num in $map {
        @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
            // Do nothing
        } @else if not comparable($prev-num, $num) {
            @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
        } @else if $prev-num >= $num {
            @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
        }
        $prev-key: $key;
        $prev-num: $num;
    }
}
// Starts at zero
//  min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
        @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
}
//import varible ro mixin
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

//btn structures mixins
//گردینت برای بک گراند که ما از این مورد برای قسمت رنگ دهی به دکمه ها استفاده می کنیم یعنی میکسین
@mixin gradient-bg($color) {
    @if $enable-gradients {
        background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
    } @else {
        background-color: $color;
    }
}

// این میکسین کار سایز بندی دکمه ها و تناسب مقادیرآن ها را با ساتفاده از متغیر های ورودی انجام می دهد
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius, $btn-height) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    border-radius: $border-radius;
    height: $btn-height;
}

//کاربرد میکسین زیر برای رنگ دهی به دکمه ها بصورت داینامیک می باشد
@mixin button-variant(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
    &:hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
        box-shadow: 0 0 10px $border;
    }

    &:focus,
    &.focus {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
        } @else {
            box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
        }
    }
}
//کاربرد میکسین زیر برای رنگ دهی به دکمه های تو خالی و دارای بوردر بصورت داینامیک می باشد

@mixin button-outline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    color: $color;
    border-width: 2px;
    border-color: $color;
    background-color: transparent;
    &:hover {
        color: $color;
        border-width: 2px;
        border-color: $active-border;
        box-shadow: 0 0 10px $btn-focus-width rgba($color, 0.5);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 10px $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $active-background;
        background-color: transparent;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow, 0 0 10px $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 10px $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}
//کاربرد میکسین زیر برای رنگ دهی به دکمه های تو خالی و دارای بوردر بصورت داینامیک می باشد

@mixin circle-outline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    color: $color;
    border-width: 1px;
    border-color: $color;
    background-color: transparent;
    &:hover {
        color: $color;
        border-width: 1px;
        border-color: $color;
    }
    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $active-background;
        background-color: transparent;
        border-color: $active-border;
    }
}

//file function and breackpoints to mixin
@import "./../../../assets/css/base/function";
@import "./../../../assets/css/base/utility/breakpoints";
