@import "./../../assets/css/base/utils";
.ui-spin {
    position: relative;
    width: 100%;
    &.inline {
        margin: 20px;
    }
    &.block {
        display: block;
    }
    .loader {
        position: absolute !important;
        width: 80px !important;
        margin: 0 auto !important;
        top: calc(50% - 10px) !important;
        z-index: 9;
        left: calc(50% - 40px) !important;
        display: block;
    }
    .ui-spin-loading {
        @extend .ui-loading;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        &:after {
            left: calc(50% - 10px);
            top: calc(50% - 10px);
        }
    }
    &.mask {
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
            content: "";
            z-index: 8;
        }
    }
}
