$themes: (
    light: (
        // ui-input-text-bg-color: white,
        ui-input-text-color: black,
        input-warning-color: #f5222d,
    ),
    dark: (
        ui-input-text-bg-color: black,
        ui-input-text-color: white,
        input-warning-color: #f5222d,
    ),
);

@import "./../../assets/css/base/theme";
@import "./../../assets/css/base/utils";

.icon-size {
    &.xxxs {
        width: 10px;
        font-size: 5px;
    }

    &.xxs {
        width: 20px;
        font-size: 5px;
    }

    &.xs {
        width: 25px;
        font-size: 10px;
    }

    &.sm {
        width: 30px;
        font-size: 15px;
    }

    &.md {
        width: 35px;
        font-size: 20px;
    }

    &.lg {
        width: 40px;
        font-size: 25px;
    }

    &.xl {
        width: 45px;
        font-size: 30px;
    }

    &.xxl {
        width: 50px;
        font-size: 35px;
    }

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-delete-size {
    &.xxxs {
        width: 10px;
        height: 10px;
        font-size: 5px;
    }

    &.xxs {
        width: 10px;
        height: 10px;
        font-size: 5px;
    }

    &.xs {
        width: 12px;
        height: 12px;
        font-size: 8px;
    }

    &.sm {
        width: 15px;
        height: 15px;
        font-size: 8px;
    }

    &.md {
        width: 15px;
        height: 15px;
        font-size: 8px;
    }

    &.lg {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    &.xl {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }

    &.xxl {
        width: 24px;
        font-size: 24px;
        font-size: 18px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3acac;
    border-radius: 100%;
    padding: 2px;
    margin-left: 5px;
    cursor: pointer;

    i {
        color: #ffffff !important;
    }
}

.ui-input {
    border: none;
    display: flex;
    align-items: center;
    width: 100%;

    &.has-border {
        border: 1px solid #1d8cf8;
    }

    &.disabled-mode {
        border: 1px solid #9e9e9e;
        color: #9e9e9e;

        .icon {
            color: #9e9e9e;
        }
    }

    @extend .ui-loading;
    @extend .height;
    @extend .radius;

    @include themify() {
        background-color: themed("ui-input-text-bg-color");
        color: themed("ui-input-text-color");
    }

    &.ui-input-no-prefix {
        padding-right: 20px;
    }

    .ui-input-text {
        flex: 1;
        border: none;
        width: 100%;
        height: 100%;
        background: transparent;
        @extend .radius;
        @extend .font-size;

        &::selection,
        &:focus,
        &:active {
            outline: none;
            box-shadow: none !important;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            @include themify() {
                -webkit-text-fill-color: themed("ui-input-text-color") !important;
                -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
                transition: background-color 5000s ease-in-out 0s;
            }
        }
    }

    .ui-input-prefix {
        text-align: center;
        @extend .icon-size;
        .text {
            font-size: 14px;
        }
    }

    .ui-input-suffix {
        text-align: center;
        @extend .icon-size;
        .text {
            font-size: 14px;
        }
    }

    .ui-input-clear {
        @extend .icon-delete-size;
    }

    .ui-input-clear-no-suffix {
        @extend .icon-delete-size;
    }

    .icon {
        display: inline-flex;
    }

    &.dark {
        background-color: black;
        color: white;
    }

    &.light {
        background-color: white;
        color: black;
    }

    &.error {
        margin-top: 5px;
        margin-bottom: 10px;
        background: transparent !important;
        text-align: right;
        @include themify() {
            color: themed("input-warning-color");
        }
    }
}
