
.mini-box-obj-details{
    margin-top: -10px;
    .ant-table:first-child:before{
        background-color: transparent;
    }
    .ant-table{
        li{
            padding: 2px 0;
        }
    }
    .main{
        height: 100%;
    }

  .wrapper-list {
    padding: 0px 0px 0px;
    direction: rtl;
    list-style-type: none;
    background-color: transparent;/*rgba(47, 52, 71, 0.4)*/
    border-bottom: none;
    position: relative;
    color: $ebony;
    &:last-child {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    &.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    &:hover:not(.ant-table-expanded-row) > td,
    &.ant-table-row-selected td{
      background: #1f273e;
    }
    li {
      .row-list {
        margin-top: 5px;
        margin-bottom: 5px;
        // height: 50px;
        font-size: 14px;
        padding-right: 0px;
        line-height: 50px;
        // background: #171b2c;
        border-radius: 10px;
        min-width: 300px;
        max-width: 450px;
        padding: 10px 0 10px;
        border-bottom: 1px solid #ddd;

        &:last-child{
          border-bottom: 0;
        }

        h3{
          color: #757575;
          line-height: 20x;
          font-size: 13px;
        }

        .data-list{
          text-align: left;
          line-height: 30px;
          color: $ebony;
          .item{
            margin-right: 5px;
            white-space: nowrap;
          }
        }

        .row {
          padding-right: 10px;
          text-align: right;
          &.row1 {
            text-align: right;
          }
          &.row2 {
            text-align: left;
            padding-left: 20px;
            color: $main-color !important;
          }
        }
      }
    }
  }

}