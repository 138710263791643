.ant-layout-header {
    @include themify() {
        background-color: themed("header-bg-color");
        border-bottom: 2px solid themed("header-border-bottom");
    }

    height: 60px;
    line-height: unset !important;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 !important;

    .ant-col-md-5.ant-col-lg-5.ant-col-xl-5.ant-col-xxl-4 {
        height: 100%;
    }
}

.ant-layout-sider {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    background: #1d2333 !important;
    min-height: calc(100vh - 70px);
    height: 100%;
}

.ant-layout {
    @include themify() {
        background-color: themed("body-bg-color");
    }
}

.ant-select-dropdown {
    background-color: $alto !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    @include scrollbar(20);
    overflow-x: hidden !important;
    z-index: 1000001 !important;

    @include themify() {
        background-color: themed("select-dropdown-bg-color") !important;
    }

    border: 1px solid $main-color;
    border-top: 0;

    .anticon-caret-down {
        svg {
            display: none;
        }
    }

    &.ant-select-dropdown-placement-topLeft {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-top: 1px solid $main-color !important;
        border-bottom: 0;

        .ant-select-dropdown-menu {
            margin-bottom: 0 !important;
            margin-top: 15px !important;
        }
    }

    .marked-image {
        width: 15px;
        margin-left: 10px;
    }
}

.ant-select-dropdown-menu {
    padding: 0 !important;
}

.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
    float: right !important;
}

@media (min-width: 992px) {

    .ant-col-lg-1,
    .ant-col-lg-2,
    .ant-col-lg-3,
    .ant-col-lg-4,
    .ant-col-lg-5,
    .ant-col-lg-6,
    .ant-col-lg-7,
    .ant-col-lg-8,
    .ant-col-lg-9,
    .ant-col-lg-10,
    .ant-col-lg-11,
    .ant-col-lg-12,
    .ant-col-lg-13,
    .ant-col-lg-14,
    .ant-col-lg-15,
    .ant-col-lg-16,
    .ant-col-lg-17,
    .ant-col-lg-18,
    .ant-col-lg-19,
    .ant-col-lg-20,
    .ant-col-lg-21,
    .ant-col-lg-22,
    .ant-col-lg-23,
    .ant-col-lg-24 {
        float: right !important;
    }

    .ant-col-lg-offset-4 {
        margin-left: unset;
        margin-right: 16.66666667%;
    }

    .ant-col-lg-offset-1 {
        margin-right: 4.16666667%;
    }
}

@media (min-width: 768px) {

    .ant-col-md-1,
    .ant-col-md-2,
    .ant-col-md-3,
    .ant-col-md-4,
    .ant-col-md-5,
    .ant-col-md-6,
    .ant-col-md-7,
    .ant-col-md-8,
    .ant-col-md-9,
    .ant-col-md-10,
    .ant-col-md-11,
    .ant-col-md-12,
    .ant-col-md-13,
    .ant-col-md-14,
    .ant-col-md-15,
    .ant-col-md-16,
    .ant-col-md-17,
    .ant-col-md-18,
    .ant-col-md-19,
    .ant-col-md-20,
    .ant-col-md-21,
    .ant-col-md-22,
    .ant-col-md-23,
    .ant-col-md-24 {
        float: right !important;
    }
}

@media (min-width: 576px) {

    .ant-col-sm-1,
    .ant-col-sm-2,
    .ant-col-sm-3,
    .ant-col-sm-4,
    .ant-col-sm-5,
    .ant-col-sm-6,
    .ant-col-sm-7,
    .ant-col-sm-8,
    .ant-col-sm-9,
    .ant-col-sm-10,
    .ant-col-sm-11,
    .ant-col-sm-12,
    .ant-col-sm-13,
    .ant-col-sm-14,
    .ant-col-sm-15,
    .ant-col-sm-16,
    .ant-col-sm-17,
    .ant-col-sm-18,
    .ant-col-sm-19,
    .ant-col-sm-20,
    .ant-col-sm-21,
    .ant-col-sm-22,
    .ant-col-sm-23,
    .ant-col-sm-24 {
        float: right !important;
    }
}

@media (min-width: 1200px) {
    .ant-col-xl-offset-4 {
        margin-left: unset !important;
        margin-right: 16.66666667% !important;
    }

    .ant-col-xl-offset-2 {
        margin-right: 8.33333333% !important;
    }

    .ant-col-xl-1,
    .ant-col-xl-2,
    .ant-col-xl-3,
    .ant-col-xl-4,
    .ant-col-xl-5,
    .ant-col-xl-6,
    .ant-col-xl-7,
    .ant-col-xl-8,
    .ant-col-xl-9,
    .ant-col-xl-10,
    .ant-col-xl-11,
    .ant-col-xl-12,
    .ant-col-xl-13,
    .ant-col-xl-14,
    .ant-col-xl-15,
    .ant-col-xl-16,
    .ant-col-xl-17,
    .ant-col-xl-18,
    .ant-col-xl-19,
    .ant-col-xl-20,
    .ant-col-xl-21,
    .ant-col-xl-22,
    .ant-col-xl-23,
    .ant-col-xl-24 {
        float: right !important;
    }

    .ant-col-xl-offset-1 {
        margin-right: 4.16666667%;
    }
}

@media (min-width: 1200px) {
    .ant-col-xl-offset-4 {
        margin-left: unset !important;
        margin-right: 16.66666667% !important;
    }

    .ant-col-xxl-1,
    .ant-col-xxl-2,
    .ant-col-xxl-3,
    .ant-col-xxl-4,
    .ant-col-xxl-5,
    .ant-col-xxl-6,
    .ant-col-xxl-7,
    .ant-col-xxl-8,
    .ant-col-xxl-9,
    .ant-col-xxl-10,
    .ant-col-xxl-11,
    .ant-col-xxl-12,
    .ant-col-xxl-13,
    .ant-col-xxl-14,
    .ant-col-xxl-15,
    .ant-col-xxl-16,
    .ant-col-xxl-17,
    .ant-col-xxl-18,
    .ant-col-xxl-19,
    .ant-col-xxl-20,
    .ant-col-xxl-21,
    .ant-col-xxl-22,
    .ant-col-xxl-23,
    .ant-col-xxl-24 {
        float: right !important;
    }

    .ant-col-xxl-offset-1 {
        margin-right: 4.16666667%;
    }
}

.ant-col-offset-4 {
    margin-right: 16.66666667%;
}

.ant-slider-rail {
    background-color: #d1d1d1 !important;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
    background-color: $main-color !important;
}

.ant-slider-handle.ant-tooltip-open,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: $main-color !important;
}

.ant-slider-handle:focus {
    box-shadow: 0 0 0 5px $box-cyan-highlight !important;
}

.ant-collapse {
    >.ant-collapse-item {
        border-bottom-color: transparent !important;

        >.ant-collapse-header {
            &[aria-expanded="true"] .anticon-right svg {
                transform: rotate(-90deg) !important;
            }

            .arrow svg {
                -webkit-transform: rotate(90deg) !important;
                transform: rotate(90deg) !important;
            }
        }
    }
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
    background-color: transparent !important;
}

.ant-select-selection__rendered {
    margin-right: 0px !important;
    margin-left: 20px;

    .ant-select-selection-selected-value {
        margin-right: 20px !important;
    }

    .ant-select-selection__choice__content {
        .avatar {
            height: 25px;
            width: 25px;
            margin: 0 0 0 8px;
        }
    }
}

.ant-select-selection--multiple,
input[type="number"] {
    min-height: 32px;
    cursor: text;
    padding-bottom: 3px;
    zoom: 1;
    border: 1px solid $gradient-cyan-right-color;
    background: transparent;
    border-radius: 20px;
    font-family: $font-family-default;

    @include themify() {
        color: themed("input-title-color");
    }

    &.disabled {
        border-color: $color-secondary7 !important;
    }
}

//Disabled states
input.ant-input.ant-input-disabled {
    border-color: $disabled-color !important;
    color: $disabled-color !important;
    cursor: not-allowed !important;
    pointer-events: none;
}

.ant-slider-disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.ant-slider-disabled .ant-slider-track.ant-slider-track-1 {
    background-color: $disabled-color !important;
}

.ant-slider-disabled .ant-slider-handle.ant-slider-handle-1,
.ant-slider-disabled .ant-slider-handle.ant-slider-handle-2 {
    border-color: $disabled-color !important;
}

.ant-select-selection.ant-select-selection--multiple {
    border: 1px solid $main-color;
    background: transparent;
    border-radius: 20px;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    right: unset;
    left: 12px;
}

.ant-select-selection--multiple {
    .ant-select-selection__placeholder {
        text-align: right;

        @include themify() {
            color: themed("select-selected-placeholder-color");
        }
    }

    .ant-select-selection__choice {
        float: right !important;
        background-color: rgba(121, 129, 150, 0.27) !important; //$ebony-clay !important;
        border-radius: 11px;
        padding: 0 5px 0 24px;
        margin-top: 4px !important;

        .ant-select-selection__choice__remove {
            left: 0;
            right: unset;
            padding-left: 4px;
            color: #a5a5a573;

            &:hover {
                color: $color-warning8; //$color-secondary5;
            }
        }
    }

    .ant-select-selection__rendered {
        margin: 0 5px 0 30px !important;
    }

    .ant-select-selection__clear {
        background: transparent;
        opacity: 1;
        right: unset;
        left: 16px;

        .ant-select-clear-icon {
            opacity: 0;
        }

        &:before {
            // custom
            content: "\e818";
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            // speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            background: transparent;
            color: #1d8cf8;
            font-size: 17px;
            line-height: 17px;
            font-weight: bold;
        }
    }

    &:after {
        content: "\E861"; // not used
        display: table;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
    }
}

.ant-checkbox {
    .ant-checkbox-inner {
        width: 17px !important;
        height: 17px !important;

        @include themify() {
            border-color: themed("checkbox-border-color") !important;
        }

        border-radius: 4px !important;
        background-color: transparent;

        &::after {
            top: 45% !important;
            left: 25% !important;
        }
    }

    &.ant-checkbox-disabled {
        span {
            border-color: $disabled-btn !important;
            background-color: transparent !important;
        }

        &+span {
            color: $disabled-btn;

            span {
                color: $disabled-btn;
            }
        }
    }
}

.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
        &::after {
            background-color: $main-color !important;
            left: 50% !important;
        }
    }
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: $main-color !important;
    }

    &::after {
        border-radius: 4px !important;
    }
}

.ant-checkbox-wrapper {
    @include themify() {
        color: themed("checkbox-title-color") !important;
    }
}

.ant-radio-group {
    direction: rtl;
}

.ant-radio {
    .ant-radio-inner {
        background-color: transparent;
    }
}

.ant-radio-checked {
    &::after {
        border: 1px solid $main-color;
    }

    .ant-radio-inner {
        border-color: $color-secondary5 !important;
        background-color: transparent;

        &::after {
            background-color: $main-color;
        }
    }
}

.ant-radio-inner {
    background-color: transparent;
    border-color: $color-secondary5;
}

.ant-radio-wrapper {
    &:hover {
        .ant-radio {
            .ant-radio-inner {
                border-color: $color-secondary5 !important;
            }

            &:hover {
                .ant-radio-inner {
                    border-color: $color-secondary5 !important;
                }
            }
        }
    }
}

.ant-select-dropdown.ant-select-dropdown--multiple {
    .ant-select-dropdown-menu-item {
        text-align: right;

        .avatar {
            height: 30px;
            width: 30px;
            margin: 0 0 0 8px;
            border-radius: 50%;
        }

        .ant-select-selected-icon {
            width: 20px;
            right: unset !important;
        }

        &:hover .ant-select-selected-icon {
            color: $main-color;
        }
    }
}

.ant-select-dropdown.ant-select-dropdown--single {
    .ant-select-dropdown-menu-item {
        text-align: right;

        .ant-select-selected-icon {
            width: 20px;
            right: unset !important;
        }

        &:hover .ant-select-selected-icon {
            color: $main-color;
        }
    }
}

:root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
    display: none;
}

div.ant-select-selection--multiple .ant-select-selection__choice {
    background-color: transparent;
    border-color: transparent;
    color: $white;
}

.ant-layout.ant-layout-has-sider,
.inner-menu.defaults-menu {
    min-height: calc(100vh - 70px);
    height: auto;
    position: relative;
    display: block !important;

    .ant-radio-wrapper {
        @include themify() {
            color: themed("radio-wrapper-color");
        }
    }

    .preset--item {
        @extend %clearfix;
        color: $white;
        padding: 0 5px;

        label i {
            margin-left: 10px;
        }

        &.changed {
            background: rgba(255, 87, 34, 0.18);
        }
    }
}

.ant-layout-sider-children {
    li {
        text-align: center;
        line-height: 3rem;
        margin: 3px 0 0 0;
        position: relative;
        transition: all 0.4s;
        background: transparent;

        a {
            padding-top: 7px;
            display: flex;
            transition: none !important;

            &:hover,
            &:focus,
            &:active {
                border-bottom: 1px solid transparent !important;
                color: $white !important;

                i {
                    border-bottom: 0 none !important;
                    color: $white !important;
                }
            }

            &.active,
            &:hover {
                // @include gradient($gradient-cyan-left-color, $gradient-cyan-right-color, horizontalRight);

                @include themify() {
                    background-color: themed("mini-sidebar-a-bg-color");
                }

                &:after {
                    position: absolute;
                    z-index: 2;
                    left: 0 !important;
                    border-radius: 0 !important;
                    top: 0;
                    bottom: 0;
                    width: 10px;
                    background: $gradient-cyan-right-color;
                    content: "";
                }
            }
        }

        i {
            font-size: 2rem;
            margin: auto;
        }
    }
}

.ant-popover-content {
    direction: rtl;
    text-align: right;
}

.ant-popover-inner-content,
.ant-popover-title {
    padding: 0 !important;
}

//Image Gallery overwrite to fix overflow
.image-gallery {
    min-width: 50px;
    @include borderRadius(10px);
    overflow: hidden;

    .image-gallery-slide .image-gallery-image img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        min-width: 150px;
    }

    .image-gallery-content.fullscreen .image-gallery-slide {
        height: 100vh;

        .image-gallery-image {
            display: flex;
            justify-content: center;
            height: 100vh;
            align-items: center;
        }
    }
}

.ant-layout-content {
    overflow: hidden;
    height: 100vh;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 70px);

    ul:nth-child(1) {
        flex: 1;
    }

    ul:nth-child(2) {
        border-top: 1px dashed $disabled-btn;
    }
}

//ant steps css override
.ant-steps-vertical {
    padding: 40px 20px 0 0 !important;

    .ant-steps-item {
        display: flex !important;
    }

    .ant-steps-item-icon {
        float: right !important;
        margin-right: 0 !important;
        margin-left: 16px;
    }

    .ant-steps-item-tail {
        left: auto !important;
        right: 12px;
        padding-right: 0 !important;

        &:after {
            background: none !important;
            background-image: linear-gradient($step-border 70%, rgba(255, 255, 255, 0) 0%) !important;
            background-position: right !important;
            background-size: 1px 8px !important;
            background-repeat: repeat-y !important;
        }
    }

    .ant-steps-item-content {
        min-height: 90px !important;
    }

    .ant-steps-item-title {
        @include themify() {
            color: themed("steps-finish-title-color") !important;
        }

        padding-right: 0 !important;
    }

    .ant-steps-item-content {
        width: calc(100% - 40px);
    }

    .ant-steps-icon {
        top: -2px !important;
    }
}

.ant-steps-item-finish {
    >.ant-steps-item-tail {
        &:after {
            background: $main-color !important;
        }
    }

    .ant-steps-item-icon {
        background-color: $main-color !important;

        >.ant-steps-icon {
            color: $white !important;
        }
    }

    .ant-steps-item-title {
        color: $main-color !important;
    }
}

.ant-steps-item-wait {
    .ant-steps-item-icon {
        background-color: transparent !important;
        border-color: $step-border !important;

        >.ant-steps-icon {
            @include themify() {
                color: themed("steps-wait-icon-color") !important;
            }
        }

        .ant-steps-item-title {
            @include themify() {
                color: themed("steps-wait-title-color") !important;
            }
        }
    }
}

.ant-steps-item-process {
    .ant-steps-item-icon {
        background: transparent !important;

        >.ant-steps-icon {
            @include themify() {
                color: themed("steps-process-icon-color") !important;
            }
        }
    }
}

@media (min-width: 1600px) {
    .ant-col-xxl-offset-4 {
        margin-right: 16.66666667% !important;
    }
}

@media (min-width: 1600px) {

    .ant-col-xxl-1,
    .ant-col-xxl-2,
    .ant-col-xxl-3,
    .ant-col-xxl-4,
    .ant-col-xxl-5,
    .ant-col-xxl-6,
    .ant-col-xxl-7,
    .ant-col-xxl-8,
    .ant-col-xxl-9,
    .ant-col-xxl-10,
    .ant-col-xxl-11,
    .ant-col-xxl-12,
    .ant-col-xxl-13,
    .ant-col-xxl-14,
    .ant-col-xxl-15,
    .ant-col-xxl-16,
    .ant-col-xxl-17,
    .ant-col-xxl-18,
    .ant-col-xxl-19,
    .ant-col-xxl-20,
    .ant-col-xxl-21,
    .ant-col-xxl-22,
    .ant-col-xxl-23,
    .ant-col-xxl-24 {
        float: left;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .ant-col-xxl-24 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
    }

    .ant-col-xxl-push-24 {
        left: 100%;
    }

    .ant-col-xxl-pull-24 {
        right: 100%;
    }

    .ant-col-xxl-offset-24 {
        margin-left: 100%;
    }

    .ant-col-xxl-order-24 {
        -webkit-box-ordinal-group: 25;
        -webkit-order: 24;
        -ms-flex-order: 24;
        order: 24;
    }

    .ant-col-xxl-23 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 95.83333333%;
    }

    .ant-col-xxl-push-23 {
        left: 95.83333333%;
    }

    .ant-col-xxl-pull-23 {
        right: 95.83333333%;
    }

    .ant-col-xxl-offset-23 {
        margin-left: 95.83333333%;
    }

    .ant-col-xxl-order-23 {
        -webkit-box-ordinal-group: 24;
        -webkit-order: 23;
        -ms-flex-order: 23;
        order: 23;
    }

    .ant-col-xxl-22 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 91.66666667%;
    }

    .ant-col-xxl-push-22 {
        left: 91.66666667%;
    }

    .ant-col-xxl-pull-22 {
        right: 91.66666667%;
    }

    .ant-col-xxl-offset-22 {
        margin-left: 91.66666667%;
    }

    .ant-col-xxl-order-22 {
        -webkit-box-ordinal-group: 23;
        -webkit-order: 22;
        -ms-flex-order: 22;
        order: 22;
    }

    .ant-col-xxl-21 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 87.5%;
    }

    .ant-col-xxl-push-21 {
        left: 87.5%;
    }

    .ant-col-xxl-pull-21 {
        right: 87.5%;
    }

    .ant-col-xxl-offset-21 {
        margin-left: 87.5%;
    }

    .ant-col-xxl-order-21 {
        -webkit-box-ordinal-group: 22;
        -webkit-order: 21;
        -ms-flex-order: 21;
        order: 21;
    }

    .ant-col-xxl-20 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 83.33333333%;
    }

    .ant-col-xxl-push-20 {
        left: 83.33333333%;
    }

    .ant-col-xxl-pull-20 {
        right: 83.33333333%;
    }

    .ant-col-xxl-offset-20 {
        margin-left: 83.33333333%;
    }

    .ant-col-xxl-order-20 {
        -webkit-box-ordinal-group: 21;
        -webkit-order: 20;
        -ms-flex-order: 20;
        order: 20;
    }

    .ant-col-xxl-19 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 79.16666667%;
    }

    .ant-col-xxl-push-19 {
        left: 79.16666667%;
    }

    .ant-col-xxl-pull-19 {
        right: 79.16666667%;
    }

    .ant-col-xxl-offset-19 {
        margin-left: 79.16666667%;
    }

    .ant-col-xxl-order-19 {
        -webkit-box-ordinal-group: 20;
        -webkit-order: 19;
        -ms-flex-order: 19;
        order: 19;
    }

    .ant-col-xxl-18 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 75%;
    }

    .ant-col-xxl-push-18 {
        left: 75%;
    }

    .ant-col-xxl-pull-18 {
        right: 75%;
    }

    .ant-col-xxl-offset-18 {
        margin-left: 75%;
    }

    .ant-col-xxl-order-18 {
        -webkit-box-ordinal-group: 19;
        -webkit-order: 18;
        -ms-flex-order: 18;
        order: 18;
    }

    .ant-col-xxl-17 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 70.83333333%;
    }

    .ant-col-xxl-push-17 {
        left: 70.83333333%;
    }

    .ant-col-xxl-pull-17 {
        right: 70.83333333%;
    }

    .ant-col-xxl-offset-17 {
        margin-left: 70.83333333%;
    }

    .ant-col-xxl-order-17 {
        -webkit-box-ordinal-group: 18;
        -webkit-order: 17;
        -ms-flex-order: 17;
        order: 17;
    }

    .ant-col-xxl-16 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 66.66666667%;
    }

    .ant-col-xxl-push-16 {
        left: 66.66666667%;
    }

    .ant-col-xxl-pull-16 {
        right: 66.66666667%;
    }

    .ant-col-xxl-offset-16 {
        margin-left: 66.66666667%;
    }

    .ant-col-xxl-order-16 {
        -webkit-box-ordinal-group: 17;
        -webkit-order: 16;
        -ms-flex-order: 16;
        order: 16;
    }

    .ant-col-xxl-15 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 62.5%;
    }

    .ant-col-xxl-push-15 {
        left: 62.5%;
    }

    .ant-col-xxl-pull-15 {
        right: 62.5%;
    }

    .ant-col-xxl-offset-15 {
        margin-left: 62.5%;
    }

    .ant-col-xxl-order-15 {
        -webkit-box-ordinal-group: 16;
        -webkit-order: 15;
        -ms-flex-order: 15;
        order: 15;
    }

    .ant-col-xxl-14 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 58.33333333%;
    }

    .ant-col-xxl-push-14 {
        left: 58.33333333%;
    }

    .ant-col-xxl-pull-14 {
        right: 58.33333333%;
    }

    .ant-col-xxl-offset-14 {
        margin-left: 58.33333333%;
    }

    .ant-col-xxl-order-14 {
        -webkit-box-ordinal-group: 15;
        -webkit-order: 14;
        -ms-flex-order: 14;
        order: 14;
    }

    .ant-col-xxl-13 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 54.16666667%;
    }

    .ant-col-xxl-push-13 {
        left: 54.16666667%;
    }

    .ant-col-xxl-pull-13 {
        right: 54.16666667%;
    }

    .ant-col-xxl-offset-13 {
        margin-left: 54.16666667%;
    }

    .ant-col-xxl-order-13 {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13;
    }

    .ant-col-xxl-12 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
    }

    .ant-col-xxl-push-12 {
        left: 50%;
    }

    .ant-col-xxl-pull-12 {
        right: 50%;
    }

    .ant-col-xxl-offset-12 {
        margin-left: 50%;
    }

    .ant-col-xxl-order-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }

    .ant-col-xxl-11 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 45.83333333%;
    }

    .ant-col-xxl-push-11 {
        left: 45.83333333%;
    }

    .ant-col-xxl-pull-11 {
        right: 45.83333333%;
    }

    .ant-col-xxl-offset-11 {
        margin-left: 45.83333333%;
    }

    .ant-col-xxl-order-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }

    .ant-col-xxl-10 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 41.66666667%;
    }

    .ant-col-xxl-push-10 {
        left: 41.66666667%;
    }

    .ant-col-xxl-pull-10 {
        right: 41.66666667%;
    }

    .ant-col-xxl-offset-10 {
        margin-left: 41.66666667%;
    }

    .ant-col-xxl-order-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }

    .ant-col-xxl-9 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 37.5%;
    }

    .ant-col-xxl-push-9 {
        left: 37.5%;
    }

    .ant-col-xxl-pull-9 {
        right: 37.5%;
    }

    .ant-col-xxl-offset-9 {
        margin-left: 37.5%;
    }

    .ant-col-xxl-order-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }

    .ant-col-xxl-8 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 33.33333333%;
    }

    .ant-col-xxl-push-8 {
        left: 33.33333333%;
    }

    .ant-col-xxl-pull-8 {
        right: 33.33333333%;
    }

    .ant-col-xxl-offset-8 {
        margin-left: 33.33333333%;
    }

    .ant-col-xxl-order-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }

    .ant-col-xxl-7 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 29.16666667%;
    }

    .ant-col-xxl-push-7 {
        left: 29.16666667%;
    }

    .ant-col-xxl-pull-7 {
        right: 29.16666667%;
    }

    .ant-col-xxl-offset-7 {
        margin-left: 29.16666667%;
    }

    .ant-col-xxl-order-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }

    .ant-col-xxl-6 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 25%;
    }

    .ant-col-xxl-push-6 {
        left: 25%;
    }

    .ant-col-xxl-pull-6 {
        right: 25%;
    }

    .ant-col-xxl-offset-6 {
        margin-left: 25%;
    }

    .ant-col-xxl-order-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }

    .ant-col-xxl-5 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 20.83333333%;
    }

    .ant-col-xxl-push-5 {
        left: 20.83333333%;
    }

    .ant-col-xxl-pull-5 {
        right: 20.83333333%;
    }

    .ant-col-xxl-offset-5 {
        margin-left: 20.83333333%;
    }

    .ant-col-xxl-order-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }

    .ant-col-xxl-4 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 16.66666667%;
    }

    .ant-col-xxl-push-4 {
        left: 16.66666667%;
    }

    .ant-col-xxl-pull-4 {
        right: 16.66666667%;
    }

    .ant-col-xxl-offset-4 {
        margin-left: 16.66666667%;
    }

    .ant-col-xxl-order-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }

    .ant-col-xxl-3 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 12.5%;
    }

    .ant-col-xxl-push-3 {
        left: 12.5%;
    }

    .ant-col-xxl-pull-3 {
        right: 12.5%;
    }

    .ant-col-xxl-offset-3 {
        margin-left: 12.5%;
    }

    .ant-col-xxl-order-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .ant-col-xxl-2 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 8.33333333%;
    }

    .ant-col-xxl-push-2 {
        left: 8.33333333%;
    }

    .ant-col-xxl-pull-2 {
        right: 8.33333333%;
    }

    .ant-col-xxl-offset-2 {
        margin-left: 8.33333333%;
    }

    .ant-col-xxl-order-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .ant-col-xxl-1 {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 4.16666667%;
    }

    .ant-col-xxl-push-1 {
        left: 4.16666667%;
    }

    .ant-col-xxl-pull-1 {
        right: 4.16666667%;
    }

    .ant-col-xxl-offset-1 {
        margin-left: 4.16666667%;
    }

    .ant-col-xxl-order-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .ant-col-xxl-0 {
        display: none;
    }

    .ant-col-push-0 {
        left: auto;
    }

    .ant-col-pull-0 {
        right: auto;
    }

    .ant-col-xxl-push-0 {
        left: auto;
    }

    .ant-col-xxl-pull-0 {
        right: auto;
    }

    .ant-col-xxl-offset-0 {
        margin-left: 0;
    }

    .ant-col-xxl-order-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
    }
}

.ant-select-selection.ant-select-selection--single {
    @include themify() {
        background: themed("select-bg-color") !important;
    }

    border: 1px solid $main-color;
    min-width: 200px;
    border-radius: 16px;
}

.ant-select-arrow {
    @include themify() {
        color: themed("select-arrow-color") !important;
    }

    font-size: $font-size-xlarg !important;
    right: unset !important;
    left: 11px;

    * {
        display: block !important;
    }
}

.ant-form-item-required {
    &:before {
        margin-left: 4px;
    }
}

.ant-form-item-label {
    label {
        @include themify() {
            color: themed("label-color") !important;
        }

        &:after {
            margin-right: 3px !important;
        }
    }
}

.ant-select-selection-selected-value {
    float: right !important;

    @include themify() {
        color: themed("ant-select-selection-selected-value");
    }

    .marked-image {
        width: 15px;
        margin-left: 10px;
    }
}

.ant-select-selection__placeholder {
    left: unset !important;
}

.ant-select-open .ant-select-selection {
    // border-radius: 20px 0 !important;
    @include borderRadius(16px, 16px, 0, 0);
}

.ant-select-dropdown-menu-item {
    padding: 10px !important;

    @include themify() {
        border-bottom: 1px solid themed("select-dropdown-menu-border-bottom-color") !important;
    }

    margin: 0 5px;

    @include themify() {
        color: themed("select-dropdown-menu-color") !important;
    }

    &:last-child {
        border: none !important;
    }
}

.ant-input {
    background-color: transparent !important;
    border-radius: 16px !important;
    border-color: $main-color !important;

    @include themify() {
        color: themed("input-title-color");
    }

    font-family: IranSans !important;

    &::-webkit-input-placeholder {
        @include themify() {
            color: themed("input-placeholder-color");
        }
    }

    &::-moz-placeholder {
        @include themify() {
            color: themed("input-placeholder-color");
        }
    }

    &:-ms-input-placeholder {
        @include themify() {
            color: themed("input-placeholder-color");
        }
    }

    &:-moz-placeholder {
        @include themify() {
            color: themed("input-placeholder-color");
        }
    }
}

.ant-tag {
    background-color: $scroll-thumb !important;
    color: $white !important;
    border: none !important;
    border-radius: 11px !important;
    margin-right: 10px !important;

    .anticon-close {
        color: $white !important;
        margin-right: 3px;
        margin-left: unset !important;
    }
}

.ant-popover-title {
    font-size: $font-size-larg !important;
    color: $gradient-cyan-left-color !important;
    padding: 10px 20px 10px 10px !important;
    border-color: $color-secondary6 !important;
    background-image: url("./info.png");
    background-position: right center;
    background-repeat: no-repeat;
}

.ant-popover-inner {
    padding: 5px 15px;
}

.ant-popover-inner-content {
    padding: 10px 0 !important;

    p {
        margin: 0 !important;
    }
}

.ant-popover-arrow {
    background-color: $white !important;
    border-style: hidden !important;
    z-index: -1;
}

// برای رادیو باتن های دکمه مانند
.ant-radio-button-wrapper {
    &:first-child {
        @include borderRadius(0px, 6px, 6px, 0px);
    }

    &:last-child {
        @include borderRadius(4px, 0, 0, 4px);
        border-left: 0;
    }

    &:not(:first-child)::before {
        left: auto;
        right: -1px;
    }

    &.ant-radio-button-wrapper-checked {
        background: $main-color;
        color: $white;

        &:hover {
            color: $white;
        }
    }
}

/*ant checkbox groups*/
.ant-checkbox-group-item {
    margin-right: unset !important;
    margin-left: 40px !important;
    margin-bottom: 15px !important;
}

/*custom ant button only for sideMenu steps*/
.steps-action {
    .ant-btn {
        height: auto !important;
        font-size: $font-size-medium !important;
    }

    .ant-btn:active,
    .ant-btn.active {
        color: unset !important;
    }

    .ant-btn:hover,
    .ant-btn:focus {
        color: unset !important;
        border-color: transparent !important;
    }

    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
        margin-left: 14px !important;
    }
}

.ant-tree {
    .ant-tree-node-content-wrapper {
        .ant-tree-title {
            color: $white;
        }
    }

    .ant-tree-switcher {
        svg {
            fill: $white;
        }

        &.ant-tree-switcher_close {
            transform: rotate(180deg);
        }
    }

    li {
        ul.ant-tree-child-tree {
            padding: 0 18px 0 0;
        }
    }
}

.ant-select-tree {
    li {
        .ant-select-tree-node-content-wrapper {
            @include themify() {
                color: themed("select-selected-tree-text-color") !important;
            }

            &:hover {
                background-color: transparent !important;
                color: $main-color !important;
            }

            &.ant-select-tree-node-selected {
                color: $main-color !important;
                background-color: transparent !important;
            }
        }

        span.ant-select-tree-switcher {
            color: $white;

            &.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
                transform: rotate(90deg) !important;
            }
        }

        ul {
            padding: 0 20px 0 0 !important;
        }
    }
}

/*ant tooltip*/
.custom-tooltip {
    .ant-tooltip-inner {
        background-color: $white;
        color: $color-secondary7;
        text-align: justify;
        direction: rtl;
        padding: 15px;
    }
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    border-left-color: rgb(255, 255, 255) !important;
}

.ant-tooltip-inner {
    font-size: $font-size-default;
}

.ant-table {
    table {
        border-collapse: separate;
        text-align: right;
        border-spacing: 0 10px !important;

        .ant-table-thead {
            tr {
                th {
                    background: transparent;

                    @include themify() {
                        color: themed("table-th-color");
                    }

                    text-align: right;
                    border-bottom: none;
                    padding-bottom: 5px;

                    &:first-child {
                        padding-right: 22px;
                    }
                }

                &.ant-table-row-hover:not(.ant-table-expanded-row)>td,
                &:hover:not(.ant-table-expanded-row)>td {
                    background: $mirage-approx;
                }
            }
        }

        .ant-table-tbody {
            tr {
                @include themify() {
                    box-shadow: 0 1px 3px themed("table-row-border-color") !important;
                }

                text-align: right;

                &:nth-child(2n + 1) {
                    @include themify() {
                        background-color: themed("table-row-even-bg-color");
                    }
                }

                &:nth-child(2n) {
                    @include themify() {
                        background-color: themed("table-row-odd-bg-color");
                    }
                }

                border-radius: 9px;

                td {
                    border-bottom: none;
                    position: relative;

                    @include themify() {
                        color: themed("table-td-color");
                    }

                    &:last-child {
                        border-top-left-radius: 9px;
                        border-bottom-left-radius: 9px;
                    }

                    &:first-child {
                        border-top-right-radius: 9px;
                        border-bottom-right-radius: 9px;
                    }

                    // &:first-child:before {
                    //   content: "";
                    //   width: 10px;
                    //   top: 0;
                    //   right: -10px;
                    //   height: 100%;
                    //   position: absolute;
                    //   border-top-right-radius: 9px;
                    //   border-bottom-right-radius: 9px;
                    // }
                }

                &.ant-table-row-hover:not(.ant-table-expanded-row)>td,
                &:hover:not(.ant-table-expanded-row)>td,
                &.ant-table-row-selected td {
                    @include themify() {
                        background: themed("table-th-hover-bg-color");
                    }

                    &:first-child:before {
                        @include themify() {
                            background-color: themed("table-th-hover-bg-color");
                        }
                    }
                }
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-inner {

            //   border-color: $black-haze !important;
            &:after {
                display: block;
                border-radius: 4px;
            }
        }
    }

    .ant-table-row-indent+.ant-table-row-expand-icon {
        margin-left: 8px;
    }

    .ant-table-body,
    .ant-table-body-inner {
        overflow: auto !important;
    }
}

.ant-table-fixed-right {
    .ant-table-tbody {
        tr {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            td {
                &:last-child {
                    border-top-left-radius: 9px;
                    border-bottom-left-radius: 9px;
                }

                &:first-child {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }
        }
    }
}

.ant-table-row-expand-icon {
    line-height: 16px;
    background: transparent;
    border-radius: 50%;
}

.ant-table-body {
    direction: rtl !important;
}

.ant-modal-body {
    padding: 0 !important;
}

.ant-modal-confirm-body>.anticon {
    float: right;
    margin-left: 16px;
    margin-right: unset;
}

.ant-input-number-input {
    @include themify() {
        background: themed("input-number-input-color") !important;
        color: themed("input-number-input-text-color");
    }

    border-radius: 20px !important;
}

.ant-input-number {
    border-color: $main-color !important;
    background: $ebony !important;
    border-radius: 20px !important;

    .ant-input-number-handler-wrap {
        .ant-input-number-handler {
            &.ant-input-number-handler-up {
                border-radius: 0 35px 0 0;
                cursor: pointer;
                position: relative;
                top: -1px;
                right: -1px;
                width: 23px;
                height: 15px !important;
                border: 1px solid #1d8cf8;

                i {
                    margin-right: 2px;

                    &:after {
                        content: "^";
                        font-size: 28px;
                        top: 7px;
                        position: absolute;
                        left: 0px;
                    }
                }
            }

            &.ant-input-number-handler-down {
                top: 0;
                border-top: 1px solid #d9d9d9;
                border-bottom-right-radius: 4px;
                cursor: pointer;
                border-radius: 0 0 15px 0;
                position: relative;
                bottom: -1px;
                right: -1px;
                width: 23px;
                height: 16px !important;
                border: 1px solid #1d8cf8;

                i {
                    margin-right: 2px;

                    &:after {
                        content: "^";
                        font-size: 28px;
                        top: -8px;
                        position: absolute;
                        left: 0px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.has-error .ant-form-explain {
    padding-right: 16px;
    margin-top: 3px;
    margin-bottom: 2px;
}

.ant-collapse-content {
    .ant-collapse-content-box {
        padding-top: 20px !important;
    }
}

.ant-select-tree {
    li {
        span.ant-select-tree-switcher {
            float: left;

            .ant-select-switcher-icon {
                &::before {
                    // content: "\e804"; // "\e807"
                    font-family: "fontello" !important;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: bold;
                    display: inline-block;
                    text-decoration: inherit;
                    width: 1em;
                    margin-right: 0.2em;
                    text-align: center;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 26px;
                    margin-left: 0.2em;
                    -webkit-font-smoothing: antialiased;
                    color: $color-secondary7;
                }
            }

            &.ant-select-tree-switcher_close {
                .ant-select-switcher-icon {
                    &::before {
                        // custom
                        content: "\e80d";
                    }
                }
            }

            &.ant-select-tree-switcher_open {
                .ant-select-switcher-icon {
                    &::before {
                        // custom
                        content: "\e80a";
                    }
                }
            }
        }

        .ant-select-tree-node-content-wrapper {
            width: 100% !important;
            padding-right: 8px !important;
        }
    }
}

/* Table */
.ant-table {
    .ant-table-content {

        .ant-table-header,
        .ant-table-fixed,
        .ant-table-body {
            @include themify() {
                background-color: themed("table-body-color") !important;
            }
        }

        .ant-table-fixed-left {
            box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
            bottom: 10px;
            right: 0;
            left: auto;

            .ant-table-header {
                .ant-table-fixed {
                    border-spacing: inherit;
                }
            }

            .ant-table-body-outer {
                .ant-table-body-inner {
                    margin-right: 0;
                    padding-right: 0;

                    &::-webkit-scrollbar {
                        width: 0 !important;

                        @include themify() {
                            color: themed("scrollbar-bg-color") !important;
                        }
                    }

                    .ant-table-fixed {
                        .ant-table-tbody {
                            tr td:last-child {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-fixed-right {
            //   box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
            top: -20px;
            right: auto;
            left: 0;

            .ant-table-header {

                //background-color: $ebony;
                .ant-table-fixed {
                    border-spacing: inherit;
                }
            }
        }
    }
}

.table-horizontal-scroll {
    .ant-table-content {
        .ant-table-scroll {

            .ant-table-header .ant-table-fixed,
            .ant-table-body .ant-table-fixed {
                padding-left: 15px;
                padding-right: 10px;
            }
        }
    }

    .ant-table-fixed-right {
        .ant-table-header {
            .ant-table-fixed {
                padding-left: 15px;
            }
        }

        .ant-table-body-outer {
            .ant-table-body-inner {
                .ant-table-fixed {
                    padding-left: 15px;
                }
            }
        }
    }

    .ant-table-fixed-left {
        .ant-table-header {
            .ant-table-fixed {
                padding-right: 10px;
            }
        }

        .ant-table-body-outer {
            .ant-table-body-inner {
                .ant-table-fixed {
                    padding-right: 10px;
                }
            }
        }
    }
}

.ant-dropdown-menu {
    border: 1px solid $main-color;

    @include themify() {
        background-color: themed("ant-dropdown-menu-bg-color");
    }

    .ant-dropdown-menu-item {
        display: flex;
        min-width: 130px;
        text-align: right;

        @include themify() {
            color: themed("ant-dropdown-menu-color");
        }

        &:hover {
            @include themify() {
                background-color: themed("ant-dropdown-menu-bg-color-hover");
            }
        }

        i {
            margin: auto 0 auto 5px;
            color: $main-color;
            font-size: 18px;
            line-height: 17px;
        }

        span {
            margin: auto 0;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .ant-layout-sider-children {
        li {
            line-height: 2.5rem !important;

            a {

                &:hover,
                &.active {
                    &:after {
                        width: 7px;
                        left: -7px !important;
                    }
                }
            }
        }
    }
}

.ant-input-prefix {
    left: auto !important;
    right: 12px;

    i {
        @include themify() {
            color: themed("input-search-perfix-icon");
        }
    }
}

.ant-input-suffix {
    left: 12px;
    right: auto !important;

    i {
        @include themify() {
            color: themed("input-search-sufix-icon");
        }
    }
}

.ant-select-selection__clear {
    color: white !important;
    background-color: transparent;
}

.ant-dropdown {
    z-index: 5 !important;

    &.tag-dropdown,
    &.popup-operation-tags {
        z-index: 99999999 !important;
    }

    .ant-dropdown-menu-item {
        &.active {
            @include themify() {
                background-color: themed("ant-dropdown-active-bg-color");
            }

            border-radius: 0;
            color: #1d8cf8;
        }
    }
}