@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';

@mixin planet($radius) {
  width: $radius;
  height: $radius;
  border-radius: 100%;
}

.message-box-container {
  text-align: center;
  @include themify() {
    background-color: themed('message-box-container-bg-color');
  } 
  height: 590px;
  position: relative;
  border-radius: 16px;
  .box {
    margin: 0;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .icon {
      font-size: $icon-size-xxxlarg;
      @include themify() {
        color: themed('message-box-container-color');
      } 
    }
    .image {
      width: 200px;
    }
  }
  &.animation {
    .image {
      width: auto;
      margin-bottom: 35px;
      animation: vibre 20s infinite;
      transform: rotate(-50deg);
      transform-origin: 100px center;
      right: -100px;
    }

    .rock1 {
      position: absolute;
      display: block;
      right: -200px;
      top: 150px;
      @include planet(120px);
      transform: rotate(-30deg);
      transform-origin: 0px center;
      overflow: hidden;
      animation: earthOrbit1 60s infinite;
    }
    .rock2 {
      position: absolute;
      display: block;
      left: -200px;
      top: 150px;
      @include planet(50px);
      transform: rotate(-20deg);
      transform-origin: 50px center;
      overflow: hidden;
      animation: earthOrbit2 35s infinite;
    }
    .rock3 {
      position: absolute;
      display: block;
      left: -350px;
      top: 150px;
      @include planet(80px);
      transform: rotate(-25deg);
      transform-origin: 450px center;
      overflow: hidden;
      animation: earthOrbit3 60s infinite;
    }
  }
  .description {
    font-size: $font-size-xxlarg;
    @include themify() {
      color: themed('message-box-container-color');
    } 
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@keyframes vibre {
  0%,
  20% {
    transform: rotate(-50deg);
    width: 170px;
  }
  20%,
  40% {
    transform: rotate(-30deg);
    width: 190px;
  }
  40%,
  60% {
    transform: rotate(-40deg);
    width: 220px;
  }
  60%,
  80% {
    transform: rotate(-20deg);
    width: 250px;
  }
  80%,
  100% {
    transform: rotate(-30deg);
    width: 200px;
  }
  100% {
    transform: rotate(-50deg);
    width: 170px;
  }
}

@keyframes earthOrbit1 {
  20%,
  25% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-330deg);
    width: 70px;
  }
  80%,
  90% {
    transform: rotate(30deg);
    width: 70px;
  }
}

@keyframes earthOrbit2 {
  20%,
  25% {
    transform: rotate(30deg);
  }

  80%,
  90% {
    transform: rotate(-60deg);
    width: 150px;
  }
}
@keyframes earthOrbit3 {
  20%,
  25% {
    transform: rotate(30deg);
  }

  80%,
  90% {
    transform: rotate(-20deg);
  }
}
