$themes: (light: (ui-radio-label-color: #070d17,
        ui-radio-border-color: #d1d1d1,
        ui-radio-disabled: #d8d8d8,
    ),
    dark: (ui-radio-label-color: #ffffff,
        ui-radio-border-color: #757575,
        ui-radio-disabled: #6b6b6b,
    ),
);

@import "./../../assets/css/base/theme";
$xs: 15px;
$sm: 15px;
$md: 15px;
$lg: 15px;
$xl: 15px;
$xxl: 15px;

.size-before {
    &.xs {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }

    &.sm {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }

    &.md {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }

    &.lg {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }

    &.xl {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }

    &.xxl {
        left: 130%;
        bottom: 10%;
        width: $md;
        height: $md;
    }
}

.size-after {
    &.xs {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }

    &.sm {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }

    &.md {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }

    &.lg {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }

    &.xl {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }

    &.xxl {
        left: 4px;
        bottom: 6px;
        width: 7px;
        height: 7px;
    }
}

.ui-radio {
    display: inline-block;

    .ui-radio-input {
        position: relative;
        opacity: 0;
    }

    .ui-radio-label {
        font-size: 12px;
        margin-right: 10px;
        margin-left: 15px;

        @include themify() {
            color: themed("ui-radio-label-color");
        }
    }

    .ui-radio-checked {
        position: relative;
        cursor: pointer;
        right: 3px;
        top: -1px;
        z-index: 0;

        &:before {
            @include themify() {
                border: 2px solid themed("ui-radio-border-color") !important;
            }

            position: absolute;
            content: "";
            background-color: transparent;
            border-radius: 50%;
            z-index: 9;
            transition: all 0.3s;
            @extend .size-before;
        }

        &:after {
            position: absolute;
            content: "";
            border-radius: 50%;
            z-index: 10;
            @extend .size-after;
        }
    }

    .ui-radio-input:checked~.ui-radio-checked:after {
        background-color: #1d8cf8;
    }

    &.disabled {
        * {
            cursor: not-allowed;
        }

        .ui-radio-checked {
            &:before {
                @include themify() {
                    border-color: themed("ui-radio-disabled") !important;
                }
            }
        }

        .ui-radio-label {
            @include themify() {
                color: themed("ui-radio-disabled");
            }
        }
    }

    &.light {

        .ui-radio-label {
            color: #070d17 !important;
        }

        .ui-radio-checked {
            &:before {
                border: 2px solid #d1d1d1 !important;
            }
        }

        &.disabled {
            .ui-radio-checked {
                &:before {
                    border-color: #d8d8d8 !important;
                }
            }

            .ui-radio-label {
                color: #d8d8d8;
            }
        }
    }

    &.dark {
        .ui-radio-label {
            color: #ffffff !important;
        }

        .ui-radio-checked {
            &:before {
                border: 2px solid #757575 !important;
            }
        }

        &.disabled {
            .ui-radio-checked {
                &:before {
                    border-color: #6b6b6b !important;
                }
            }

            .ui-radio-label {
                color: #6b6b6b;
            }
        }
    }
}