$themes: (
    light: (
        ui-table-row-bg-color-odd: #ebf5ff,
        ui-table-row-bg-color-even: white,
        ui-table-row-border-color-on-move: black,
        ui-table-header-color: #757575,
        ui-table-pagination-cell-color: #070d17,
        ui-table-pagination-number-color: #2f3447,
        ui-table-pagination-jump-text: #070d17,
        ui-table-pagination-bg-color: #edf1f3,
        ui-table-pagination-shadow: silver,
        ui-table-errors-bg-color: #f5f5f5,
        ui-table-popupinfo-bg-color: #1d8cf8,
        ui-table-popupinfo-checkbox-wrapper-color: #ffffff,
        ui-table-popupinfo-text-color: #ffffff,
    ),
    dark: (
        ui-table-row-bg-color-odd: #2f3447,
        ui-table-row-bg-color-even: #1d2333,
        ui-table-row-border-color-on-move: white,
        ui-table-header-color: #d1d1d1,
        ui-table-pagination-cell-color: #edf1f3,
        ui-table-pagination-number-color: #edf1f3,
        ui-table-pagination-jump-text: #edf1f3,
        ui-table-pagination-bg-color: #070d17,
        ui-table-pagination-shadow: black,
        ui-table-errors-bg-color: #191d29,
        ui-table-popupinfo-bg-color: #1d8cf8,
        ui-table-popupinfo-checkbox-wrapper-color: #ffffff,
        ui-table-popupinfo-text-color: #ffffff,
    ),
);

@import "./../../assets/css/base/theme";
@import "./../../assets/css/base/utils";

$tablePaddind: 32px;
$tableHeadPaddind: 32px 48px 16px;
$rowPadding: 16px;
$rowBorderRadios: 10px;
$rowBorderRadiosCollapse: 10px 10px 0 0;
$rowBorderRadiosExpended: 0 0 10px 10px;
$rowBoxShadow: 0 0 9px rgba(0, 0, 0, 0.2);
$rowBoxShadowOnMove: 0 0 15px rgba(0, 0, 0, 0.5) !important;
$rowMargin: 10px 0;
$gridGap: 20px;

.ui-table-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui-table {
    @extend .ui-loading;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 65px);

    .ui-table-rows {
        overflow: auto;
        padding: 0 $tablePaddind;
        flex: auto;
    }
    &.ui-table-row {
        box-shadow: $rowBoxShadowOnMove;
        @include themify() {
            border-bottom: 1px dashed themed("ui-table-row-border-color-on-move") !important;
        }
        cursor: move !important;
    }
    .ui-table-row,
    &.ui-table-row {
        display: grid;
        grid-gap: $gridGap;
        grid-template-columns: repeat(5, 1fr);
        padding: $rowPadding;
        box-shadow: $rowBoxShadow;
        margin: $rowMargin;
        border-radius: $rowBorderRadios;
        align-items: center;
        .ui-table-cell {
            @include themify() {
                color: themed("ui-table-pagination-cell-color");
            }
            @extend .ui-table-nowrap;
            .ui-table-actions {
                text-align: left;
                border-right: 1px solid silver;
                .icon {
                    margin-right: 8px;
                }
            }
            &.expandable {
                cursor: pointer;
                text-align: center;
            }
        }

        &.collapse {
            border-radius: $rowBorderRadiosCollapse;
            margin-bottom: 0;
        }
        &.expended {
            border-radius: $rowBorderRadiosExpended;
            margin-top: 1px;
            .ui-table-cell {
                white-space: normal !important;
            }
        }
        &:nth-child(odd) {
            @include themify() {
                background-color: themed("ui-table-row-bg-color-odd");
            }
        }
        &:nth-child(even) {
            @include themify() {
                background-color: themed("ui-table-row-bg-color-even");
            }
        }
    }
    .ui-table-header {
        display: grid;
        grid-gap: $gridGap;
        grid-template-columns: repeat(5, 1fr);
        padding: $rowPadding;
        align-items: center;
        padding: $tableHeadPaddind;
        z-index: 1;
        @include themify() {
            box-shadow: -8px 2px 9px themed("ui-table-pagination-shadow"),
                -8px 1px 3px themed("ui-table-pagination-shadow");
        }
        .ui-table-cell {
            @include themify() {
                color: themed("ui-table-header-color");
            }
            @extend .ui-table-nowrap;
        }
    }
    .ui-table-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        z-index: 1;
        @include themify() {
            background: themed("ui-table-pagination-bg-color");
            box-shadow: -8px -2px 9px themed("ui-table-pagination-shadow"),
                -8px -1px 3px themed("ui-table-pagination-shadow");
        }

        .pagination {
            margin: 0;
            list-style: none;
            display: inline-block;

            li {
                display: inline-block;
                margin: 3px 7px 0 7px;
                vertical-align: top;

                a {
                    @include themify() {
                        color: themed("ui-table-pagination-number-color");
                    }
                    height: 18px;
                    &:focus {
                        border-color: transparent !important;
                    }
                }
            }

            .active {
                pointer-events: none;
                &:focus {
                    border-color: transparent !important;
                }
                a {
                    color: #1d8cf8 !important;
                    &:focus {
                        border-color: transparent !important;
                        display: contents;
                    }
                }
            }

            .previous {
                border-radius: 50%;
                height: 20px;
                width: 20px;
                position: relative;
                background-color: #1d8cf8;

                &:before {
                    font-family: "fontello" !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-font-smoothing: antialiased;
                    font-size: 10px;
                    // custom
                    content: "\e80c";
                    color: white;
                }

                a {
                    display: block;
                    border-radius: 50%;
                    position: relative;
                    z-index: 123;
                    height: 100%;
                }
            }

            .next {
                border-radius: 50%;
                height: 20px;
                width: 20px;
                position: relative;
                background-color: #1d8cf8;

                &:before {
                    font-family: "fontello" !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-font-smoothing: antialiased;
                    font-size: 10px;
                    // custom
                    content: "\e80b";
                    color: white;
                }

                a {
                    display: block;
                    border-radius: 50%;
                    position: relative;
                    z-index: 123;
                    height: 100%;
                }
            }
        }

        .ui-table-pagination-jump-text {
            @include themify() {
                color: themed("ui-table-pagination-jump-text");
            }
            margin: 0 20px 0 7px;
        }

        .ui-table-pagination-jump-input {
            height: 26px;
            width: 5rem;
            text-align: center;
            display: inline-block;
        }
    }
    .ui-table-error {
        flex: auto;
        position: relative;
        .ui-table-warning {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .no-absolute {
                margin: 0 !important;
            }
            .ui-table-error-details {
                color: silver !important;
                font-size: 12px;
                margin-right: 15px;
                cursor: pointer;
                .icon {
                    margin-right: 10px;
                    display: inline-flex;
                }
            }
            .ui-table-error-section {
                margin-top: 15px;
                @include themify() {
                    background-color: themed("ui-table-errors-bg-color");
                }
                border-radius: 10px;
                padding: 10px;
                direction: ltr;
                text-align: left;
            }
        }
    }
}
.ui-table-inline-error {
    margin-top: 15px;
    .ui-table-inline-error-lable {
        color: red !important;
    }
    .ui-table-inline-error-more {
        color: silver !important;
        font-size: 12px;
        margin-right: 15px;
        cursor: pointer;
        .icon {
            margin-right: 10px;
            display: inline-flex;
        }
    }
    .ui-table-error-list {
        list-style: none;
        user-select: text;
        padding: 0;
        margin: 0;
        direction: rtl;
        margin-top: 15px;
        color: #939393;
        .ui-table-error-list-title {
            font-weight: bold;
        }
    }
}

.ui-table-popup-operation {
    @include themify() {
        background-color: themed("ui-table-popupinfo-bg-color");
    }

    @include borderRadius(20px, 20px, 0, 0);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 10%;
    left: 10%;
    bottom: -50px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    z-index: 999;
    min-height: 75px;

    &.vis {
        opacity: 1;
        bottom: -1px;
        pointer-events: all;
    }

    .information-area {
        margin: auto 0;
        display: flex;

        @include themify {
            color: themed("ui-table-popupinfo-text-color") !important;
        }
    }

    &.ui-table-popup-operation-sm {
        flex-direction: column;

        .information-area {
            display: block;
            text-align: center;
            padding: 8px 0 15px;
        }

        .buttons-area {
            display: block;
            text-align: center;
        }
    }
}
