// استایل های اینپوت دیت پیکر
.custom-input-datepicker {
    text-align: center;

    @include themify() {
        border: 1px solid themed("datepicker-input-border");
    }

    border-radius: 30px;
    padding: 0 15px;
    background: transparent;
    width: 173px;
    font-family: $font-family-default;
    display: inline-block;
    margin-left: 15px;
    height: 35px;
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    align-items: center;

    input {
        text-align: right;
        background: transparent;
        border: none;
        width: 100%;

        &:focus {
            outline: none;
            box-shadow: none !important;
        }
    }

    &:before {
        // custom
        content: "\e813"; //'';
        font-family: "fontello";
        speak: none;
        text-align: center;
        position: absolute;
        left: 9px;
        font-size: 20px;

        @include themify() {
            color: themed("datepicker-input-icon-color");
        }

        line-height: 23px;
    }
}

// استایل های دیت پیکر
.custom-datepicker {
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .JDheader {
        border-bottom: 1px solid #ddd;
        padding: 10px 15px;

        select {
            padding: 0 5px !important;
        }

        .time-container {
            display: flex;
        }
    }

    .right {
        border-left: 1px solid #ddd;

        input[type="tel"] {
            position: relative !important;
            width: calc(100% - 40px) !important;
            border: 1px solid #ccc !important;
            top: 0 !important;

            &:focus {
                outline: -webkit-focus-ring-color auto 5px !important;
                outline-color: -webkit-focus-ring-color !important;
                outline-style: auto !important;
                outline-width: 5px !important;
            }
        }
    }

    .day-items {
        padding: 5px 0;
    }

    .JC-days {
        border-bottom: 1px solid #ddd;
    }

    .JC-months {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;

        .holder {
            float: none !important;
            width: 100% !important;
        }
    }

    button {
        height: unset !important;
        width: unset !important;
        padding: 5px 15px !important;
        border-radius: 2px !important;
        font-size: $font-size-default !important;
    }

    .JDsubmit {
        background: $main-color !important;
        cursor: pointer;
        color: #ffffff;
    }

    .JDcancel {
        background-color: #ccc;
        cursor: pointer;
    }
}

.custom-date-picker-form-item {
    .ui-radio-group.horizontal{
        flex-wrap: wrap;
        .ui-radio{
            display: inline-block;
            width: 150px;
        }
    }
    .ant-form-item-control {
        line-height: 18px;

        .days-titles {
            padding: 5px 0;
            margin-bottom: 5px;
        }
    }

    .custom-input-datepicker {
        border: 1px solid $main-color;
        position: relative;

        &.disabled {
            border-color: $step-border;
        }
    }

    .custom-input-datepicker input.JDinput {
        @include themify() {
            color: themed("datepicker-input-text-color");
        }
    }

    .JDatePicker {
        right: 0;
        top: 30px;
        min-height: 347px;
        z-index: 10;

        .JC-days {
            min-height: 185px;
        }

        .day-items {
            border: 1px solid transparent;

            &.current-date {
                background: #d1e9ff;
                font-weight: bold;
                color: $main-color;
                border: 1px solid $main-color;
            }

            &:hover {
                background: #d1e9ff;
            }

            &.selected {
                background: $main-color;
                color: $white;
            }
        }

        .JDbuttons {
            text-align: left;
            display: flex;
            justify-content: space-between;
        }
    }

    &.small {
        .custom-input-datepicker {
            width: 150px !important;
        }
    }
}