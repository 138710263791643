@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';
//استایل پاپ اور
.popover {
    padding: 0;
    .ant-popover-inner-content {
      padding: 0;
    }
    .accountinfo {
      padding: 10px 0 0;
      display: flex;
      justify-content: space-between;
      button {
        border: 1px solid $main-color;
        color: $main-color;
      }
      p {
        flex: 1;
      }
      &-right {
        float: right;
        color: #333;
        padding: 10px 10px 0;
        .icon {
          font-size: 18px;
          float: right;
        }
      }
      &-toolbar {
        float: left;
        padding: 7px 10px 5px;
        .circle-toolbar-icon {
          float: left;
          height: 25px;
          width: 25px;
          border: 1px solid #1d8cf8;
          text-align: center;
          line-height: 27px;
          font-size: 18px;
          border-radius: 50%;
          .icon {
            color: #1d8cf8;
          }
        }
      }
    }
    .userinfo {
      @include gradient($gradient-cyan-left-color, $gradient-cyan-right-color, horizontalRight);
      padding: 5px 6px;
      display: flex;
      color: transparent;
  
      .img-wrap {
        position: relative;
        width: 185px;
        height: 140px;
        @include borderRadius(3%);
        border: 2px solid $box-classification;
        overflow: hidden;
        &-text {
          z-index: 0;
          width: 185px;
          height: 140px;
          position: absolute;
          top: 0;
          text-align: center;
          line-height: 140px;
          font-size: 2em;
        }
        .userinfo-avatar-loading {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          // width: 50px;
          // height: 50px;
          // @include borderRadius(50%);
        }
      }
  
      & > div {
        color: $white;
        margin: 2px;
        flex: 1;
      }
      .userinfo-data {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      & .major {
        flex: 1;
        margin: auto 10px auto 0;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      & .minor {
        // max-width: 60px;
        text-align: center;
        font-size: 1rem;
        margin: auto 10px auto 0;
        flex-direction: column;
        justify-content: center;
      }
    }
    .moreinfo {
      padding: 15px;
      border-bottom: 1px solid rgba($disabled-color, 0.3);
      width: 350px;
      text-align: justify;
    }
    .mediainfo {
      padding: 15px;
      div {
        display: flex;
        max-width: 330px;
        i {
          font-size: 2rem;
          color: $color-cyan;
        }
  
        p {
          flex: 1;
          text-align: left;
          position: relative;
          span {
            background: white;
            position: relative;
            z-index: 3;
            padding-right: 10px;
            a {
              overflow: hidden;
              max-height: 2rem;
              text-overflow: ellipsis;
              max-width: 220px;
              display: inline-block;
            }
          }
          &:after {
            position: absolute;
            left: 0;
            right: 10px;
            content: "";
            border-top: 1px dashed $disabled-color;
            top: 48%;
          }
        }
      }
    }
    &.userinfo-popover {
      margin: -10px;
      //border:1px solid #707070;
    }
  }