$color-light: #ffffff;
$color-primary0: #edf1f3;
$color-primary1: #ebf5ff;
$color-primary2: #c4e2ff;
$color-primary3: #62b1fe;
$color-primary4: #03a9f4;
$color-primary5: #2196f3;
$color-primary6: #1d8cf8;
$color-primary7: #3545d5;
$color-primary8: #3f51b5;
$color-primary9: #3b5998;
$color-primary10: #0c3858;
$color-primary11: #2f3447;
$color-primary12: #1d2333;
$color-primary13: #191d29;
$color-primary14: #070d17;
$color-primary15: #e6f2ff;
$color-primary16: #191d29;
$color-primary17: #192538;
$color-primary18: #2f3447;
$color-primary19: #708090;
$color-primary20: #ffffff1a;
$color-primary21: #c7e4ff;
$color-primary22: #eceff1;
$color-primary23: #d9edff;

$color-success1: #00e676;
$color-success2: #4dca73;
$color-success3: #1ac974;
$color-success4: #0ac17c;
$color-success5: #1d9360;
$color-success6: #cddc39;
$color-success7: #8bc34a;
$color-success8: #4caf50;
$color-success9: #35d5c6;
$color-success10: #0db7a7;
$color-success11: #009688;
$color-success12: #00bcd4;
$color-success13: #0aa8c1;
$color-success14: #eef7e2;
$color-success15: #7aaf2c;
$color-success16: #eef7e2;
$color-success17: #0aa8c1;
$color-success18: #edfef5;
$color-success19: #172c2e;

$color-danger1: #b71c1c;
$color-danger2: #ed1250;
$color-danger3: #e91e63;
$color-danger4: #b135d5;
$color-danger5: #9c27b0;
$color-danger6: #673ab7;
$color-danger7: #f7e2e2;
$color-danger8: #b71c1c;
$color-danger9: #ffefef;
$color-danger10: #292128;
$color-danger11: #281d2d;

$color-warning1: #fff6f3;
$color-warning2: #f6e8e4;
$color-warning3: #f8bba8;
$color-warning4: #f29e66;
$color-warning5: #ff9574;
$color-warning6: #f28766;
$color-warning7: #ff7043;
$color-warning8: #ff5722;
$color-warning9: #f44336;

$color-secondary1: #fafafa;
$color-secondary2: #f5f5f5;
$color-secondary3: #eceff1;
$color-secondary4: #d1d1d1;
$color-secondary5: #9e9e9e;
$color-secondary6: #757575;
$color-secondary7: #b5b5b5;
$color-secondary8: #e5e5e5;
$color-secondary9: #707070;
$color-secondary10: #dbdbdb;
$color-secondary11: #878787;
$color-secondary12: #aaa;

$color-info1: #ffc53d;
$color-info2: #ffba43;
$color-info3: #ed7a07;
$color-info4: #fef8ed;

$color-dark: #000000;
$color-dark1: #0000000f;

// chart public
$chart-lable0-color: #ffffff;
$chart-lable1-color: #757575;
$chart-lable2-color: #2f3447;
$chart-lable3-color: #000000;
$chart-lable4-color: #1d8cf8;
$chart-bg-color: #070d17;

// donut chart
$donut-chart-area1: #fb6868;
$donut-chart-area2: #51c8b1;
$donut-chart-area3: #62b1fe;
$donut-chart-area4: #51a3c8;
$donut-chart-area5: #9951c8;
$donut-chart-area6: #f5925f;
$donut-chart-area7: #5169c8;
$donut-chart-area8: #f3d388;
$donut-chart-area9: #5cc1e1;
$donut-chart-area10: #256bc7;
$donut-chart-area11: #999cff;
$donut-chart-area12: #6b358e;
$donut-chart-area13: #70cb7f;
$donut-chart-area14: #c0c851;
$donut-chart-area15: #be724c;
$donut-chart-area16: #bf5656;
$donut-chart-area17: #df4848;
$donut-chart-area18: #b7b7b7;

// line-chart
$line-chart-v-axis-color: $color-secondary4;
$line-chart-h-axis-color: $color-secondary4;
$line-chart-lable-color: $color-secondary6;
$line-chart-help-color: $color-secondary3;

$themes: (
    light: (
        data-empty-text: "#333",
        tag-content-multiselect-tag-color: black,
        tag-content-multiselect-tag-category-color: gray,
        tag-content-shared-popover-bg-color: white,
        tag-content-multiselect-tag-bg-color: transparent,
        tag-shared-department-info-color: #070d17,
        tag-shared-department-icon-color: #9e9e9e,
        anlyzer-content-detail-table-header-bg-color: #d1d1d1,
        anlyzer-content-detail-col-border-color: #eceff1,
        anlyzer-content-detail-col-bg-color: #f7f7f7,
        anlyzer-content-detail-col-title-color: #070d17,
        anlyzer-content-detail-first-col-bg-color: #ffffff,
        anlyzer-header-color: #ffffff,
        anlyzer-header-bg-color: #0c3858,
        analyzer-sidebar-bordr-bottom-color: #b5b5b5,
        analyzer-sidebar-name-color: #070d17,
        analyzer-sidebar-date-color: #707070,
        analyzer-sidebar-count-color: #070d17,
        analyzer-sidebar-a-active-color: #070d17,
        analyzer-dashboard-grid-border-color: #d1d1d1,
        analyzer-dashboard-grid-bg-color: #ffffff,
        analyzer-word-cloud-tab-bg-color: #0c3858,
        analyzer-word-cloud-tab-color: #d1d1d1,
        analyzer-word-cloud-tab-active-bg-color: #ffffff,
        analyzer-word-cloud-tab-active-color: #0c3858,
        add-wave-alarm-title-color: $color-dark,
        breadcrumb-item-color: #707070,
        breadcrumb-seperator-color: #9e9e9e,
        breadcrumb-last-item-color: #2f3447,
        breadcrumb-container-bg-color: #ffffff,
        breadcrumb-container-bb-color: #d1d1d1,
        cover-preview-header-bg-color: #ebf5ff,
        cover-preview-container-border-color: #d1d1d1,
        cover-preview-container-bg-color: #eceff1,
        cover-preview-title-color: #070d17,
        dashboard-more-icon-color: #0c3858,
        scrollbar-bg-color: $color-secondary4,
        scrollbar-thumb-bg-color: $color-secondary6,
        scrollbar-hover-bg-color: $color-secondary6,
        global-info-box-bg-color: $color-primary0,
        global-info-box-color: $color-secondary6,
        target-back-btn-border-color: $color-secondary6,
        target-back-icon-color: $color-secondary6,
        target-section-title-color: $color-primary14,
        target-section-border-color: $color-light,
        target-section-bg-color: $color-light,
        target-label-color: $color-primary11,
        target-badge-bg-color: $color-secondary4,
        target-badge-title-color: $color-primary14,
        introduction-bg-color: $color-secondary3,
        introduction-color: $color-secondary5,
        network-view-setting-h1-color: $color-dark,
        network-view-setting-h1-border-color: $color-secondary4,
        source-category-sidebar-text-color: $color-dark,
        mobile-header-bg-color: $color-primary12,
        mobile-top-navbar-bg-color: $color-secondary2,
        mobile-top-navbar-text-color: $color-dark,
        mobile-top-navbar-hr-color: $color-secondary5,
        mobile-platform-box-bg-color: $color-secondary2,
        mobile-platform-box-active-color: $color-primary6,
        mobile-platform-box-icon-color: $color-secondary5,
        mobile-platform-items-bg-color: $color-secondary4,
        mobile-platform-items-text-color: $color-dark,
        mobile-platform-items-active-text-color: $color-primary6,
        mobile-platform-after-color: $color-secondary5,
        html-sidebar-collapse-bg-color: $color-secondary4,
        html-sidebar-collapse-icon-color: $color-primary6,
        html-sidebar-name-color: $color-dark,
        html-sidebar-date-color: $color-primary11,
        html-platform-title-color: $color-primary14,
        html-platform-title-after-color: $color-secondary4,
        html-sidebar-timeline-text-color: $color-dark,
        html-sidebar-bulletin-title-color: $color-dark,
        bulletin-modal-bg-color: $color-secondary3,
        bulletin-modal-text-color: $color-dark,
        bulletin-modal-title-color: $color-dark,
        bulletin-modal-empty-result-color: $color-secondary1,
        bulletin-modal-collapse-content-bg-color: $color-light,
        bulletin-modal-collapse-content-color: $color-primary14,
        fetures-title-color: $color-dark,
        fetures-text-color: $color-dark,
        fetures-hr-color: $color-secondary3,
        GaugeColor-text-color: $color-dark,
        bulletin-content-last-child-bg-color: $color-secondary2,
        bulletin-content-last-child-hover-color: $color-secondary4,
        bulletin-content-last-child-icon-color: $color-primary6,
        bulletin-content-expanded-row-bg-color: $color-secondary2,
        bulletin-content-expanded-label-bg-color: $color-secondary4,
        bulletin-content-expanded-label-border-color: $color-secondary4,
        bulletin-content-expanded-label-subject-color: $color-dark,
        bulletin-content-expanded-label-title-color: $color-primary6,
        bulletin-content-actions-border-color: #3b5998,
        bulletin-content-newsCategory-border-color: $color-light,
        bulletin-content-newsCategory-bg-color: "transparent",
        department-select-api-label-color: $color-dark,
        department-boxes-label-color: $color-dark,
        department-icons-list-color: $color-primary6,
        steps-finish-title-color: $color-dark,
        steps-wait-icon-color: $color-secondary6,
        steps-process-icon-color: $color-primary6,
        steps-wait-title-color: $color-primary11,
        file-upload-bg-color: $color-secondary3,
        file-upload-icon-color: $color-secondary5,
        file-upload-title-color: $color-primary11,
        file-upload-description-color: $color-secondary6,
        smart-search-bg-color: $color-secondary1,
        smart-search-result-box-bg-color: $color-secondary3,
        smart-search-switch-btn-text-color: $color-dark,
        smart-search-item-bg-color: $color-secondary4,
        smart-search-item-color: $color-dark,
        smart-search-check-icon-color: $color-success4,
        smart-search-normal-recommend: $color-dark,
        dashboard-sidebar-text-color: $color-dark,
        dashboard-sidebar-disabled-color: $color-secondary6,
        dashboard-sidebar-active-bg-color: $color-primary2,
        dashboard-sidebar-hover-bg-color: $color-secondary3,
        dashboard-add-query-color: $color-dark,
        dashboard-add-selected-value-color: $color-dark,
        dashboard-snappshot-user-background: $color-secondary4,
        dashboard-snappshot-user-border: $color-secondary4,
        dashboard-snappshot-user: $color-dark,
        mb-tab-row-bg-color: $color-secondary3,
        mb-tab-title-color: $color-primary6,
        mb-tab-number-color: $color-secondary6,
        mb-tab-row-number-color: $color-dark,
        mb-list-bg-color: $color-secondary3,
        mb-list-text-color: $color-dark,
        profile-bg-color: $color-secondary2,
        profile-name-color: $color-primary6,
        profile-icon-number-color: $color-dark,
        profile-icon-color: $color-primary6,
        profile-icon-border-color: $color-primary6,
        profile-detail-text-color: $color-primary11,
        profile-icon-text-color: $color-dark,
        profile-email-color: $color-primary11,
        profile-detail-icon-color: $color-secondary6,
        profile-description-text-color: $color-secondary6,
        profile-switch-btn-text-color: $color-dark,
        profile-sidebar-title-color: $color-dark,
        profile-sidebar-title-seperator-color: $color-primary11,
        profile-minibox-bg-color: $color-light,
        profile-cache-input-text: $color-dark,
        direct-details-chatmessage-divider-color: $color-secondary3,
        comment-header-bg-color: $color-primary10,
        comment-bg-color: $color-secondary3,
        comment-border-color: $color-secondary4,
        comment-add-bg-color: $color-secondary1,
        comment-add-title-color: $color-dark,
        comment-add-icon-color: $color-dark,
        comment-box-bg-color: $color-secondary3,
        comment-delete-icon-border-color: $color-primary9,
        comment-delete-icon-color: $color-primary9,
        comment-box-text-color: $color-dark,
        comment-check-box-text-color: $color-primary11,
        comment-list-header-bg-color: $color-primary2,
        comment-list-body-bg-color: $color-primary0,
        comment-list-border-color: $color-secondary4,
        comment-list-description-color: $color-primary11,
        comment-list-date-color: $color-secondary6,
        comment-list-name-color: $color-dark,
        comment-list-avatar-border-color: $color-secondary6,
        comment-btn-color: $color-light,
        hashtag-row-bg-color: $color-secondary3,
        hashtag-title-color: $color-primary6,
        hashtag-header-bg-color: $color-primary6,
        hashtag-header-color: $color-light,
        hashtag-number-color: $color-secondary6,
        hashtag-row-number-color: $color-dark,
        apply-filter-button-bg-color: $color-secondary4,
        apply-filter-icon-color: $color-secondary6,
        apply-filter-button-color: $color-dark,
        apply-filter-icon-close-color: $color-success13,
        apply-filter-label-color: $color-secondary5,
        apply-filter-label-color-in-widget-wrapper: #d1d1d1,
        global-filter-navbar-color: $color-secondary4,
        minibox-bg-color: $color-light,
        minibox-title-color: $color-dark,
        minibox-pink-color: $color-danger3,
        minibox-green-color: $color-success1,
        minibox-follow-color: $color-warning8,
        minibox-main-color: $color-primary6,
        minibox-stats-row-bg-color: #eceff1,
        minibox-stats-row-color: $color-primary14,
        widget-builder-bg-color: white,
        widget-builder-border-color: $color-secondary4,
        widget-builder-box-header-bg-color: $color-primary10,
        widget-builder-box-header-title-color: $color-light,
        widget-builder-tab-bg-color: $color-secondary4,
        widget-builder-tab-color: $color-dark,
        widget-builder-active-tab-bg-color: $color-primary10,
        widget-builder-active-tab-color: $color-light,
        widget-builder-icon-border-color: $color-light,
        widget-builder-icon-color: $color-light,
        widget-builder-empty-data: $color-dark,
        counter-bg-color: $color-primary9,
        counter-color: $color-light,
        counter-text-color: $color-secondary4,
        counter-icon-color: $color-secondary5,
        login-header-bg-color: $color-primary11,
        login-body-bg-color: $color-secondary4,
        login-logo-border-color: $color-secondary5,
        login-input-icon-color: $color-primary6,
        login-input-placeholder-color: $color-secondary5,
        login-footer-divider-color: $color-secondary5,
        login-footer-link-color: $color-dark,
        login-header-slogan-color: $color-secondary3,
        login-input-bg-color: $color-light,
        login-captcha-reload-icon-color: #3b5998,
        login-input-color: $color-dark,
        login-warning-color: $color-danger1,
        text-color1: $color-dark,
        text-color2: $color-primary14,
        text-color3: $color-primary11,
        text-color4: $color-secondary6,
        datepicker-input-border: $color-primary6,
        datepicker-input-icon-color: $color-primary6,
        datepicker-input-text-color: $color-dark,
        datepicker-profile-input-text-color: $color-light,
        pagination-number-color: $color-primary11,
        previous-bg-color: $color-primary6,
        next-bg-color: $color-primary6,
        pagination-title-color: $color-primary11,
        pagination-input-border-color: $color-primary11,
        pagination-input-number-color: $color-primary6,
        result-sidebar-menu-color: $color-dark,
        result-sidebar-menu-type-color: $color-primary5,
        result-sidebar-icon-color: $color-secondary6,
        result-sidebar-number-color: $color-secondary6,
        result-sidebar-number-selected-color: $color-dark,
        result-bg-color: $color-primary0,
        result-search-box-bg-color: $color-light,
        result-search-box-input-border-color: $color-secondary4,
        result-search-collaps-bg-color: $color-light,
        result-search-collaps-icon-color: $color-primary14,
        result-search-collaps-title-color: $color-primary14,
        result-search-modal-collaps-title-color: $color-dark,
        result-filter-popup-label-color: $color-primary14,
        result-search-settings-text-color: $color-primary14,
        checkbox-border-color: #d1d1d1,
        checkbox-title-color: $color-primary11,
        boxTabel-bg-color: $color-primary1,
        box-header-tail-bg-color: $color-light,
        box-header-source-color: $color-primary9,
        box-source-border-color: $color-warning7,
        box-header-date-color: $color-primary11,
        box-header-info-icon-color: $color-secondary5,
        box-header-info-color: $color-secondary6,
        box-header-title-color: $color-dark,
        box-header-title-mute-color: $color-secondary12,
        box-collaps-icon-color: $color-secondary5,
        box-satisfaction-name-color: $color-dark,
        box-satisfaction-username-color: $color-secondary6,
        box-collaps-icon-border-color: $color-secondary5,
        box-header-icon-color: $color-secondary6,
        box-header-border-color: $color-secondary4,
        box-header-btn-color: $color-primary9,
        box-header-btn-color-hover: $color-warning7,
        box-content-bg-color: $color-secondary1,
        box-content-username-color: $color-primary6,
        box-content-info-color: $color-secondary6,
        box-content-date-color: $color-secondary6,
        box-content-gender-male-color: #00acc1,
        box-content-gender-female-color: #e91e63,
        box-content-gender-unknown-color: #757575,
        box-content-description-color: $color-dark,
        box-content-post-tag-bg-color: #f5f5f5,
        box-content-Similar-btn-border-color: $color-primary6,
        box-content-Similar-btn-color: $color-primary6,
        box-content-hot-news-btn-color: $color-primary6,
        box-content-summary-bg-color: $color-secondary2,
        box-content-summary-color: $color-primary14,
        box-content-news-body-color: $color-dark,
        box-content-related-post-bg-color: $color-secondary4,
        box-content-related-post-text-color: $color-primary14,
        box-content-related-post-user-id-color: $color-primary6,
        box-content-related-post-media-bg-color: $color-primary13,
        groupModal-bg-color: $color-secondary3,
        confirmbox-bg-color: $color-light,
        confirmbox-input-bg-color: $color-secondary8,
        confirmbox-title-color: $color-dark,
        confirmbox-description-color: $color-dark,
        toast-error-bg-color: $color-danger1,
        toast-success-bg-color: $color-success4,
        radio-border-color: $color-secondary6,
        radio-wrapper-color: $color-dark,
        search-text-color: $color-dark,
        search-page-title: $color-success10,
        search-colapse-title-color: $color-primary11,
        search-colapse-icon-color: $color-primary11,
        search-filter-title-color: $color-primary11,
        search-setting-label-color: $color-primary13,
        search-loading-bg-color: $color-secondary4,
        search-loading-text-color: $color-primary11,
        search-sidebar-cancel-btn-border-color: $color-secondary6,
        search-sidebar-cancel-btn-color: $color-secondary6,
        search-sidebar-icon-color: $color-primary6,
        search-sidebar-text-color: $color-dark,
        search-fixed-bg-color: $color-primary0,
        search-fixed-info-color: $color-secondary6,
        input-search-perfix-icon: $color-primary6,
        input-search-sufix-icon: $color-secondary4,
        input-title-color: #333,
        input-placeholder-color: $color-secondary5,
        input-number-input-color: $color-light,
        input-number-input-text-color: $color-dark,
        popupinfo-bg-color: $color-primary6,
        popupinfo-checkbox-wrapper-color: $color-light,
        popupinfo-text-color: $color-light,
        label-color: $color-dark,
        select-bg-color: transparent,
        select-color: white,
        select-bg-color-home-page: $color-secondary3,
        select-bg-color-result-search: white,
        select-color-result-search-time-duration: $color-primary14,
        select-color-public-settings: white,
        select-bg-color-page-number: #0c3858,
        select-multi-tag-bg-color: transparent,
        select-arrow-color: $color-primary6,
        select-dropdown-menu-color: $color-primary11,
        select-dropdown-menu-border-bottom-color: $color-secondary5,
        select-dropdown-bg-color: $color-primary0,
        select-value-color: $color-dark,
        select-selected-bg-color: $color-secondary4,
        select-selected-color: $color-dark,
        select-selected-icon-color: $color-dark,
        select-selected-tree-text-color: $color-dark,
        select-selected-placeholder-color: $color-secondary5,
        button-circle-border: $color-primary9,
        button-circle-hover: $color-secondary3,
        button-default-border: $color-light,
        button-default-bg-color: $color-primary6,
        button-orange-filled-color: $color-warning8,
        button-blue-border-color: $color-dark,
        button-orange-filled-border-color: $color-warning8,
        button-cancel-color: $color-secondary6,
        simple-Button-color: $color-primary6,
        simple-Button-border-color: $color-primary6,
        simple-Button-active-border-color: $color-primary6,
        body-bg-color: $color-primary0,
        header-bg-color: $color-primary12,
        header-border-bottom: $color-warning7,
        header-profile-modal-bg-color: $color-light,
        header-profile-modal-border-color: $color-secondary4,
        header-profile-modal-avatar-border-color: $color-secondary4,
        header-profile-modal-username-color: $color-dark,
        header-profile-modal-btn-bg-color: $color-light,
        header-profile-modal-btn-border-color: $color-primary6,
        header-profile-modal-btn-color: $color-primary6,
        header-profile-modal-arrow-bg-color: $color-light,
        sidebar-bg-color: $color-secondary2,
        sidebar-title-color: $color-primary13,
        sidebar-icon-title: $color-dark,
        sidebar-border-left-color: $color-secondary5,
        sidebar-selected-bg-color: $color-primary2,
        sidebar-selected-left-bg-color: $color-primary6,
        sidebar-inner-menu-after-bg-color: $color-primary6,
        sidebar-inner-menu-hover-bg-color: $color-primary2,
        sidebar-inner-menu-hover-text-color: $color-dark,
        sidebar-pin-color: $color-primary6,
        table-row-even-bg-color: $color-light,
        table-row-odd-bg-color: $color-primary1,
        table-row-border-color: $color-secondary4,
        table-row-selected-color: $color-primary1,
        table-body-color: $color-primary0,
        table-td-before-color: $color-primary1,
        table-td-color: $color-primary14,
        table-th-color: $color-secondary6,
        table-th-hover-bg-color: $color-primary1,
        boxes-sidebar-icon: $color-secondary6,
        boxes-add-api-config-queri-color: $color-dark,
        mini-sidebar-a-left-bg-color: $color-primary6,
        mini-sidebar-a-bg-color: $color-primary11,
        loading-bg-color: $color-primary10,
        modal-share-profile-label-color: $color-dark,
        modal-share-collapse-bg-color: #eceff1,
        modal-share-collapse-content-color: $color-dark,
        modal-share-collapse-header-color: $color-dark,
        row-label-color: $color-dark,
        line-chart-v-axis-color: $color-secondary4,
        line-chart-h-axis-color: $color-secondary4,
        line-chart-lable-color: $color-secondary6,
        line-chart-help-color: $color-secondary3,
        donut-chart-area1: #fb6868,
        donut-chart-area2: #51c8b1,
        donut-chart-area3: #62b1fe,
        donut-chart-area4: #51a3c8,
        donut-chart-area5: #9951c8,
        donut-chart-area6: #f5925f,
        donut-chart-area7: #5169c8,
        donut-chart-area8: #f3d388,
        donut-chart-area9: #5cc1e1,
        donut-chart-area10: #256bc7,
        donut-chart-area11: #999cff,
        donut-chart-area12: #6b358e,
        donut-chart-area13: #70cb7f,
        donut-chart-area14: #c0c851,
        donut-chart-area15: #be724c,
        donut-chart-area16: #bf5656,
        donut-chart-area17: #df4848,
        donut-chart-area18: #b7b7b7,
        chart-lable0-color: #ffffff,
        chart-lable1-color: #757575,
        chart-lable2-color: #2f3447,
        chart-lable3-color: #000000,
        chart-lable4-color: #1d8cf8,
        chart-bg-color: #070d17,
        message-box-container-bg-color: #edf1f3,
        message-box-container-color: #2f3447,
        search-box-planning-bg-color: $color-secondary4,
        search-box-planning-input-bg-color: $color-light,
        search-box-planning-input-border-color: $color-primary6,
        search-box-planning-text-color: $color-dark,
        search-box-planning-select-border-right: $color-primary6,
        search-box-planning-select-bg-color: $color-light,
        search-box-planning-select-value-color: $color-dark,
        search-box-planning-select-arrow-color: $color-light,
        search-box-planning-search-btn-bg-color: $color-primary6,
        search-box-planning-search-btn-color: $color-light,
        search-box-planning-close-btn-bg-color: $color-secondary4,
        search-box-planning-close-btn-color: $color-secondary6,
        schedule-down-btn-bg-color: $color-light,
        schedule-down-btn-color: $color-primary6,
        schedule-down-btn-border-color: $color-secondary4,
        schedule-down-btn-hover-bg-color: $color-light,
        schedule-i-reload-color: #3b5998,
        schedule-i-reload-border-color: #dbdbdb,
        schedule-popover-border-color: $color-secondary4,
        schedule-popover-inner-bg-color: $color-light,
        schedule-popover-arrow-border-color: $color-secondary4,
        schedule-radio-color: $color-dark,
        schedule-radio-border-bottom-color: $color-secondary4,
        schedule-radio-wrapper-hover-color: $color-primary11,
        schedule-radio-wrapper-hover-bg-color: $color-secondary3,
        schedule-down-btn-minute-bg-color: $color-primary12,
        chartLablelColor1: #000000,
        chartLablelColor2: #2f3447,
        chartLablelColor3: #000,
        chartLablelColor4: #1d8cf8,
        chartBgColor: $chart-bg-color,
        lineChartLableColor: $line-chart-lable-color,
        lineChartVAxisColor: $line-chart-v-axis-color,
        lineChartHAxisColor: $line-chart-h-axis-color,
        lineChartHelpColor: $line-chart-help-color,
        donutChartArea1: $donut-chart-area1,
        donutChartArea2: $donut-chart-area2,
        donutChartArea3: $donut-chart-area3,
        donutChartArea4: $donut-chart-area4,
        donutChartArea5: $donut-chart-area5,
        donutChartArea6: $donut-chart-area6,
        donutChartArea7: $donut-chart-area7,
        donutChartArea8: $donut-chart-area8,
        donutChartArea9: $donut-chart-area9,
        donutChartArea10: $donut-chart-area10,
        donutChartArea11: $donut-chart-area11,
        donutChartArea12: $donut-chart-area12,
        donutChartArea13: $donut-chart-area13,
        donutChartArea14: $donut-chart-area14,
        donutChartArea15: $donut-chart-area15,
        donutChartArea16: $donut-chart-area16,
        donutChartArea17: $donut-chart-area17,
        donutChartArea18: $donut-chart-area18,
        gaugeLineColor: $color-primary6,
        gaugeNonSpace: $color-secondary2,
        disabled-color: $color-secondary6,
        button-orange-filled: $color-warning8,
        button-orange-border: $color-warning7,
        button-orange-gradient-start: $color-warning8,
        button-orange-gradient-end: $color-warning7,
        confirm-box-prompt: $color-primary12,
        box-classification: $color-secondary7,
        disabled-button-bg-color: $color-secondary5,
        disabled-button-color: $color-secondary1,
        // planning-alert-box
        planning-alert-box-border-color: $color-secondary3,
        planning-alert-box-error-bg-color: $color-danger7,
        planning-alert-box-error-icon-color: $color-danger8,
        planning-alert-box-success-bg-color: $color-success14,
        planning-alert-box-close-icon-color: $color-dark,
        planning-alert-box-collapse-content-color: $color-dark,
        planning-alert-box-collapse-header-color: $color-dark,
        planning-alert-box-success-icon-color: $color-success15,
        planning-upload-media-delete-icon: $color-light,
        planning-upload-media-delete-icon-bg: $color-secondary5,
        planning-box-item-header-summary-status-icon-bg-color: $color-light,
        planning-box-item-header-summary-status-icon-color: #757575,
        planning-box-item-header-summary-status-label-color: #757575,
        planning-box-item-header-summary-status-bg-color: #eef7e2,
        planning-box-item-header-desc-text-color: #2f3447,
        planning-box-generator-each-box-bg: $color-primary1,
        planning-box-generator-each-box-color: $color-primary6,
        planning-box-generator-each-box-border-color: $color-primary6,
        planning-box-generator-each-box-deactive-bg: #eceff1,
        planning-box-generator-each-box-deactive-color: #757575,
        planning-box-generator-each-box-deactive-border-color: #d1d1d1,
        planning-box-generator-each-box-bg-hover: $color-light,
        planning-box-generator-each-box-hover: $color-primary6,
        planning-box-generator-each-box-icon: #8cc7ff,
        planning-box-item-avatar-border: $color-secondary4,
        planning-box-item-header-button-bg: $color-light,
        planning-box-time-ago: $color-dark,
        planning-box-item-header-button: $color-dark,
        planning-box-item-header-button-assign-bg: $color-success16,
        planning-box-item-header-button-selected-icon: $color-success17,
        planning-box-item-header-button-selected-other-assigned: #ebf5ff,
        planning-box-item-header-button-tag-selected-icon: #0aa8c1,
        planning-box-item-header-button-selected-bg: $color-primary1,
        planning-box-item-header-button-selected-title: #000,
        planning-box-modal-select-box-bg: $color-light,
        planning-box-item-header-assign-selected: $color-primary3,
        planning-box-item-selected: $color-primary2,
        planning-box-item-deleted: $color-secondary3,
        planning-box-drop-items-deleted-border-color: #a2a4a6,
        planning-box-item-footer-icon-send: $color-primary6,
        planning-box-item-footer-icon-color: #3b5998,
        planning-box-item-footer-icon-deactive: $color-secondary6,
        planning-instagram-item-bg: $color-light,
        planning-instagram-item-border: $color-secondary3,
        planning-instagram-item-body-img-border: $color-secondary4,
        planning-instagram-item-body-influencer-badge: $color-success13,
        planning-instagram-item-body-reply-to-user-name: $color-secondary5,
        planning-instagram-item-body-description: $color-dark,
        planning-instagram-item-header-reply-to-text: $color-secondary9,
        planning-instagram-item-comment-text-color: $color-dark,
        planning-box-item-header-button-icon: $color-secondary11,
        planning-box-item-footer-icon: $color-primary8,
        planning-header-border-color: $color-secondary4,
        planning-header-item-border-color: $color-secondary4,
        planning-box-header-background: $color-primary1,
        planning-box-header-color: $color-dark,
        planning-box-header-type-information: $color-info2,
        planning-box-item-footer-icon-liked: $color-danger1,
        planning-box-item-footer-text: $color-secondary9,
        planning-box-item-footer-comment-background: $color-secondary3,
        planning-box-load-more-footer-background-color: $color-light,
        planning-box-footer-inline-comments-background-color: #f0f6fc,
        planning-box-item-footer-comment-border: $color-secondary4,
        planning-tooltip-text: $color-light,
        planning-tooltip-background: $color-primary11,
        planning-tooltip-border: $color-primary14,
        planning-modal-like-history-title-border: $color-secondary5,
        planning-modal-like-history-each-liker-line: $color-secondary3,
        planning-dropdown-icons: $color-secondary9,
        planning-box-item-body-bg-color: $color-light,
        planning-box-item-body-color: $color-dark,
        planning-emoji-picker-bg-color: $color-light,
        planning-emoji-picker-border-color: $color-light,
        planning-modal-textarea-bg-color: #eceff1,
        planning-modal-textarea-border-color: #eceff1,
        planning-modal-textarea-tools-icon-color: $color-primary9,
        planning-modal-counter-color: $color-danger8,
        planning-modal-mention-name-color: #757575,
        planning-profile-actions-btn-color: $color-dark,
        planning-profile-actions-btn-border-color: $color-secondary4,
        planning-profile-user-name-color: $color-dark,
        planning-generator-select-platform-selected: $color-dark,
        planning-generator-account-select-selected: $color-dark,
        planning-header-little-account-list-menu-bg: $color-primary21,
        planning-bg-color: $color-primary22,
        planning-filter-config-label-color: $color-dark,
        planning-filter-config-top-nav-color: $color-dark,
        planning-filter-config-ant-input-number-color: $color-dark,
        planning-filter-config-ant-input-icon-color: $color-dark,
        planning-account-list-color: $color-dark,
        //carousel
        carousel-icon-left-right: $color-secondary6,
        //query-rules
        custom-query-top-menu: $color-dark,
        custom-query-parsed-query-color: $color-dark,
        //notification
        notification-empty-message: $color-light,
        //emoji-picker
        emoji-picker-selected-emoji-border-bottom: $color-secondary5,
        planing-box-header-select-color: $color-primary14,
        planing-box-header-share-color: $color-primary14,
        planing-box-header-section-bg-color: #c4e2ff,
        planing-box-header-select-account-color: $color-primary13,
        //planning DashboardModal
        planning-header-dashboard-icon-color: $color-secondary9,
        planing-header-color: $color-dark,
        planing-header-input-add-bg-color: #edf1f3,
        planing-header-bg-color: $color-light,
        planing-header-border-color: $color-secondary7,
        planning-header-item-active-background: $color-primary2,
        planning-header-item-active-border: $color-primary6,
        planning-header-item-hover-background: $color-primary1,
        planning-dashboard-modal-platform-bg-color: $color-light,
        planning-dashboard-modal-total-bg-color: $color-primary1,
        planning-dashboard-modal-assign-bg-color: $color-primary15,
        planning-dashboard-modal-not-assign-bg-color: $color-danger9,
        planning-dashboard-modal-close-bg-color: $color-success18,
        planning-dashboard-modal-body-color: $color-dark,
        planning-dashboard-modal-close-icon-color: $color-light,
        planning-dashboard-modal-header-bg-color: $color-primary12,
        planning-dashboard-modal-header-logo-bg-color: $color-primary11,
        planning-dashboard-modal-cell-bg-color: $color-dark,
        planning-dashboard-modal-cell-boder-color: $color-secondary4,
        planning-dashboard-modal-pick-task-bg-color: #fef8ed,
        planning-dashboard-modal-row-bg-color: $color-dark1,
        ant-dropdown-active-bg-color: #ebf5ff,
        ant-select-selection-selected-value: $color-dark,
        //Diff Text and Hashtag
        diff-header: $color-dark,
        diff-header-shadow: #d1d1d1,
        diff-header-bg: $color-light,
        diff-header-each-item: $color-dark,
        diff-header-each-item-bg: $color-primary22,
        diff-table-box-general-col: #070d17,
        diff-table-box-general-col-bg: #edfef5,
        diff-table-box-general-col-border: #d1d1d1,
        diff-table-box-1-col: #070d17,
        diff-table-box-1-col-bg: white,
        diff-table-box-1-col-border: #d1d1d1,
        diff-table-box-2-col: $donut-chart-area3,
        diff-table-box-2-col-bg: #ebf2f9,
        diff-table-box-2-col-border: #d1d1d1,
        diff-table-box-3-col: $donut-chart-area1,
        diff-table-box-3-col-bg: #fff2f2,
        diff-table-box-3-col-border: #d1d1d1,
        diff-table-box-4-col: $donut-chart-area2,
        diff-table-box-4-col-bg: #e8f3f1,
        diff-table-box-4-col-border: #d1d1d1,
        diff-table-box-5-col: $donut-chart-area5,
        diff-table-box-5-col-bg: #f7f1fb,
        diff-table-box-5-col-border: #d1d1d1,
        diff-table-box-1-contain-platform: $color-secondary6,
        diff-boxes-line-chart-select: $color-dark,
        diff-boxes-line-chart-date: $color-light,
        //assignment-details-footer
        assignment-details-footer-divider-bg-color: $color-secondary4,
        assignment-sidebar-new-item-bg-color: #c4e2ff,
        assignment-sidebar-input-new-item-bg-color: #edf1f3,
        assignment-details-footer-text-wrapper-color: $color-danger1,
        assignment-details-footer-media-icon-bg-color: $color-secondary5,
        assignment-details-footer-media-icon-color: $color-light,
        assignment-details-footer-drop-items-divider-bg-color: $color-secondary4,
        assignment-details-footer-dropdown-box-shadow-color: #00000080,
        assignment-details-footer-dropdown-hover-bg-color: $color-secondary3,
        assignment-details-footer-textarea-bg-color: $color-light,
        //assignment-details-content
        assignment-details-content-bg-color: $color-light,
        assignment-details-content-twitter-item-border-color: $color-primary22,
        //assignment-details-status
        assignment-details-status-bg-color: $color-light,
        assignment-details-status-border-color: $color-primary22,
        assignment-details-status-header-bg-color: $color-primary22,
        assignment-details-status-header-color: $color-dark,
        assignment-details-status-account-select-label-color: #757575,
        assignment-details-status-account-select-color: #757575,
        assignment-details-status-header-border-bottom-color: $color-primary22,
        assignment-details-status-header-menu-active-bg-color: $color-primary23,
        assignment-details-status-header-menu-border-bottom-color: $color-primary22,
        assignment-details-status-header-menu-active-border-bottom-color: $color-primary6,
        assignment-details-status-header-menu-color: $color-dark,
        //assignment-header
        assignment-header-bg-color: $color-secondary2,
        assignment-header-color: $color-dark,
        assignment-header-drop-item-border-bottom-color: $color-secondary4,
        assignment-header-total-text-color: #757575,
        assignment-header-total-number-color: $color-dark,
        //assignment-dashboard
        assignment-dashboard-empty-details-bg-color: $color-light,
        assignment-dashboard-empty-details-after-bg-color: $color-primary1,
        assignment-dashboard-box-planning-shadow-color: rgba(0, 0, 0, 0.2),
        assignment-dashboard-box-planning-border-right-color: #eceff1,
        assignment-dashboard-empty-details-shadow-color: $color-primary22,
        //assignment-details-header
        assignment-details-header-bg-color: $color-light,
        assignment-details-header-color: $color-dark,
        assignment-details-header-border-bottom-color: $color-primary22,
        //assignment-details-history
        assignment-details-history-bg-color: $color-light,
        assignment-details-history-color: $color-dark,
        assignment-details-history-border-color: #d9d9d9,
        sentiment-modal-bg-color: $color-light,
        sentiment-modal-color: $color-primary11,
        planning-input-filter-assignment: $color-dark,
        ant-dropdown-menu-bg-color: #fff,
        ant-dropdown-menu-color: #333,
        ant-dropdown-menu-bg-color-hover: #eceff1,
        schedule-account-username-color: $color-dark,
        schedule-header-border-color: #d5d5d5,
        schedule-header-bg-color: #f5f5f5,
        landing-grid-bg-color: #fafafa,
        landing-list-style-li-bg-color: #f6f7f8,
        landing-list-style-a-color: $color-dark,
        landing-list-style-a-hover-bg-color: #eceff1,
        landing-list-style-left-section-color: #707070,
        landing-list-style-right-section-color: #191d29,
        landing-grid-border-color: #d1d1d1,
        landing-content-child-tag-color: #2f3447,
        landing-content-parent-tag-color: #9e9e9e,
        box-item-description-color: #000000,
        resources-color: $color-primary12,
        resources-bg-color: $color-primary22,
        sources-wrapper-bg-color: $color-light,
        tag-selected-border-color: #d1d1d1,
        tag-selected-color: #191d29,
        tag-content-color: rgba(0, 0, 0, 0.65),
        tag-content-background-color-hover: $color-secondary3,
        tag-content-background: #ffffff,
        tag-content-border: #d9d9d9,
        diff-filter-bg-color: #ffffff,
        diff-filter-border-color: #d1d1d1,
        diff-filter-label-color: $color-dark,
        content-cluster-color: $color-dark,
        content-cluster-border-top-color: #b5b5b5,
        fusioncharts-list-bg-color: #eceff1,
        fusioncharts-list-category-color: #2f3447,
        fusioncharts-list-sub-category-color: #757575,
        reply-mention-popover-bg-color: $color-light,
        reply-mention-name-color: #757575,
        reply-mention-title-color: #472f2f,
        box-rank-cluster-number-color: #757575,
        box-content-hot-news-select-bg-color: #0c3858,
    ),
    dark: (
        tag-content-color: $color-secondary4,
        tag-content-background-color-hover: $color-primary12,
        tag-content-background: $color-primary16,
        tag-content-border: $color-primary18,
        data-empty-text: white,
        tag-content-multiselect-tag-color: white,
        tag-content-multiselect-tag-category-color: gray,
        tag-content-shared-popover-bg-color: #2f3447,
        tag-content-multiselect-tag-bg-color: #191d29,
        tag-shared-department-info-color: #ffffff,
        tag-shared-department-icon-color: #9e9e9e,
        anlyzer-content-detail-table-header-bg-color: #373b4d,
        anlyzer-content-detail-col-border-color: #2f3447,
        anlyzer-content-detail-col-bg-color: #222631,
        anlyzer-content-detail-col-title-color: #d1d1d1,
        anlyzer-content-detail-first-col-bg-color: #191d29,
        anlyzer-header-color: #ffffff,
        anlyzer-header-bg-color: #191d29,
        analyzer-sidebar-bordr-bottom-color: #2f3447,
        analyzer-sidebar-name-color: #eceff1,
        analyzer-sidebar-date-color: #d1d1d1,
        analyzer-sidebar-count-color: #ffffff,
        analyzer-sidebar-a-active-color: #ffffff,
        analyzer-dashboard-grid-border-color: #2f3447,
        analyzer-dashboard-grid-bg-color: #070d17,
        analyzer-word-cloud-tab-bg-color: #191d29,
        analyzer-word-cloud-tab-color: #d1d1d1,
        analyzer-word-cloud-tab-active-bg-color: #070d17,
        analyzer-word-cloud-tab-active-color: #ffffff,
        add-wave-alarm-title-color: $color-light,
        //assignment-details-history
        assignment-details-history-bg-color: $color-primary13,
        assignment-details-history-color: $color-light,
        assignment-details-history-border-color: #2f3447,
        //assignment-details-header
        assignment-details-header-bg-color: $color-primary16,
        assignment-details-header-color: $color-light,
        assignment-details-header-border-bottom-color: $color-primary12,
        //assignment-dashboard
        assignment-dashboard-empty-details-bg-color: $color-primary16,
        assignment-dashboard-empty-details-after-bg-color: $color-primary12,
        assignment-dashboard-box-planning-shadow-color: $color-primary11,
        assignment-dashboard-box-planning-border-right-color: #2f3447,
        assignment-dashboard-empty-details-shadow-color: $color-primary12,
        //assignment-header
        assignment-header-bg-color: $color-primary11,
        assignment-header-color: $color-light,
        assignment-header-drop-item-border-bottom-color: $color-primary12,
        assignment-header-total-text-color: #d9d9d9,
        assignment-header-total-number-color: $color-light,
        //assignment-details-status
        assignment-details-status-bg-color: $color-primary13,
        assignment-details-status-border-color: $color-primary18,
        assignment-details-status-header-bg-color: $color-primary11,
        assignment-details-status-header-color: $color-light,
        assignment-details-status-account-select-label-color: #757575,
        assignment-details-status-account-select-color: #ffffff,
        assignment-details-status-header-border-bottom-bg-color: $color-primary16,
        assignment-details-status-header-menu-active-bg-color: $color-primary11,
        assignment-details-status-header-menu-border-bottom-color: $color-primary11,
        assignment-details-status-header-menu-active-border-bottom-color: $color-primary6,
        assignment-details-status-header-menu-color: $color-light,
        //assignment-details-content
        assignment-details-content-bg-color: $color-primary13,
        assignment-details-content-color: $color-light,
        assignment-details-content-twitter-item-border-color: $color-primary18,
        //assignment-details-footer
        assignment-details-footer-divider-bg-color: $color-primary12,
        assignment-sidebar-new-item-bg-color: #2f3447,
        assignment-sidebar-input-new-item-bg-color: #070d17,
        assignment-details-footer-text-wrapper-color: $color-danger1,
        assignment-details-footer-media-icon-bg-color: $color-secondary4,
        assignment-details-footer-media-icon-color: $color-dark,
        assignment-details-footer-drop-items-divider-bg-color: $color-secondary5,
        assignment-details-footer-dropdown-box-shadow-color: #00000080,
        assignment-details-footer-dropdown-hover-bg-color: $color-secondary3,
        assignment-details-footer-textarea-bg-color: #191d29,
        breadcrumb-item-color: #d1d1d1,
        breadcrumb-seperator-color: #707070,
        breadcrumb-last-item-color: #ffffff,
        breadcrumb-container-bg-color: #1d2333,
        breadcrumb-container-bb-color: #757575,
        cover-preview-header-bg-color: #2f3447,
        cover-preview-container-border-color: #2f3447,
        cover-preview-container-bg-color: #070d17,
        cover-preview-title-color: #ffffff,
        dashboard-more-icon-color: #ffffff,
        direct-details-chatmessage-bg-color: $color-primary14,
        direct-details-chatmessage-color: $color-secondary4,
        direct-details-chatmessage-divider-color: $color-primary18,
        scrollbar-bg-color: $color-secondary4,
        scrollbar-thumb-bg-color: $color-primary6,
        scrollbar-hover-bg-color: $color-primary6,
        global-info-box-bg-color: $color-primary11,
        global-info-box-color: $color-light,
        target-back-btn-border-color: $color-secondary4,
        target-back-icon-color: $color-secondary4,
        target-section-title-color: $color-light,
        target-section-border-color: $color-primary11,
        target-section-bg-color: $color-primary14,
        target-label-color: $color-secondary4,
        target-badge-bg-color: $color-secondary6,
        target-badge-title-color: $color-light,
        introduction-bg-color: $color-primary13,
        introduction-color: $color-secondary5,
        //network
        network-view-setting-h1-color: $color-light,
        //
        network-view-setting-h1-border-color: $color-secondary4,
        //
        //source-category
        source-category-sidebar-text-color: $color-light,
        //
        //mobile-bulletin
        mobile-header-bg-color: $color-primary11,
        //
        mobile-top-navbar-bg-color: $color-primary13,
        //
        mobile-top-navbar-text-color: $color-light,
        //
        mobile-top-navbar-hr-color: $color-secondary5,
        //
        mobile-platform-box-bg-color: $color-primary13,
        //
        mobile-platform-box-active-color: $color-primary6,
        //
        mobile-platform-box-icon-color: $color-secondary1,
        //
        mobile-platform-items-bg-color: $color-primary13,
        //,
        mobile-platform-items-text-color: $color-secondary4,
        //
        mobile-platform-items-active-text-color: $color-light,
        //
        mobile-platform-after-color: $color-secondary5,
        //
        //html-bulletin
        html-sidebar-collapse-bg-color: $color-primary11,
        //
        html-sidebar-collapse-icon-color: $color-primary6,
        //
        html-sidebar-name-color: $color-light,
        //
        html-sidebar-date-color: $color-secondary4,
        //
        html-platform-title-color: $color-light,
        //
        html-platform-title-after-color: $color-primary11,
        //
        html-sidebar-timeline-text-color: $color-secondary4,
        //
        html-sidebar-bulletin-title-color: $color-light,
        //
        //bulletin-modal
        bulletin-modal-bg-color: $color-primary13,
        bulletin-modal-text-color: $color-light,
        bulletin-modal-title-color: $color-light,
        bulletin-modal-empty-result-color: $color-primary13,
        bulletin-modal-collapse-content-bg-color: $color-primary12,
        bulletin-modal-collapse-content-color: $color-secondary4,
        //fetures
        fetures-title-color: $color-light,
        fetures-text-color: $color-light,
        fetures-hr-color: $color-secondary3,
        //dashboard-multicolor
        GaugeColor-text-color: $color-light,
        //bulletin-content
        bulletin-content-last-child-bg-color: $color-primary11,
        bulletin-content-last-child-hover-color: $color-primary12,
        bulletin-content-last-child-icon-color: $color-light,
        bulletin-content-expanded-row-bg-color: $color-primary12,
        bulletin-content-expanded-label-bg-color: $color-primary13,
        bulletin-content-expanded-label-border-color: $color-primary13,
        bulletin-content-expanded-label-subject-color: $color-light,
        bulletin-content-expanded-label-title-color: $color-primary6,
        bulletin-content-actions-border-color: $color-light,
        bulletin-content-newsCategory-border-color: $color-secondary6,
        bulletin-content-newsCategory-bg-color: "transparent",
        //department
        department-select-api-label-color: $color-light,
        department-boxes-label-color: $color-light,
        department-icons-list-color: $color-primary6,
        //steps
        steps-finish-title-color: $color-light,
        steps-wait-icon-color: $color-secondary6,
        steps-process-icon-color: $color-primary6,
        steps-wait-title-color: $color-primary11,
        //file-upload
        file-upload-bg-color: $color-primary14,
        //
        file-upload-icon-color: $color-light,
        //
        file-upload-title-color: $color-light,
        //
        file-upload-description-color: $color-secondary5,
        //
        //smart-search
        smart-search-bg-color: $color-primary12,
        //
        smart-search-result-box-bg-color: $color-primary13,
        //
        smart-search-switch-btn-text-color: $color-light,
        //
        smart-search-item-bg-color: $color-primary11,
        //
        smart-search-item-color: $color-light,
        //
        smart-search-check-icon-color: $color-success4,
        //
        smart-search-normal-recommend: $color-light,
        //dashboard
        dashboard-sidebar-text-color: $color-light,
        dashboard-sidebar-disabled-color: $color-secondary6,
        dashboard-sidebar-active-bg-color: $color-primary2,
        dashboard-sidebar-hover-bg-color: $color-primary12,
        dashboard-add-query-color: $color-light,
        dashboard-add-selected-value-color: $color-light,
        //
        dashboard-snappshot-user-background: $color-secondary4,
        //
        dashboard-snappshot-user-border: $color-secondary4,
        //
        dashboard-snappshot-user: $color-light,
        //
        //mb-tabs
        mb-tab-row-bg-color: $color-secondary3,
        mb-tab-title-color: $color-primary6,
        mb-tab-number-color: $color-secondary6,
        mb-tab-row-number-color: $color-light,
        //mb-list
        mb-list-bg-color: $color-primary12,
        //
        mb-list-text-color: $color-light,
        //profile
        profile-bg-color: $color-primary13,
        //
        profile-name-color: $color-light,
        //
        profile-icon-number-color: $color-light,
        //
        profile-icon-color: $color-light,
        //
        profile-icon-border-color: $color-light,
        //
        profile-detail-text-color: $color-secondary1,
        //
        profile-icon-text-color: $color-light,
        profile-email-color: $color-secondary6,
        //
        profile-detail-icon-color: $color-secondary6,
        profile-description-text-color: $color-secondary1,
        //
        profile-switch-btn-text-color: $color-light,
        //
        profile-sidebar-title-color: $color-light,
        //
        profile-sidebar-title-seperator-color: $color-primary11,
        profile-minibox-bg-color: $color-primary11,
        profile-cache-input-text: $color-light,
        //entity-comment
        comment-header-bg-color: $color-primary13,
        //
        comment-bg-color: $color-primary12,
        //
        comment-border-color: $color-primary12,
        //
        comment-add-bg-color: $color-primary11,
        //
        comment-add-title-color: $color-light,
        //
        comment-add-icon-color: $color-secondary3,
        //
        comment-box-bg-color: $color-primary12,
        //
        comment-delete-icon-border-color: $color-secondary3,
        //
        comment-delete-icon-color: $color-secondary3,
        comment-btn-color: $color-light,
        //
        comment-box-text-color: $color-light,
        //
        comment-check-box-text-color: $color-secondary4,
        //
        comment-list-header-bg-color: $color-primary14,
        //
        comment-list-body-bg-color: $color-primary13,
        //
        comment-list-border-color: $color-primary13,
        //
        comment-list-description-color: $color-light,
        //
        comment-list-date-color: $color-secondary5,
        //
        comment-list-name-color: $color-secondary3,
        //
        comment-list-avatar-border-color: $color-secondary6,
        //hashtag
        hashtag-row-bg-color: $color-primary12,
        //
        hashtag-title-color: $color-primary6,
        //
        hashtag-header-bg-color: $color-primary6,
        hashtag-header-color: $color-light,
        //
        hashtag-number-color: $color-light,
        //
        hashtag-row-number-color: $color-light,
        //
        //apply-filter
        apply-filter-button-bg-color: $color-primary11,
        //
        apply-filter-icon-color: $color-light,
        //
        apply-filter-button-color: $color-light,
        //
        apply-filter-icon-close-color: $color-secondary6,
        //
        apply-filter-label-color: $color-secondary5,
        apply-filter-label-color-in-widget-wrapper: #707070,
        //
        //global filter navbar
        global-filter-navbar-color: $color-secondary4,
        //mini-box
        minibox-bg-color: $color-primary11,
        //
        minibox-title-color: $color-light,
        //
        minibox-pink-color: $color-danger3,
        //
        minibox-green-color: $color-success1,
        //
        minibox-follow-color: $color-warning8,
        //
        minibox-main-color: $color-primary6,
        minibox-stats-row-bg-color: $color-primary13,
        minibox-stats-row-color: #eceff1,
        //widget-builder
        widget-builder-bg-color: $color-primary14,
        //
        widget-builder-border-color: $color-primary12,
        //
        widget-builder-box-header-bg-color: $color-primary13,
        //
        widget-builder-box-header-title-color: $color-light,
        //
        widget-builder-tab-bg-color: $color-primary11,
        //
        widget-builder-tab-color: $color-light,
        //
        widget-builder-active-tab-bg-color: $color-primary13,
        //
        widget-builder-active-tab-color: $color-light,
        //
        widget-builder-icon-border-color: $color-light,
        //
        widget-builder-icon-color: $color-light,
        //
        widget-builder-empty-data: $color-light,
        // کانتر
        counter-bg-color: $color-primary9,
        counter-color: $color-light,
        counter-text-color: $color-secondary4,
        counter-icon-color: $color-secondary5,
        // لاگین
        login-header-bg-color: $color-primary11,
        //
        login-body-bg-color: $color-primary14,
        //
        login-logo-border-color: $color-secondary6,
        //
        login-input-icon-color: $color-primary6,
        //
        login-input-placeholder-color: $color-secondary5,
        //
        login-footer-divider-color: $color-secondary5,
        //
        login-footer-link-color: $color-light,
        //
        login-header-slogan-color: $color-secondary3,
        //
        login-input-bg-color: #2f3447,
        login-captcha-reload-icon-color: $color-light,
        //
        login-input-color: $color-light,
        //
        login-warning-color: $color-danger1,
        //
        // رنگ فونت ها
        text-color1: $color-light,
        text-color2: $color-primary14,
        text-color3: $color-primary11,
        text-color4: $color-secondary6,
        //datepicker
        datepicker-input-border: $color-primary6,
        //
        datepicker-input-icon-color: $color-light,
        //
        datepicker-input-text-color: $color-light,
        //
        datepicker-profile-input-text-color: $color-light,
        //pagination
        pagination-number-color: $color-light,
        //
        previous-bg-color: $color-primary6,
        //
        next-bg-color: $color-primary6,
        //
        pagination-title-color: $color-light,
        //
        pagination-input-border-color: $color-light,
        //
        pagination-input-number-color: $color-primary6,
        //
        //result
        result-sidebar-menu-color: $color-light,
        //
        result-sidebar-menu-type-color: $color-primary5,
        //
        result-sidebar-icon-color: $color-secondary1,
        //
        result-sidebar-number-color: $color-primary6,
        //
        result-sidebar-number-selected-color: $color-light,
        //
        result-bg-color: $color-primary11,
        //
        result-search-box-bg-color: $color-primary14,
        result-search-box-input-border-color: $color-secondary4,
        //
        result-search-collaps-bg-color: $color-primary14,
        //
        result-search-collaps-icon-color: $color-light,
        //
        result-search-collaps-title-color: $color-primary0,
        result-search-modal-collaps-title-color: $color-dark,
        result-filter-popup-label-color: $color-primary0,
        //
        result-search-settings-text-color: $color-light,
        //
        //checkbox
        checkbox-border-color: #757575,
        //
        checkbox-title-color: $color-light,
        //
        //box-tabel
        boxTabel-bg-color: $color-primary12,
        //
        box-header-tail-bg-color: $color-primary11,
        //
        box-header-source-color: $color-warning8,
        //
        box-source-border-color: $color-primary11,
        //
        box-header-date-color: $color-primary6,
        //
        box-header-info-icon-color: $color-secondary4,
        //
        box-header-info-color: $color-light,
        //
        box-header-title-color: $color-light,
        box-header-title-mute-color: $color-secondary6,
        box-collaps-icon-color: $color-secondary4,
        box-satisfaction-name-color: $color-light,
        box-satisfaction-username-color: $color-secondary7,
        //
        box-collaps-icon-border-color: $color-secondary4,
        //
        box-header-icon-color: $color-secondary3,
        //
        box-header-border-color: $color-primary12,
        //
        box-header-btn-color: $color-light,
        //
        box-header-btn-color-hover: $color-primary6,
        //
        box-content-bg-color: $color-primary13,
        //
        box-content-username-color: $color-light,
        //
        box-content-info-color: $color-secondary5,
        //
        box-content-date-color: $color-secondary5,
        //
        box-content-gender-male-color: #00acc1,
        box-content-gender-female-color: #e91e63,
        box-content-gender-unknown-color: #eceff1,
        box-content-description-color: $color-light,
        box-content-post-tag-bg-color: #191d29,
        //
        box-content-Similar-btn-border-color: $color-light,
        //
        box-content-Similar-btn-color: $color-light,
        box-content-hot-news-btn-color: $color-light,
        //
        box-content-summary-bg-color: $color-primary12,
        box-content-summary-color: $color-secondary5,
        box-content-news-body-color: $color-light,
        box-content-related-post-bg-color: $color-primary14,
        box-content-related-post-text-color: $color-primary1,
        box-content-related-post-user-id-color: $color-primary6,
        box-content-related-post-media-bg-color: $color-primary13,
        //group-modal
        groupModal-bg-color: $color-primary13,
        //
        //confirmbox
        confirmbox-bg-color: $color-primary12,
        confirmbox-input-bg-color: $color-secondary1,
        //
        confirmbox-title-color: $color-light,
        //
        confirmbox-description-color: $color-secondary4,
        //
        //toast
        toast-error-bg-color: $color-danger1,
        //
        toast-success-bg-color: $color-success4,
        //
        //radio-button
        radio-border-color: $color-secondary6,
        //
        radio-wrapper-color: $color-light,
        //
        //search
        search-text-color: $color-light,
        //
        search-page-title: $color-secondary3,
        //
        search-colapse-title-color: $color-light,
        //
        search-colapse-icon-color: $color-secondary5,
        //
        search-filter-title-color: $color-light,
        search-setting-label-color: $color-primary0,
        //,
        search-loading-bg-color: $color-primary11,
        search-loading-text-color: $color-secondary5,
        // search sidebar
        search-sidebar-cancel-btn-border-color: $color-secondary6,
        search-sidebar-cancel-btn-color: $color-secondary6,
        search-sidebar-icon-color: $color-primary6,
        search-sidebar-text-color: $color-light,
        // search fixed
        search-fixed-bg-color: $color-primary14,
        //
        search-fixed-info-color: $color-light,
        //
        //input
        input-search-perfix-icon: $color-primary6,
        //
        input-search-sufix-icon: $color-secondary4,
        //
        input-title-color: $color-light,
        //
        input-placeholder-color: $color-secondary5,
        input-number-input-color: $color-light,
        input-number-input-text-color: $color-light,
        //popup-info
        popupinfo-bg-color: $color-primary6,
        popupinfo-checkbox-wrapper-color: $color-light,
        popupinfo-text-color: $color-light,
        //form-label
        label-color: $color-light,
        //select-box
        select-bg-color: $color-primary14,
        select-color: white,
        select-bg-color-home-page: $color-primary14,
        select-bg-color-result-search: $color-primary14,
        select-color-result-search-time-duration: white,
        select-color-public-settings: white,
        select-bg-color-page-number: #191d29,
        select-multi-tag-bg-color: #191d29,
        select-arrow-color: $color-light,
        select-dropdown-menu-color: $color-light,
        select-dropdown-menu-border-bottom-color: $color-secondary5,
        select-dropdown-bg-color: $color-primary14,
        select-value-color: $color-light,
        select-selected-bg-color: $color-secondary4,
        select-selected-color: $color-light,
        select-selected-icon-color: $color-light,
        select-selected-tree-text-color: $color-light,
        select-selected-placeholder-color: $color-secondary5,
        //button
        button-circle-border: $color-primary9,
        button-circle-hover: $color-secondary3,
        button-default-border: $color-light,
        button-default-bg-color: $color-primary6,
        button-orange-filled-color: $color-light,
        button-blue-border-color: $color-light,
        //
        button-orange-filled-border-color: $color-warning8,
        button-cancel-color: $color-secondary6,
        simple-Button-color: $color-primary6,
        simple-Button-border-color: $color-primary6,
        simple-Button-active-border-color: $color-primary6,
        //body
        body-bg-color: $color-primary14,
        //
        //header
        header-bg-color: $color-primary12,
        //
        header-border-bottom: $color-warning7,
        //
        header-profile-modal-bg-color: $color-primary11,
        //
        header-profile-modal-border-color: $color-primary11,
        //
        header-profile-modal-avatar-border-color: $color-secondary6,
        //
        header-profile-modal-username-color: $color-light,
        //
        header-profile-modal-btn-bg-color: $color-primary11,
        //
        header-profile-modal-btn-border-color: $color-primary6,
        header-profile-modal-btn-color: $color-light,
        //
        header-profile-modal-arrow-bg-color: $color-primary11,
        //
        //sidebar
        sidebar-bg-color: $color-primary13,
        //
        sidebar-title-color: $color-secondary4,
        //
        sidebar-icon-title: $color-light,
        //
        sidebar-border-left-color: $color-primary13,
        //
        sidebar-selected-bg-color: $color-primary11,
        //
        sidebar-selected-left-bg-color: $color-primary6,
        //
        sidebar-inner-menu-after-bg-color: $color-primary6,
        sidebar-inner-menu-hover-bg-color: $color-primary11,
        sidebar-inner-menu-hover-text-color: $color-light,
        sidebar-pin-color: $color-primary6,
        //
        //table
        table-row-even-bg-color: $color-primary12,
        //
        table-row-odd-bg-color: $color-primary11,
        //
        table-row-border-color: $color-primary12,
        //
        table-row-selected-color: $color-primary1,
        table-body-color: $color-primary14,
        //
        table-td-before-color: $color-primary1,
        table-td-color: $color-primary0,
        //
        table-th-color: $color-secondary4,
        //
        table-th-hover-bg-color: $color-primary13,
        //boxes
        boxes-sidebar-icon: $color-secondary5,
        boxes-add-api-config-queri-color: $color-light,
        //
        // mini-sidebar
        mini-sidebar-a-left-bg-color: $color-primary6,
        //
        mini-sidebar-a-bg-color: $color-primary11,
        //
        // loading
        loading-bg-color: $color-secondary5,
        //
        modal-share-profile-label-color: $color-light,
        modal-share-collapse-bg-color: #191d29,
        modal-share-collapse-content-color: $color-light,
        modal-share-collapse-header-color: $color-light,
        // global-styles
        row-label-color: $color-dark,
        //
        // line-chart
        line-chart-v-axis-color: $color-primary12,
        line-chart-h-axis-color: $color-primary12,
        line-chart-lable-color: $color-secondary6,
        line-chart-help-color: $color-secondary3,
        // donut chart
        donut-chart-area1: #fb6868,
        donut-chart-area2: #51c8b1,
        donut-chart-area3: #62b1fe,
        donut-chart-area4: #51a3c8,
        donut-chart-area5: #9951c8,
        donut-chart-area6: #f5925f,
        donut-chart-area7: #5169c8,
        donut-chart-area8: #f3d388,
        donut-chart-area9: #5cc1e1,
        donut-chart-area10: #256bc7,
        donut-chart-area11: #999cff,
        donut-chart-area12: #6b358e,
        donut-chart-area13: #70cb7f,
        donut-chart-area14: #c0c851,
        donut-chart-area15: #be724c,
        donut-chart-area16: #bf5656,
        donut-chart-area17: #df4848,
        donut-chart-area18: #b7b7b7,
        // chart public
        chart-lable0-color: #ffffff,
        chart-lable1-color: #ffffff,
        chart-lable2-color: #d1d1d1,
        chart-lable3-color: #333333,
        chart-lable4-color: #1d8cf8,
        chart-bg-color: #070d17,
        // message box container
        message-box-container-bg-color: #2f3447,
        message-box-container-color: white,
        //search-box-planning
        search-box-planning-bg-color: $color-primary11,
        search-box-planning-input-bg-color: $color-light,
        search-box-planning-input-border-color: $color-primary6,
        search-box-planning-text-color: $color-dark,
        search-box-planning-select-border-right: $color-primary6,
        search-box-planning-select-bg-color: $color-light,
        search-box-planning-select-value-color: $color-dark,
        search-box-planning-select-arrow-color: $color-light,
        search-box-planning-search-btn-bg-color: $color-primary6,
        search-box-planning-search-btn-color: $color-light,
        search-box-planning-close-btn-bg-color: $color-primary11,
        search-box-planning-close-btn-color: $color-secondary6,
        //schedule-planninf
        schedule-down-btn-bg-color: $color-dark,
        schedule-down-btn-color: $color-light,
        schedule-down-btn-border-color: $color-light,
        schedule-down-btn-hover-bg-color: $color-dark,
        schedule-i-reload-color: $color-light,
        schedule-i-reload-border-color: $color-light,
        schedule-popover-border-color: $color-primary18,
        schedule-popover-inner-bg-color: $color-primary18,
        schedule-popover-arrow-border-color: $color-primary12,
        schedule-radio-color: $color-light,
        schedule-radio-border-bottom-color: $color-secondary4,
        schedule-radio-wrapper-hover-color: $color-light,
        schedule-radio-wrapper-hover-bg-color: $color-primary12,
        schedule-down-btn-minute-bg-color: $color-light,
        // chart public
        chartLablelColor1: #ffffff,
        chartLablelColor2: #d1d1d1,
        chartLablelColor3: #000,
        chartLablelColor4: #1d8cf8,
        chartBgColor: $chart-bg-color,
        // line chart
        lineChartLableColor: $line-chart-lable-color,
        lineChartVAxisColor: $line-chart-v-axis-color,
        lineChartHAxisColor: $line-chart-h-axis-color,
        lineChartHelpColor: $line-chart-help-color,
        // donut chart
        donutChartArea1: $donut-chart-area1,
        donutChartArea2: $donut-chart-area2,
        donutChartArea3: $donut-chart-area3,
        donutChartArea4: $donut-chart-area4,
        donutChartArea5: $donut-chart-area5,
        donutChartArea6: $donut-chart-area6,
        donutChartArea7: $donut-chart-area7,
        donutChartArea8: $donut-chart-area8,
        donutChartArea9: $donut-chart-area9,
        donutChartArea10: $donut-chart-area10,
        donutChartArea11: $donut-chart-area11,
        donutChartArea12: $donut-chart-area12,
        donutChartArea13: $donut-chart-area13,
        donutChartArea14: $donut-chart-area14,
        donutChartArea15: $donut-chart-area15,
        donutChartArea16: $donut-chart-area16,
        donutChartArea17: $donut-chart-area17,
        donutChartArea18: $donut-chart-area18,
        // gauge chart OR [dashboard chart]
        gaugeLineColor: $color-primary6,
        gaugeNonSpace: $color-primary12,
        disabled-color: $color-secondary6,
        button-orange-filled: $color-warning8,
        button-orange-border: $color-warning7,
        button-orange-gradient-start: $color-warning8,
        button-orange-gradient-end: $color-warning7,
        confirm-box-prompt: $color-primary12,
        box-classification: $color-secondary7,
        disabled-button-bg-color: $color-secondary5,
        disabled-button-color: $color-secondary1,
        // planning-alert-box
        planning-alert-box-border-color: $color-secondary3,
        planning-alert-box-error-bg-color: $color-danger7,
        planning-alert-box-error-icon-color: $color-danger8,
        planning-alert-box-success-bg-color: #163733,
        planning-alert-box-close-icon-color: $color-light,
        planning-alert-box-collapse-content-color: $color-light,
        planning-alert-box-collapse-header-color: #d1d1d1,
        planning-alert-box-success-icon-color: $color-success15,
        planning-upload-media-delete-icon: $color-light,
        planning-upload-media-delete-icon-bg: $color-secondary5,
        planning-box-item-header-summary-status-icon-bg-color: #757575,
        planning-box-item-header-summary-status-icon-color: $color-light,
        planning-box-item-header-summary-status-label-color: #d1d1d1,
        planning-box-item-header-summary-status-bg-color: #2f3447,
        planning-box-item-header-desc-text-color: #ffffff,
        planning-box-generator-each-box-bg: #001529,
        planning-box-generator-each-box-color: $color-light,
        planning-box-generator-each-box-border-color: $color-primary6,
        planning-box-generator-each-box-deactive-bg: #070d17,
        planning-box-generator-each-box-deactive-color: #757575,
        planning-box-generator-each-box-deactive-border-color: #070d17,
        planning-box-generator-each-box-bg-hover: $color-primary13,
        planning-box-generator-each-box-hover: $color-primary6,
        planning-box-generator-each-box-icon: $color-secondary4,
        planning-box-item-avatar-border: $color-secondary4,
        planning-box-item-header-button-bg: $color-primary13,
        planning-box-time-ago: $color-secondary4,
        planning-box-item-header-button: $color-light,
        planning-box-item-header-button-assign-bg: #01160e,
        planning-box-item-header-button-selected-icon: $color-success17,
        planning-box-item-header-button-selected-other-assigned: #070d17,
        planning-box-item-header-button-tag-selected-icon: #0aa8c1,
        planning-box-item-header-button-selected-bg: $color-dark,
        planning-box-item-header-button-selected-title: #fff,
        planning-box-modal-select-box-bg: $color-dark,
        planning-box-item-header-assign-selected: $color-primary18,
        planning-box-item-selected: $color-primary11,
        planning-box-item-deleted: #070d17,
        planning-box-drop-items-deleted-border-color: #8c8c8c,
        planning-box-item-footer-icon-send: $color-primary6,
        planning-box-item-footer-icon-color: $color-light,
        planning-box-item-footer-icon-deactive: $color-secondary6,
        planning-instagram-item-bg: $color-primary13,
        planning-instagram-item-border: #2f3447,
        planning-instagram-item-body-img-border: $color-secondary4,
        planning-instagram-item-body-influencer-badge: $color-success13,
        planning-instagram-item-body-reply-to-user-name: $color-secondary9,
        planning-instagram-item-body-description: $color-light,
        planning-instagram-item-header-reply-to-text: $color-secondary10,
        planning-instagram-item-comment-text-color: $color-light,
        planning-box-item-header-button-icon: $color-light,
        planning-box-item-footer-icon: $color-light,
        planning-header-border-color: $color-primary14,
        planning-header-item-border-color: $color-secondary4,
        planning-box-header-background: $color-primary12,
        planning-box-header-color: $color-light,
        planning-box-header-type-information: $color-info2,
        planning-box-item-footer-icon-liked: $color-danger1,
        planning-box-item-footer-text: $color-secondary9,
        planning-box-item-footer-comment-background: $color-primary11,
        planning-box-load-more-footer-background-color: $color-primary11,
        planning-box-footer-inline-comments-background-color: $color-primary12,
        planning-box-item-footer-comment-border: $color-primary11,
        planning-tooltip-text: $color-dark,
        planning-tooltip-background: $color-light,
        planning-tooltip-border: $color-primary14,
        planning-modal-like-history-title-border: $color-secondary5,
        planning-modal-like-history-each-liker-line: $color-secondary3,
        planning-dropdown-icons: $color-secondary9,
        planning-box-item-body-bg-color: $color-primary13,
        planning-box-item-body-color: $color-light,
        planning-emoji-picker-bg-color: $color-primary12,
        planning-emoji-picker-border-color: $color-primary11,
        planning-modal-textarea-bg-color: $color-primary13,
        planning-modal-textarea-border-color: $color-primary13,
        planning-modal-textarea-tools-icon-color: $color-light,
        planning-modal-counter-color: $color-danger8,
        planning-modal-mention-name-color: #d1d1d1,
        planning-profile-actions-btn-color: $color-light,
        planning-profile-actions-btn-border-color: $color-primary14,
        planning-profile-user-name-color: $color-light,
        planning-generator-select-platform-selected: $color-light,
        planning-generator-account-select-selected: $color-dark,
        planning-header-little-account-list-menu-bg: $color-dark,
        planning-bg-color: $color-primary14,
        planning-filter-config-label-color: $color-light,
        planning-filter-config-top-nav-color: $color-light,
        planning-filter-config-ant-input-number-color: $color-dark,
        planning-filter-config-ant-input-icon-color: $color-light,
        planning-account-list-color: $color-light,
        //carousel
        carousel-icon-left-right: $color-secondary2,
        //query-rules
        custom-query-top-menu: $color-light,
        custom-query-parsed-query-color: $color-light,
        //notification
        notification-empty-message: $color-light,
        //emoji-picker
        emoji-picker-selected-emoji-border-bottom: $color-secondary5,
        planing-box-header-select-color: $color-light,
        planing-box-header-share-color: $color-light,
        planing-box-header-section-bg-color: #070d17,
        planing-box-header-select-account-color: $color-light,
        //planning DashboardModal
        planning-header-dashboard-icon-color: $color-light,
        planing-header-color: $color-light,
        planing-header-input-add-bg-color: #070d17,
        planing-header-bg-color: $color-dark,
        planing-header-border-color: $color-primary11,
        planning-header-item-active-background: $color-primary12,
        planning-header-item-active-border: $color-primary6,
        planning-header-item-hover-background: $color-primary11,
        planning-dashboard-modal-platform-bg-color: $color-primary16,
        planning-dashboard-modal-total-bg-color: $color-primary10,
        planning-dashboard-modal-assign-bg-color: $color-primary17,
        planning-dashboard-modal-not-assign-bg-color: $color-danger11,
        planning-dashboard-modal-close-bg-color: $color-success19,
        planning-dashboard-modal-body-color: $color-light,
        planning-dashboard-modal-close-icon-color: $color-light,
        planning-dashboard-modal-header-bg-color: $color-primary12,
        planning-dashboard-modal-header-logo-bg-color: $color-primary11,
        planning-dashboard-modal-cell-bg-color: $color-light,
        planning-dashboard-modal-cell-boder-color: $color-primary18,
        planning-dashboard-modal-pick-task-bg-color: $color-danger10,
        planning-dashboard-modal-row-bg-color: $color-primary20,
        ant-dropdown-active-bg-color: #ebf5ff,
        ant-select-selection-selected-value: $color-light,
        //Diff Text and Hashtag
        diff-header: $color-light,
        diff-header-shadow: $color-dark,
        diff-header-bg: $color-primary11,
        diff-header-each-item: $color-light,
        diff-header-each-item-bg: $color-primary12,
        diff-table-box-general-col: #eceff1,
        diff-table-box-general-col-bg: #191d29,
        diff-table-box-general-col-border: #2f3447,
        diff-table-box-1-col: white,
        diff-table-box-1-col-bg: #191d29,
        diff-table-box-1-col-border: #2f3447,
        diff-table-box-2-col: $donut-chart-area3,
        diff-table-box-2-col-bg: #182a3c,
        diff-table-box-2-col-border: #2f3447,
        diff-table-box-3-col: $donut-chart-area1,
        diff-table-box-3-col-bg: #271212,
        diff-table-box-3-col-border: #2f3447,
        diff-table-box-4-col: $donut-chart-area2,
        diff-table-box-4-col-bg: #102723,
        diff-table-box-4-col-border: #2f3447,
        diff-table-box-5-col: $donut-chart-area5,
        diff-table-box-5-col-bg: #1b0e23,
        diff-table-box-5-col-border: #2f3447,
        diff-table-box-1-contain-platform: $color-secondary4,
        diff-boxes-line-chart-select: $color-light,
        diff-boxes-line-chart-date: $color-light,
        sentiment-modal-bg-color: rgba(0, 0, 0, 0.75),
        sentiment-modal-color: $color-light,
        planning-input-filter-assignment: $color-light,
        ant-dropdown-menu-bg-color: #191d29,
        ant-dropdown-menu-color: white,
        ant-dropdown-menu-bg-color-hover: #2f3447,
        schedule-account-username-color: $color-light,
        schedule-header-border-color: #2f3447,
        schedule-header-bg-color: #2f3447,
        landing-grid-bg-color: #1d2333,
        landing-list-style-li-bg-color: #191d29,
        landing-list-style-a-color: $color-light,
        landing-list-style-a-hover-bg-color: #2f3447,
        landing-list-style-left-section-color: #9e9e9e,
        landing-list-style-right-section-color: $color-light,
        landing-grid-border-color: #1d2333,
        landing-content-child-tag-color: #ffffff,
        landing-content-parent-tag-color: #d1d1d1,
        box-item-description-color: #ffffff,
        resources-color: $color-light,
        resources-bg-color: $color-primary13,
        sources-wrapper-bg-color: $color-primary14,
        tag-selected-border-color: #2f3447,
        tag-selected-color: #eceff1,
        diff-filter-bg-color: #2f3447,
        diff-filter-border-color: #1d2333,
        diff-filter-label-color: $color-light,
        content-cluster-color: $color-light,
        content-cluster-border-top-color: #757575,
        fusioncharts-list-bg-color: #191d29,
        fusioncharts-list-category-color: #ffffff,
        fusioncharts-list-sub-category-color: #eceff1,
        reply-mention-popover-bg-color: #2f3447,
        reply-mention-name-color: $color-light,
        reply-mention-title-color: $color-light,
        box-rank-cluster-number-color: #d1d1d1,
        box-content-hot-news-select-bg-color: #191d29,
    ),
);

:export {
    @each $a, $v in $themes {
        @each $color, $value in $v {
            #{$a + '_' + unquote($color)}: $value;
        }
    }
}

@mixin themify() {
    transition: all 0.3s;
    transition-property: color, background;
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

// color
$red: #000000;
$pink: #e91e63;
$java: #1aacbe;
$blue: #4ec5ff;
$fountain-blue: #5dbac5;
$cyan: #00acc1;
$deep-cerulean: #0291a2;
$main-color: #1d8cf8;
$chambray: #3b5998;
$pink: #ff729b;
$purple: #ad0153;
$blue-purple: #8242ad;
$regent-gray: #78909c;
$regent-dark: #606060;
$ebony-clay: #2f3447;
$mine-shaft: #333333;
$outer-space: #263238;
$mirage-light: #1d2333;
$mirage: #191d29;
$ebony: #070d17;
$white: #ffffff;
$white-backcolor: #eceff1;
$gray-light: #ebebeb;
$alto: #d1d1d1;
$black: #000000;
$mirage-approx: #1f273e;
$gun-powder: #46455747;
$black-haze: #ebecec;
$silver: #cccccc;
$titan-white: #eeeeff;
$astral: #337ab7;
$gallery: #eeeeee;
$alabaster: #f9f9f9;
$backcolor-login: #000000a1;
$white-light: #ffffff33;
$amber: #ffbf00;
$torch-red: #f5222d;
$silver-approx: #bbb;
$brandy: #dcc896;
$champagne: #faf0d280;
$tropaz: #3276b1;
$tropaz-i: #285e8e;
$boston-blue: #428bca;
$boston-blue-i: #357ebd;
$dim-gray: #707070;
$sonic-silver: #757575;
$follow: #ff6c00;
$grayish-blue: #292e3f;
$darkgrayish-blue: #4f546a;
$red-delete: #b71c1c;
$box-shadow: #e4e5e6;
$spring-green: #00e676;

//font family
$font-family-default: IranSans;

//font size
$font-size-default: 12px;
$font-size-small: 10px;
$font-size-medium: 12px;
$font-size-larg: 14px;
$font-size-xlarg: 16px;
$font-size-xxlarg: 18px;
$font-size-xxxlarg: 20px;
$font-size-26larg: 26px;
$font-size-32larg: 32px;
$font-size-36larg: 36px;
$font-size-43larg: 43px;
$font-size-48larg: 48px;
$font-size-65larg: 65px;

//icon size
$icon-size-small: 12px;
$icon-size-medium: 20px;
$icon-size-larg: 24px;
$icon-size-xlarg: 32px;
$icon-size-xxxlarg: 70px;

// menu
$menu-font-size: $font-size-xxxlarg;

//links
$link-color: #00acc1;

//back colors
$sidebar-backcolor: #070d17;
$defaults-backcolor: #191d29;
$header-backcolor: #1d2333;
$logo-backcolor: #2f3447;
$defaults-searchbar: #78909c;

//Gradient cyan
$gradient-cyan-left-color: #1d8cf8;
$gradient-cyan-right-color: #3b5998;
$select-icon: #1aacbe;
$box-cyan-highlight: #5dbac5;

//disabled color
$disabled-color: #757575;
$button-orange-filled: #ff5722;
$button-orange-border: #ff7043;
$button-orange-gradient-start: #ff5722;
$button-orange-gradient-end: #ff7043;
$box-classification: #b5b5b5;
$disabled-button-color: #9e9e9e;

//alert boxes
$box-info-color: #7fb7ed;
$box-warning-color: #ff5722;
$color-cyan: #0291a2;

//notifications
$noti-bg: #1d2333;
$noti-dl-bg: #2f3447;
$noti-dl-prog: #1d8cf8;
$noti-dl-compl: #00e676;
$noti-dl-cancel: #ff5722;
$noti-dl-reload: #0086fa;

//scroll
$scroll-track: #d1d1d1;
$scroll-thumb: #757575;

//dark-modal
$sidebar-bg: #191d29;
$box-bg: #1d2333;
$disabled-btn: #757575;

//steps
$step-border: #d1d1d1;

//query builder
$input-border: #2f3447;

//page 404
$page404-font-size: $font-size-small * 5;
// login-background
$login-background: "./../../../assets/img/login-back.jpg";
// screen breakpoints value
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;
