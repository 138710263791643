@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';
.Toastify .Toastify__toast-container {
  .Toastify__toast--info {
    margin-left: 14px;
    @include themify() {
      background-color: themed("counter-bg-color") !important;
    }
    .Toastify__toast-body {
      text-align: center !important;
    }
    .counter {
      text-align: center;
      @include themify() {
        color: themed("counter-color");
      }
      font-size: 20px;
    }
    .Toastify__close-button--info {
      @include themify() {
        color: themed("counter-icon-color");
      }
    }
    .text {
      @include themify() {
        color: themed("counter-text-color");
      }
      margin: 6px;
    }
  }
}
.Toastify {
  .Toastify__toast.Toastify__toast--info.Toastify__toast--rtl {
    @include themify() {
      background: themed("counter-bg-color") !important;
    }
  }
}
