.sidebar-menu {
    &.open-side {
        width: 320px;
        max-width: 320px;
        height: calc(100vh - 60px);
        position: fixed;
        z-index: 1000;
        padding: 0;
        top: 60px;
        right: 0px;
        overflow-x: hidden;
        transition: max-width 0.5s;
        background: #1D2333 !important;

        ul:nth-child(1) {
            background: #1D2333 !important;
        }

        .ant-menu-sub .ant-menu-item a:after {
            display: none;
        }

        .ant-menu-item-selected {
            background-color: unset !important;

        }

        a {
            text-decoration: none;
            width: calc(100% - 42px);
            position: relative;

        }


        li {
            border-bottom: 1px solid black;
            margin: 0;
            min-height: 60px;
        }

        .ant-menu-submenu-title {
            margin: 0 !important;
            height: 60px !important;
            line-height: 60px !important;

            i {
                height: 100%;
            }

            &:after {

                display: none;
            }

            a {
                position: relative;
                top: 0;
                color: white;
                width: 100%;
                height: 100%;
                line-height: 50px;

                span {
                    border: 0 !important;
                    color: white;
                }



            }

            a.active {
                width: 100%;
                color: white !important;

                &:after {
                    background: #1d8cf8;
                    width: 5px;
                }

            }

            a:hover:after {
                background: #1d8cf8;
                width: 5px;
            }

        }

        .ant-menu-submenu-arrow {
            right: unset !important;
            left: 0px !important;
            top: 0px !important;
            padding: 30px 25px 30px 30px;
            // border-right: 1.5px solid black;

            // &:after {
            //     width: 10px;
            // }

            // &:before {
            //     width: 10px;
            // }

        }

        .ant-menu-item {
            display: flex;
            margin: 0 !important;
            min-height: 60px !important;

            a {
                line-height: 50px;
                color: white !important;
            }

            a.active {
                // background-color:unset !important;
                width: 100%;

                &:after {
                    background: #1d8cf8;
                    width: 5px;
                }

            }

            a:hover:after {
                background: #1d8cf8;
                width: 5px;
            }

            &>a {
                width: 100%;


            }

        }

        .ant-menu-submenu-inline>.ant-menu-submenu-title {
            text-align: right;
            padding-right: initial;
        }

        i {
            padding: 0 28px;
            margin: unset !important;
            position: relative;
            top: 1px;
        }


        li a.active:after,
        li a:hover:after {

            right: 0 !important;
            left: unset !important;
            ;

        }

        .ant-menu-sub {
            li {
                border-top: 1px solid #2f3447ba;

                .ant-menu-submenu-arrow {
                    // border-right: 1.5px solid #2f3447ba !important;
                }
            }
        }

        .ant-menu-submenu .ant-menu-sub {
            border: none !important;

            li a {
                background-color: unset !important;
            }

            .ant-menu-item:hover {
                border-right: 3px solid #1d8cf8;
            }
        }

    }

    &.closes-sidebar {
        height: calc(100vh - 60px);
        max-width: 110px;
        // transition:  0.5s;
        background: #1D2333 !important;

        .ant-menu-item {
            min-height: 60px !important;

            a {
                line-height: 35px;
                min-height: 60px !important;
                color: white;
            }
        }

        li {
            min-height: 60px !important;
            margin: 0 !important;

        }

        li a.active:after,
        li a:hover:after {

            right: 0 !important;
            left: unset !important;
            background: #1d8cf8;
            width: 5px;

        }

        ul {
            background: #1D2333 !important;

            li {
                div {

                    &:after {
                        display: none !important;
                    }
                }

            }
        }

        .ant-menu-inline .ant-menu-item {
            padding-right: 0 !important;
            padding-left: unset !important;

            span {
                display: none !important;
            }
        }

        .ant-menu-inline .ant-menu-submenu-title {
            padding: 0 !important;
            margin: 0 !important;
            height: 60px !important;
            ;

            span {
                display: none !important;
                line-height: 25px;
            }
        }

        .ant-menu-submenu-title:after {
            display: none;
        }

        .ant-menu-submenu-title {
            padding-right: 20px !important;
            min-height: 60px !important;

            span {
                display: none;
            }

            a {
                color: white;
                min-height: 60px !important;
            }


        }

        .ant-menu-submenu-arrow {
            display: none;
        }

        .ant-layout-sider-children li a {
            padding-top: 5px;
        }
    }

    li {
        border-bottom: 1px solid black;
    }
    .icon-mini-box-list{
        font-size: 20px;
    }
    .icon-add-1{
        font-size: 20px;
    }
}