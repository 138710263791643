@import "./btn-variables";
@import "../../assets/css/base/mixins";
.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    color: $body-color;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    margin: 5px;
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $btn-font-size,
        $btn-line-height,
        $btn-border-radius-default,
        $btn-heghit-normal
    );
    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: 0;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        color: $body-color;
        text-decoration: none;
    }

    &:active,
    &.active {
        background-image: none;
        outline: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: progress;
        opacity: 0.65;
        box-shadow: none;
    }
}
//btn-size
.btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg,
        $btn-heghit-lg
    );
}
.btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-line-height-sm,
        $btn-border-radius-sm,
        $btn-heghit-sm
    );
}
//
// Block button
//
.btn-block {
    display: block !important;
    width: 100% !important;
    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}
// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
// Make a button look and behave like a link
.btn-link {
    font-weight: $btn-font-weight;
    color: $link-color;
    background-color: transparent;
    text-decoration: $link-decoration;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        border: none;
        box-shadow: none;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
        border: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}
// Make a button look and behave like a link
.radius {
    border-radius: $btn-border-radius;
}
.radius-left {
    border-radius: $btn-border-radius-left;
}
.radius-right {
    border-radius: $btn-border-radius-right;
}
//
// Alternate buttons
//
@each $theme, $map in $theme-colors {
    .theme-#{$theme} {
        @each $color, $value in $map {
            .btn-#{$color} {
                @include button-variant($value, $value);
            }
            .btn-outline-#{$color} {
                @include button-outline-variant($value);
            }
            .circle-outline-#{$color} {
                @include circle-outline-variant($value);
            }
        }
    }
}
//btn 2 child (icon and text for desplay)
.child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 96%;
    .child-icon {
        font-size: 20px;
    }
}
.link-color {
    @include themify() {
        color: themed("button-link-color");
    }
    &:hover {
        @include themify() {
            color: themed("button-link-color-hover");
        }
    }
}

.circle {
    border-radius: 50%;
    padding: 5px;
    font-weight: bold;
}
//class circle button
