$themes: (
    light: (
        ui-radio-label-color: #070d17,
        ui-radio-border-color: #d1d1d1,
    ),
    dark: (
        ui-radio-label-color: #ffffff,
        ui-radio-border-color: #757575,
    ),
);

@import "./../../assets/css/base/theme";

.ui-radio-group {
    &.horizontal {
        display: flex;
        .ui-radio {
            display: inline;
        }
    }
    &.vertical {
        display: grid;
        .ui-radio {
            height: 30px;
            display: block;
        }
    }
}
