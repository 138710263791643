$themes: (light: (ui-tree-select-selection-bg-color: transparent,
        ui-tree-select-selection-title-color: #070d17,
        ui-tree-select-list-bg-color: #ffffff,
        ui-tree-select-list-hover-color: #eceff1,
        ui-tree-select-list-item-color: #070d17,
        ui-tree-select-selected-item-bg-color:#e8e8e8,
        ui-tree-select-selected-item-color: #1d2333,
        ui-tree-select-data-empty:#1d2333,
    ),
    dark: (ui-tree-select-selection-bg-color: transparent,
        ui-tree-select-selection-title-color:#ffffff,
        ui-tree-select-list-bg-color: #070d17,
        ui-tree-select-list-hover-color: #1d2333,
        ui-tree-select-list-item-color: #ffffff,
        ui-tree-select-selected-item-bg-color:#1d2333,
        ui-tree-select-selected-item-color: #ffffff,
        ui-tree-select-data-empty:#ffffff,
    ),
);

@import "./../../assets/css/base/theme";
$xs: 25px;
$sm: 30px;
$md: 35px;
$lg: 40px;
$xl: 45px;
$xxl: 60px;

.ui-tree-select-size {
    &.xs {
        min-height: $xs;

        .ui-tree-select-selection {
            min-height: $xs;
            border-radius: $xs;

            .ui-tree-select-close {
                width: $xs;
            }

            .ui-tree-select-arrow {
                width: $xs;
            }

        }

        .marquee {
            margin-right: $xs/10 !important;
        }
    }

    &.sm {
        min-height: $sm;

        .ui-tree-select-selection {
            min-height: $sm;
            border-radius: $sm/2;

            .ui-tree-select-close {
                width: $sm;
            }

            .ui-tree-select-arrow {
                width: $sm;
            }

        }

        .marquee {
            margin-right: $sm/10 !important;
        }

    }

    &.md {
        min-height: $md;

        .ui-tree-select-selection {
            min-height: $md;
            border-radius: $md/2;

            .ui-tree-select-close {
                width: $md;
            }

            .ui-tree-select-arrow {
                width: $md;
            }

        }

        .marquee {
            margin-right: $md/10 !important;
        }

    }

    &.lg {
        min-height: $lg;

        .ui-tree-select-selection {
            min-height: $lg;
            border-radius: $lg/2;

            .ui-tree-select-close {
                width: $lg;
            }

            .ui-tree-select-arrow {
                width: $lg;
            }

        }

        .marquee {
            margin-right: $lg/10 !important;
        }
    }

    &.xl {
        min-height: $xl;

        .ui-tree-select-selection {
            min-height: $xl;
            border-radius: $xl/2;

            .ui-tree-select-close {
                width: $xl;
            }

            .ui-tree-select-arrow {
                width: $xl;
            }

        }

        .marquee {
            margin-right: $xl/10 !important;
        }
    }

    &.xxl {
        min-height: $xxl;

        .ui-tree-select-selection {
            min-height: $xxl;
            border-radius: $xxl/2;

            .ui-tree-select-close {
                width: $xxl;
            }

            .ui-tree-select-arrow {
                width: $xxl;
            }
        }

        .marquee {
            margin-right: $xxl/10 !important;
        }

    }
}

.ui-tree-select-theme {
    &.dark {
        .ui-tree-select-list {

            background-color: #070d17 !important;

            .ui-tree-select-list-item {

                color: #ffffff !important;


                &:hover {

                    background-color: #1d2333 !important;
                    color: #1d8cf8 !important;
                }

                &.selected-item {

                    background-color: #1d2333 !important;
                    color: #1d8cf8 !important;

                }
            }

            .ui-tree-select-data-empty {
                color: #ffffff !important;
            }
        }




        .ui-tree-select-selection {
            color: white !important;
            background-color: transparent !important;

            .ui-tree-select-selected {
                .ui-tree-select-selected-item {

                    // background-color: #1d2333 !important;
                    color: #ffffff !important;
                }
            }
        }
    }

    &.light {
        .ui-tree-select-list {

            background-color: #ffffff !important;

            .ui-tree-select-list-item {

                color: #070d17 !important;


                &:hover {

                    background-color: #eceff1 !important;

                }

                &.selected-item {

                    background-color: #eceff1 !important;
                    color: #1d8cf8 !important;

                }
            }

            .ui-tree-select-data-empty {
                color: #1d2333 !important;
            }
        }

        .ui-tree-select-selection {
            color: #070d17 !important;
            background-color: transparent !important;

            .ui-tree-select-selected {
                .ui-tree-select-selected-item {

                    // background-color: #e8e8e8 !important;
                    color: #1d2333 !important;
                    ;

                }
            }
        }
    }
}

.ui-tree-select-disabled {
    &.disabled {
        .ui-tree-select-selection {
            cursor: not-allowed;

            * {
                color: #9e9e9e !important;
            }

            border-color: #d1d1d1 !important;
        }
    }
}

.ui-tree-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    outline: 0;
    width: 100%;

    .ui-tree-select-selection {
        display: flex;
        cursor: pointer;
        width: 100%;
        border: 1px solid #1d8cf8;

        @include themify() {
            color: themed("ui-tree-select-selection-title-color");
            background-color: themed("ui-tree-select-selection-bg-color");
        }

        align-items: center;

        &.open {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .ui-tree-select-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .ui-tree-select-selected {
            margin: 2px 2px 2px 20px;
            padding: 0 2px 0 0;
            z-index: 1;

            display: flex;

            &.showMultiSelected {
                flex-wrap: wrap;
            }

            width: calc(100%);
            overflow: hidden;

            li {
                list-style: none;
                display: flex;
            }

            .ui-tree-select-selected-item {
                cursor: pointer;
                flex: 0 0 auto;
                justify-content: center;
                // padding: 0px 5px;
                color: black;

                border-radius: 16px;
                margin-left: 2px;

                @include themify() {
                    // background-color: themed("ui-tree-select-selected-item-bg-color");
                    color: themed("ui-tree-select-selected-item-color");
                }

                span {
                    padding: 5px 5px 5px 0px;
                    margin: auto;
                }

                i {
                    float: left;
                    margin: 2px;
                    color: #a0a1a2;
                    font-size: 12px;
                    padding: 5px 0px 5px 5px;
                }
            }

            .ui-tree-select-input {
                // flex: 1 1 auto;

                // margin-left: 30px;
                input {
                    border: none;
                    outline: 0;
                    background-color: transparent;
                    height: 100%;
                    width: 100%;

                    &::selection,
                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none !important;
                    }
                }
            }


        }

        .ui-tree-select-arrow {
            text-align: center;
            color: #1890ff;
            position: absolute;
            left: 0;
            text-align: center;
            display: flex;
            justify-content: space-around;
            z-index: 3;
        }

        .ui-tree-select-close {
            position: absolute;
            left: 0;
            text-align: center;
            display: flex;
            justify-content: space-around;
            z-index: 3;

            &:after {
                position: absolute;
                content: "";
                left: 0px;
                top: 3px;
                width: 14px;
                height: 14px;
                background: transparent;
                z-index: 3;
                border-radius: 50%;
            }
        }

        &:hover .ui-tree-select-close {
            display: block;
        }
    }

    .ui-tree-select-list {
        width: 100%;


        @include themify() {
            background-color: themed("ui-tree-select-list-bg-color");
        }



        position: relative;
        z-index: 4;
        right: 0;
        top:100%;
        list-style: none;
        margin: 0;
        padding: 0px 20px 0px 0px;
        border-radius: 0 0 5px 5px;
        cursor: pointer;

        &.first {
            position: absolute;
            border: 1px solid #1d8cf8;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            padding: 0 !important;
            max-height: 300px;
            overflow: hidden;
            overflow-y: auto;
        }


        .ui-tree-select-list-item {
            margin: 5px;
            display: flex;
            cursor: pointer;

            @include themify() {
                color: themed("ui-tree-select-list-item-color");
            }

            span {
                margin: auto 0;
            }

            .ui-tree-select-child-icons {
                display: flex;
                font-weight: bold;

                .ui-tree-select-arrow {
                    color: #1d8cf8;
                }
            }


            .ui-tree-select-list-item-title {
                width: 100%;
                padding: 5px;
                display: flex;
                justify-content: space-between;

                .ui-tree-select-list-item-img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-left: 10px;
                }

                .ui-tree-select-list-item-image-icon {
                    width: 15px;
                    height: 15px;
                    margin-left: 10px;
                }
            }


            &:hover {
                @include themify() {
                    background-color: themed("ui-tree-select-list-hover-color");
                }

                border-radius: 3px;
            }

            &.selected-item {
                @include themify() {
                    color: #1d8cf8;
                }

                display: flex;
                justify-content: space-between;
            }
        }

        .ui-tree-select-data-empty {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;

            @include themify() {
                color: themed("ui-tree-select-data-empty");
            }
        }

        .ui-tree-select-list-item-footer {
            .add {
                background-color: #c2dbf3;
                color: #1d8cf8;
                padding: 10;
                padding: 5px;
                text-align: center;
                border-radius: 0 0 5px 5px;
                display: block;
            }
        }
    }



    .ui-select-click-area {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1;
    }

    .marquee {
        position: unset !important;
        z-index: 1;
        display: flex;

        span {
            display: flex;
            flex: 1;

            &.animate {
                animation: marquee 5s linear infinite;
            }
        }
    }

    @extend .ui-tree-select-disabled;

    @extend .ui-tree-select-size;

    @extend .ui-tree-select-theme;
}