@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';
$box-bg: $header-backcolor;
$disabled-btn: $scroll-thumb;

.ui-modal-body {
    padding: 0;
}

.sources-wrapper {
    display: flex;
    color: $white;

    &.full {
        flex-direction: column;
        border: 2px solid #2F3447;
        border-radius: 9px;
    }

    .table-row {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-grow: 0;
        -webkit-flex-grow: 0;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        width: 100%;
        // padding-left: 15px;
        padding-right: 15px;

        .icon {
            font-size: 20px;
            float: left;
        }
    }

    .select-transparent.multi {
        div {
            div {
                div {
                    background-color: transparent !important;
                }
            }
        }

        svg {
            color: $white !important;
        }
    }

    // Text based cells: grow, truncate and show ellipsis when there is not enough space
    .text {
        flex-grow: 1;
        -webkit-flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 20px;

        .image {
            float: right;
        }
    }

    .text-result {
        flex-grow: 1;
        -webkit-flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        margin-left: 10px;
    }

    .header {
        color: $white;
        padding-left: 10px;
        padding-right: 38px;
        position: relative;
    }

    /*
   * General good-look styles, not mandatory.
   */
    body {
        font-size: 20px;
    }

    .table-row {
        align-items: center;
        border-bottom: 2px solid #e0e0e0;
        border-collapse: collapse;
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;

        &:hover {
            background-color: $color-secondary7;
        }
    }

    .table-row.header {
        align-items: center;
        @include borderRadius(8px);
        background-color: $main-color;
        font-weight: bold;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .rightPart {
        flex-basis: 200px;
        background: $sidebar-bg;
        padding: 15px 0;
        max-height: 700px;
        min-height: 500px;
        display: flex;
        flex-direction: column;

        header {
            padding: 5px 0 15px 0;
            margin: 0 15px;
            font-size: $font-size-larg;
            text-align: center;
            border-bottom: 1px solid $white;
        }

        .tag-form {
            margin: 0 15px;

            label,
            .ui-input {
                color: $white !important;
            }

            .ui-input-suffix {
                .icon {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border-radius: 50%;
                    line-height: 25px;
                    text-align: center;
                    font-size: 18px;
                    color: #1d8cf8;
                }

                .color-picker {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .rescources {
            flex: 1;
            margin: 20px 0;

            label {
                padding: 15px;
            }

            .item {
                margin: 5px 0;
                padding: 10px 15px 5px 10px;
                cursor: pointer;
                display: flex;
                @include transition(all 0.3s ease);

                & i {
                    font-size: 25px;
                    vertical-align: top;
                    line-height: 2rem;
                }

                & span:nth-child(2) {
                    flex: 1;
                    padding: 0 5px;
                }

                & span:nth-child(3) {
                    color: $main-color;
                }

                &:hover,
                &.active,
                &.selected {
                    border-left: 3px solid $main-color;
                    background-color: $color-primary11;

                    & span:nth-child(3) {
                        color: $white;
                    }
                }
            }
        }

        .buttons {
            padding: 0 20px 0px 0px;
        }
    }

    .topPart {
        display: flex;

        .rescources {
            display: flex;
            overflow-x: scroll;
            width: 100%;
            background-color: #2F3447;
            border-radius: 7px 7px 0 0;

            .item {
                margin: 1px 0;
                padding: 5px;
                font-size: 12px;
                min-width: 120px;
                // border-left: 2px solid #191D29;
                cursor: pointer;
                display: flex;
                justify-content: space-evenly;
                @include transition(all 0.3s ease);
                // &:after{
                //     left: 0;
                //     position: relative;
                //     width: 2px;
                //     top: 5px;
                //     height: 25px;
                //     right: 10px;
                //     background-color: #191D29;
                //     content: "";

                // }
                & i {
                    font-size: 25px;
                    vertical-align: top;
                    line-height: 2rem;
                }

                & span:nth-child(2) {
                    //   flex: 1;
                    padding: 0 5px;
                }

                & span:nth-child(3) {
                    color: $main-color;
                }

                &:hover,
                &.active,
                &.selected {
                    border-bottom: 2px solid $main-color;

                    & span:nth-child(3) {
                        color: $white;
                    }
                }
            }
        }

    }

    .leftPart {
        flex: 1;
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        @include borderRadius(4px);

        @include themify() {
            color: themed("resources-color");
        }

        label {
            @include themify() {
                color: themed("resources-color");
            }
        }

        @include themify() {
            background-color: themed("sources-wrapper-bg-color");
        }

        .source-box-wrapper {
            flex: 1;
            margin: 0 10px;
            display: flex;
            flex-direction: column;

            header {
                padding-bottom: 15px;
            }

            .resource-search {
                align-items: center;

                form {
                    width: 100%;
                }

                .ui-input-affix-wrapper {
                    input {
                        border-radius: 0 15px 15px 0 !important;
                    }

                    .ui-input-prefix {
                        .icon {
                            color: $main-color;
                            font-size: 18px;
                        }
                    }
                }
            }

            .source-box {
                flex: 1;
                //overflow: auto;
                flex-basis: 550px;
                min-height: 580px;

                @include themify() {
                    background-color: themed("resources-bg-color");
                }

                padding: 10px;
                @include borderRadius(10px);

                .advanced-search {
                    .row-label {
                        @include themify() {
                            color: themed("resources-color");
                        }
                    }

                    .ant-collapse {
                        //margin-right: 15px;
                        background: $white-backcolor !important;
                        font-size: 13px;

                        .ant-collapse-content.ant-collapse-content-active {
                            overflow: visible;
                        }

                        .ui-input-affix-wrapper {
                            z-index: 0;
                        }
                    }

                    .ant-collapse .ant-collapse-header {
                        background: $white-backcolor !important;
                        margin: 0px !important;
                        color: $main-color !important;
                        margin-top: -4px !important;
                        width: fit-content;
                    }

                    .ant-collapse-header .anticon {
                        color: $defaults-backcolor !important;
                    }

                    .ant-collapse-content-box {
                        min-height: auto;
                        background: $white-backcolor !important;
                        padding: 0 0.5rem 0.7rem !important;

                        .ant-collapse-header {
                            color: $ebony-clay !important;
                            width: fit-content;
                        }

                        .ant-row,
                        .ant-row-flex {
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;
                            // input {
                            //   min-height: 0px !important;
                            //   height: 25px;
                            //   background-color: $white-backcolor;
                            // }

                            .select-transparent,
                            .multi {

                                //
                                &>div:first-of-type {
                                    // min-height: 25px;
                                    // height: 25px;
                                    // max-height: fit-content;
                                    background-color: $white-backcolor;
                                    //     display: flex;
                                    //     align-items: center;
                                    //       & > div:first-of-type {
                                    //         flex: 4;
                                    //         height: 100%;
                                    //         position: sticky;
                                    //         & > div:first-of-type {
                                    //           position: sticky;
                                    //         }
                                    //       }
                                    //       & > div:nth-of-type(2) {
                                    //         flex: 1;
                                    //         height: 100%;
                                    //         position: sticky;
                                    //       }
                                }

                                &>div:nth-of-type(2) {
                                    background-color: $white-backcolor !important;
                                    //     >div{
                                    //       max-height: 150px;
                                    //       >div{
                                    //       background-color: $white-backcolor !important;
                                    //       }
                                    //     }
                                }

                                //   & > span:first-of-type {
                                //     display: none;
                                // }
                            }
                        }

                        .ant-col-lg-9,
                        .ant-col-xl-6 {
                            width: 33.33333333% !important;
                            color: $mirage;
                        }

                        .ant-col-lg-6,
                        .ant-col-xl-4,
                        .ant-col-lg-5,
                        .ant-col-xl-3 {
                            width: 20.83333333% !important;
                            color: $mirage;

                            .row-label {
                                margin-right: 10px;
                            }

                            >input {
                                color: $sonic-silver !important;
                            }

                            >input[disabled] {
                                color: $color-secondary5 !important;
                            }
                        }

                        .ant-col-lg-15,
                        .ant-col-xl-17 {
                            //width: 50% !important;
                            color: $mirage;
                        }

                        .ant-col-lg-18.ant-col-xl-21 {
                            margin-right: 10px;
                        }
                    }

                    // .select-transparent {
                    //   @extend .select-sm;
                    // }
                }

                .search-type {
                    display: flex;
                    margin: 0px 5px 10px 0px;

                    .search-type-title {
                        margin-left: 25px;
                    }
                }
            }
        }

        .orange-border {
            color: $disabled-button-color;
        }

        .fullySelectedCategory {
            display: flex;
            align-items: baseline;
            border: 1px solid lighten($disabled-button-color, 20%);
            padding: 3px 10px 3px 3px;
            margin-bottom: 5px;
            @include borderRadius(5px);

            span {
                flex: 1;
            }

            button {
                margin: 5px;

                i {
                    vertical-align: sub;
                    float: right;
                    margin: 6px 2px 0 0px;
                }
            }
        }

        .search {
            margin-left: 3px;
            width: 100%;

            .ui-input-suffix {
                padding-top: 5px;

                .icon {
                    font-size: 20px;
                }
            }

            .ui-input {
                margin-left: 3px;
                @include borderRadius(5px);

                @include themify() {
                    color: themed("resources-color");
                }

                padding-right: 5px;

                &:nth-child(1) {
                    @include borderRadius(0, 20px, 20px, 0);
                    flex: 0.85;
                }

                &::placeholder {
                    color: $regent-gray;

                    font-size: $font-size-medium;
                    padding-right: 5px;
                }
            }

            button {
                @include borderRadius(0);
                font-size: 1.3rem;
                flex: 0.15;
                min-width: 15%;

                &:nth-of-type(2),
                &:last-child {
                    @include borderRadius(20px, 0, 0, 20px);
                    border-right: 1px solid $white-backcolor;
                    font-size: $font-size-medium;
                }

                .ant-spin {
                    padding-top: 4px;
                }
            }

            &.selected-search {
                margin-bottom: 20px;

                .ui-input-prefix {
                    .icon {
                        color: $main-color;
                        font-size: 18px;
                    }
                }
            }
        }

        .advanced {
            margin-left: 3px;

            &.visible {
                display: block;
            }

            &.hidden {
                display: none;
            }
        }
    }

    .with-icon {
        position: relative;

        i {
            position: absolute;
            right: 0;
            top: 0;
            background: $red;
            bottom: 0;
            width: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .un-category-resource {
        padding: 20px 0;

        .search-result-count {
            margin-bottom: 10px;
        }

        .resource-result-table {
            .resource-result-item {
                &.active-item {
                    position: relative;
                    border: 2px solid #00e676;
                    //   background-color: #eefced;
                    border-radius: 9px;
                    margin: 2px 0;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 19px;
                        width: 19px;
                        border-radius: 10px;
                        text-align: center;
                        font-family: "fontello";
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        font-size: 21px;
                        line-height: 23px;
                        color: $boston-blue;
                        background: #fff;
                        margin: 0;
                        padding: 0;
                    }
                }

                .icon {
                    &.icon-male-1 {
                        font-size: 30px;
                        padding-top: 5px;

                        @include themify() {
                            color: themed("box-content-gender-male-color");
                        }
                    }

                    &.icon-female-1 {
                        font-size: 30px;
                        padding-top: 5px;

                        @include themify() {
                            color: themed("box-content-gender-female-color");
                        }
                    }

                    &.icon-unknown-1 {
                        font-size: 30px;
                        padding-top: 5px;

                        @include themify() {
                            color: themed("box-content-gender-unknown-color");
                        }
                    }
                }
            }
        }
    }

    .source-select-pagination {
        text-align: center;
        margin: 5px 0 0 0;

        .pagination {
            margin: 0 !important;
        }
    }

    .search-loading-container {
        text-align: center;
        margin: 100px;
    }

    .pagination {
        li {
            a {
                color: $color-secondary6 !important;
            }

            &.active {
                a {
                    color: $deep-cerulean !important;
                }
            }
        }

        .next,
        .previous {
            &::before {
                color: $white;
            }
        }
    }
}

.modal-shadow {
    &.larg-modal {
        width: 95% !important;
        max-width: 1300px;
    }
}

.validate-source {
    color: $torch-red;
}