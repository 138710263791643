$themes: (light: (ui-checkbox-color: #070d17,
    ),
    dark: (ui-checkbox-color: #ffffff,
    ),
);

@import "./../../assets/css/base/theme";

.ui-checkbox {
    @include themify() {
        color: themed("ui-checkbox-color");
    }

    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: initial;



    &.disabled {
        * {
            cursor: not-allowed;
            color: #9e9e9e;
        }
    }

    /* Hide the browser's default checkbox */
    .ui-checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .ui-checkbox-checked {
        position: absolute;
        top: 0;
        right: 0;
        height: 15px;
        width: 15px;

        border: 1px solid #d1d1d1;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
    }




    /* When the checkbox is checked, add a blue background */
    .ui-checkbox-input:checked~.ui-checkbox-checked {
        background-color: #2196F3;
        border: 0;
    }

    /* Create the ui-checkbox-checked/indicator (hidden when not checked) */
    .ui-checkbox-checked:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the ui-checkbox-checked when checked */
    .ui-checkbox-input:checked~.ui-checkbox-checked:after {
        display: block;
    }

    /* Style the ui-checkbox-checked/indicator */
    .ui-checkbox-checked:after {
        content: "\e8b9";
        font-family: "fontello";
        top: 49%;
        transform: translateY(-50%);
        color: white;
    }






    &.indeterminate {
        .ui-checkbox-checked {
            background-color: transparent !important;
            border: 1px solid #d1d1d1 !important;

            &:before {
                width: 7px;
                height: 7px;
                background-color: #0075ff;
                content: " ";
                right: 3px;
                top: 3px;
                position: absolute;
            }

            &:after {
                display: none !important;
            }
        }

    }

    .ui-checkbox-label {
        padding-right: 20px;
    }

    &.light {
        .ui-checkbox-label {
            color: #070d17 !important;
        }

    }

    &.dark {
        .ui-checkbox-label {
            color: #ffffff !important;
        }


    }

}