.notif-info {
    display: flex;
    align-items: center;
    .text {
        margin-bottom: 10px;
    }
}
.notif-status {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &.done {
        color: #00e676;
    }
    &.failed {
        color: #ff3030;
    }
    .icon {
        font-size: 20px !important;
        &.icon-warning {
            color: #ff3030 !important;
            margin-right: 5px;
            margin-top: 7px;
        }
    }
    .icon-tick-circle {
        color: #00e676 !important;
        margin-right: 5px;
    }
}
