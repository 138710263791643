$themes: (
    light: (
        ui-button-icon-color: gray,
    ),
    dark: (
        ui-button-icon-color: white,
    ),
);

@import "./buttons";
@import "./../../assets/css/base/theme";
@import "./../../assets/css/base/utils";

.colors {
    &.default {
        border-color: #3b5998 !important;
        .icon {
            color: #3b5998;
        }
    }
    &.default-outline {
        border-color: #3b5998 !important;
        background-color: transparent !important;
        .icon {
            color: #3b5998;
        }
    }
    &.primary {
        background-color: #1d8cf8;
        border-color: #1d8cf8 !important;
        .icon {
            color: white;
        }
    }
    &.primary-outline {
        background-color: transparent !important;
        border-color: #1d8cf8 !important;
        .icon {
            color: #1d8cf8 !important;
        }
    }
    &.secondary {
        background-color: #9e9e9e;
        border-color: #9e9e9e !important;
        .icon {
            color: white;
        }
    }
    &.secondary-outline {
        background-color: transparent !important;
        border-color: #9e9e9e !important;
        .icon {
            color: #9e9e9e !important;
        }
    }
    &.success {
        background-color: #00e676;
        border-color: #00e676 !important;
        .icon {
            color: white;
        }
    }
    &.success-outline {
        background-color: transparent !important;
        border-color: #00e676 !important;
        .icon {
            color: #00e676 !important;
        }
    }
    &.danger {
        background-color: #b71c1c;
        border-color: #b71c1c !important;
        .icon {
            color: white;
        }
    }
    &.danger-outline {
        background-color: transparent !important;
        border-color: #b71c1c !important;
        .icon {
            color: #b71c1c;
        }
    }
    &.warning {
    }
    &.info {
        background-color: #1d8cf8;
        border-color: #1d8cf8 !important;
        .icon {
            color: white;
        }
    }
    &.info-outline {
        background-color: transparent !important;
        border-color: #1d8cf8 !important;
        .icon {
            color: #1d8cf8;
        }
    }
    &.light {
        background-color: white;
        border-color: white !important;
        .icon {
            color: #2f3447;
        }
    }
    &.light-outline {
        background-color: transparent !important;
        border-color: white !important;
        .icon {
            color: white;
        }
    }
    &.dark {
        background-color: #2f3447;
        border-color: #2f3447 !important;
        .icon {
            color: white;
        }
    }
    &.dark-outline {
        background-color: transparent !important;
        border-color: #2f3447 !important;
        .icon {
            color: #2f3447;
        }
    }
}

.ui-button {
    width: auto;
    display: inline-flex !important;
    padding: 5px 10px !important;
    justify-content: space-between;
    align-items: center;

    &.disabled {
        cursor: not-allowed;
    }
    &.remove-border {
        border: none;
    }

    @extend .ui-loading;
    @extend .height;
    @extend .font-size;

    &.ui-button-prefix {
        .ui-button-text {
            text-align: right;
            margin-right: 5px;
        }
    }
    .ui-button-text {
        flex: 1;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.ui-button-sufix {
        .ui-button-text {
            text-align: right;
            margin-right: 5px;
        }
    }
    &.ui-button-prefix,
    &.ui-button-sufix {
        .icon {
            position: relative;
            top: 2px;
        }
    }
}

.ui-button-icon {
    text-align: center;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;

    @extend .ui-button-icon-size;

    &.disabled {
        cursor: not-allowed;
    }
    @include themify() {
        color: themed("ui-button-icon-color");
    }

    @extend .ui-loading;
    &.has-boeder {
        @include themify() {
            border: 1px solid themed("ui-button-icon-color");
        }
        border-radius: 50%;
    }
    .icon {
        display: flex;
    }
    @extend .colors;

    &.active {
        background-color: #1d8cf8;
        border-color: #1d8cf8;
        color: white;
        &:hover {
            background-color: #1d8cf8 !important;
        }
    }

    &.dark {
        &.has-boeder {
            border: 1px solid gray !important;
        }
        color: gray;
    }
    &.light {
        &.has-boeder {
            border: 1px solid white !important;
        }
        color: white;
    }
}

// چون روی هاور دکمه هایی که دارای تولتیپ هستند رنگ متن جایگزین میشد، فعلا به صورت موفقت این کد نوشته شده
// .btn-primary {
//     &:hover {
//         color: unset !important;
//     }
// }
