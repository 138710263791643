@import "./../../assets/css/base/variables";
@import "./../../assets/css/base/mixins";
.global-filter-calendar {
    background: $white !important;
    width: 100% !important;
    margin: 10px 0;
    padding: 10px 15px !important;
    border-radius: 20px !important;
    color: $black;
}

.general-settings--sidebar {
    .border-b {
        header {
            border-bottom: 1px solid $sonic-silver;
            padding: 25px 0;
            margin: 0 10px;
        }
    }
    .filter-container {
        margin: 0 10px;
        .exists {
            .ant-select-selection__choice__content {
                color: rgb(241, 241, 241);
            }
            .ant-select-selection__choice {
                padding: 0 10px 0 20px !important;
                svg {
                    display: block !important;
                }
            }
            .ant-select-search__field {
                box-shadow: none !important;
                color: rgb(241, 241, 241);
            }
            margin: 20px 0 0 0;
            .ant-select {
                width: 100%;
            }
        }
        .relation-ages {
            input[type="number"] {
                color: white;
            }
        }
        .ant-btn-round {
            border-radius: 30px;
            margin: 0 25px 0 0;
            a {
                color: $white;
            }
            svg {
                display: none;
            }
        }
        .section-title {
            margin: 20px 10px 20px 10px;
            color: $main-color;
            font-size: $font-size-xlarg;
        }

        .border-b {
            border-bottom: 1px solid $sonic-silver;
            padding: 25px 0;

            .section-title {
                margin: 0 10px 20px 10px;
                //color: $main-color;
                font-size: $font-size-xlarg;
            }

            .ant-select-selection-selected-value {
                @include themify() {
                    color: themed("select-color-public-settings") !important;
                }
            }
            .ant-select-selection__placeholder {
                @include themify() {
                    color: themed("select-color-public-settings") !important;
                }
            }

            .global-category {
                .ant-select {
                    width: 100%;
                    .ant-select-selection-selected-value {
                        @include themify() {
                            color: themed("select-color-public-settings") !important;
                        }
                    }
                }
            }

            .global-reource {
                //margin: 20px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .section-title {
                    flex: 1;
                    margin: 0;
                    margin-right: 10px;
                }
            }
            .ant-select {
                .ant-select-selection__clear {
                    background: transparent !important;
                    color: $sonic-silver;
                    opacity: 1;
                    &:hover {
                        color: $main-color;
                    }
                    i {
                        display: none !important;
                    }
                }
            }
        }
        .date-time-range-picker-container {
            display: flex;
        }

        .custom-input-datepicker {
            &::before {
                color: white;
            }
            &.global-filter-calendar {
                // background-image: url('/images/calendar.png') !important;
                // background-repeat: no-repeat !important;
                // background-size: contain !important;
                display: flex;
                align-items: center;
                padding: 0px 15px 0px 0px !important;
                .JDinput {
                    flex: 0.7;
                    font-size: 12px;
                    color: $black;
                    margin-right: -6px;
                }
                .left-side {
                    background: $main-color;
                    flex: 0.3;
                    height: 100%;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                &.disabled {
                    > div {
                        background: $color-secondary7;
                    }
                }
                #jalali-date-picker-container-id-datePicker {
                    right: unset;
                    left: -7px;
                }
            }
        }

        .date-range {
            padding: 0 25px;
            margin: 0;
            list-style: none;

            li {
                padding: 10px 0;
                width: 50%;
                display: inline-block;
                cursor: pointer;
                color: $white;

                &:hover,
                &.selected-range {
                    color: $main-color !important;
                }
                &.disabled {
                    color: rgba(255, 255, 255, 0.25);
                }
            }
        }

        .apply-check {
            margin: 20px;
            span {
                @include themify() {
                    color: themed("global-filter-navbar-color");
                }
            }
            .green-spot {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: $spring-green;
                display: inline-block;
            }
        }

        .new-categori-btn {
            @include borderRadius(20px);
            margin: 0 10px;

            a {
                color: $white;
            }
        }

        .custom-query-filed-container {
            .ant-select {
                width: 100%;
            }
        }

        .ant-radio-disabled + span {
            color: rgba(255, 255, 255, 0.55);
        }

        .custom-input-datepicker input.JDinput {
            color: #000000;
        }
    }
}

.apply-btn {
    margin: 15px auto !important;
    display: block;
}
.content-cluster-global-filter {
    .ant-collapse-borderless {
        background-color: transparent;
        .ant-collapse-item {
            .ant-collapse-header {
                color: white;
            }
        }
    }
}
.custom-date-picker-form-item .border-b .custom-input-datepicker input.JDinput {
    color: $black !important;
}

.apply-modal {
    .ui-modal-content,
    .ui-modal-header {
        background: $color-primary12;
        border-bottom: none;
        border-radius: 20px;
    }
    .ui-modal-confirm-title {
        color: $white;
    }
    .ui-modal-footer {
        border-top: none;
        text-align: center;
    }

    .ui-modal-confirm-btns {
        text-align: center;
        float: unset;
        .ant-btn-round {
            border-radius: 30px;
            border-color: $color-secondary5;
            background: transparent !important;
            color: $white;
            margin: 0 10px;
            padding: 3px 40px;

            &.ant-btn-primary {
                background: $color-warning8 !important;
                border-color: $color-warning8 !important;
            }
        }
    }
}
.global-filter-header {
    // direction: rtl;
    display: flex;
    align-items: center;
    .global-filter-slider {
        .JDinput {
            color: white !important;
        }
        //width: calc(100% - 150px);
        // direction: ltr;
        display: flex;
        flex: 0.9;
        // overflow: hidden;
        overflow-y: visible;
        .globalKeywords {
            display: flex;
            color: #b5b5b5;
            margin: 0 15px;
            align-items: center;
            .ant-select-selection__placeholder,
            .ant-select-selection-selected-value {
                @include themify() {
                    color: themed("global-filter-navbar-color");
                }
            }
            .custom-query-filed-container {
                margin-right: 10px;
                align-items: center;
                display: flex;
                .gutter-row {
                    display: flex;
                    .ant-select-selection--single {
                        background-color: transparent !important;
                        min-width: 150px;
                        width: 170px;
                        height: 30px;
                        .ant-select-selection__rendered {
                            margin-right: 15px;
                            margin-left: 28px;
                            line-height: 28px;
                            .ant-select-selection__placeholder {
                                padding-left: 12px;
                            }
                        }
                        .ant-select-selection__clear {
                            opacity: 1;
                            background-color: transparent;
                            margin-right: -4px;
                            i {
                                display: none;
                            }
                            &::before {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .applied-filters {
            flex: 1;
            display: flex;
            align-items: center;
            .inner-content {
                flex: 1;
            }
        }
        .slick-next {
            right: 25px;
        }
    }
    .global-filter-item {
        // direction: rtl;
        background-color: $ebony-clay;
        display: inline !important;
        margin: 0 10px;
        padding: 4px 10px;
        border-radius: 20px;

        .tag {
            padding: 5px 10px;
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $main-color;
            }
        }
        .remove {
            cursor: pointer;
            color: $white;
            display: inline-flex;

            &:hover {
                color: $white;
            }
        }
    }
    .left-top-icons {
        flex: 0.1;
        display: flex;
        flex-direction: row-reverse;
        max-height: 50px;
        align-items: center;
        div {
            margin: 0 10px;
        }
        .g-settings-icon {
            color: $white;
            font-size: 2.5rem;
            cursor: pointer;
            //display: block;
            //padding-top: 8px;
            // height: 20px;
            flex: 1;
            text-align: center;
            .icon {
                display: flex;
                width: 30px;
                margin: auto;
                height: 27px;
            }
        }

        .notification--icons {
            //margin-left: 10px;
            //padding-top: 8px;
            //height: 20px;
            color: $white;
            cursor: pointer;
            z-index: 50;
            flex: 1;
            text-align: center;
            position: relative;
            &:before {
                border-radius: 50%;
                height: 40px;
                width: 40px;
                position: absolute;
                top: -3px;
                left: 7px;
                transform: scale(1, 1);
                transform-origin: center;
            }
            &.has-new {
                color: $amber;
            }

            .sidebar-notif {
                //margin-top: 1px;
                .icon-alarm {
                    font-size: 2.5rem;
                    display: flex;
                    width: 30px;
                    margin: auto;
                    height: 27px;
                }
                .badge {
                    position: absolute;
                    top: -7px;
                    right: -3px;
                    border: 1px solid white;
                    border-radius: 50%;
                    background: orange;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    span {
                        // position: relative;
                        // top: -23px;
                        // left: -4px;
                        color: white !important;
                    }
                }
            }
        }

        .user-icon {
            color: #ffffff;
            text-align: center;
            display: content;
            width: max-content;
            // margin-left: 20px;

            .hover-user {
                border: 2px solid rgb(143, 143, 143) !important;
                border-radius: 30px;
                height: 40px;
                position: relative;
                padding: 9px !important;
                margin: 0 !important;
                .avatar-icon {
                    border: none;
                    width: 40px;
                    height: 40px;
                    margin-right: -11px;
                    border: 2px solid #8f8f8f;
                    margin-top: -1px;
                }
                .user-name {
                    padding-right: 15px;
                    padding-left: 10px;
                    color: white;
                }
                .popover-inner-content {
                    margin: 0;
                    padding: 10px 0;
                }
            }
        }
    }
}
.btn-report-call-to-action {
    color: white !important;
    &:hover {
        background-color: #1d8cf8 !important;
    }
    span {
        padding: 5px;
    }
}
.ui-popover {
    .user {
        border-radius: 5px;
        box-sizing: content-box;
        text-align: center;
        width: 185px;
        padding: 5px 0px;

        .user-name {
            color: #333;
            padding-top: 10px;
            margin: 0;
            font-size: 14px;
        }
        .avatar {
            border: 5px solid #d1d1d1;
        }

        .button {
            display: flex;
            align-items: center;
            text-align: center;
            place-items: center;
            min-width: 180px;
            border-radius: 10px;
            padding: 5px;
            margin: auto;
            cursor: pointer;
            justify-content: center;
            margin-bottom: 15px;
            top: 15px;
            * {
                color: white;
            }

            &.profile {
                border: 1px solid #1d8cf8;
                background-color: #1d8cf8;
                color: white;
            }

            &.log-out {
                border: 1px solid #1d8cf8;
                background-color: white;
                * {
                    color: #1d8cf8;
                }
            }
            .icon {
                font-size: 25px;
                margin-bottom: -7px;
                margin-right: 4px;
            }
            label {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}

.slick-slide {
    float: left !important;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    direction: ltr !important;
}

.ant-select-dropdown-menu-item {
    &::after {
        display: none !important;
    }
}

.sidebar-time-duration {
    .ui-radio-group {
        &.horizontal {
            flex-wrap: wrap;
        }
    }
    .ui-radio {
        width: 200px;
        .ui-radio-label {
            color: white;
        }
    }
}
.content-cluster-global-filter {
    .row-label {
        color: #d1d1d1;
    }
    .custom-collapse .header {
        color: #d1d1d1;
        &::after {
            border: 1px solid #d1d1d1;
            border-style: dashed;
        }
    }
    .ant-checkbox-wrapper {
        color: #d1d1d1 !important;
    }
    .css-es53b3-multiValue {
        background-color: #191d29;
        color: #d1d1d1;
    }
    .select-transparent {
        > div {
            @include borderRadius(30px);
            background: #070d17;
            // width: 300px;
            color: $white;
            overflow: hidden;
            margin: 0;
            border-color: $main-color;

            div {
                &:nth-child(1) {
                    div {
                        color: #ffffff;
                        padding-right: 5px;
                    }

                    + div {
                        span {
                            display: none;
                        }
                    }
                    .ant-select-selection__choice__remove {
                        line-height: 25px;
                        font-size: 14px;
                    }
                }
            }

            svg {
                fill: #ffffff;
            }

            &:hover {
                border-color: $main-color;
                cursor: pointer;
            }
        }

        // span{
        //   + div{
        //     border-bottom: none !important;
        //     box-shadow: unset;
        //     @include borderRadius(15px, 15px, 0, 0 );
        //   }
        // }
        .css-kj6f9i-menu {
            box-shadow: none;
        }
        & > div:nth-child(3) {
            @include borderRadius(0, 0, 15px, 15px);
            border-top: 10px solid #070d17;
            background: #070d17;
            border-left: 2px solid $main-color;
            border-right: 2px solid $main-color;
            border-bottom: 2px solid $main-color;
            overflow: auto;
            margin-top: -10px;
            & div {
                position: relative;
            }
            & div div {
                color: #ffffff;
                background: #070d17;
                // &:nth-child(1){
                //   // border-top: 2px solid lighten( $sidebar-backcolor, 10% )
                // }
            }
            & div div:hover {
                // background: white;
                cursor: pointer;
                color: $main-color;
                margin-left: -4px;
            }
        }

        &.disabled {
            & div {
                background: transparent;
                border-color: $disabled-btn;
                color: $disabled-btn !important;
                span {
                    background: $disabled-btn;
                }
                svg {
                    fill: $disabled-btn !important;
                }
            }
        }
        > span + div {
            position: relative;
            z-index: 4;
            overflow: visible;
        }

        .ui-modal-body & {
            > div {
                background: white;
                div {
                    &:nth-child(1) {
                        div {
                            color: $defaults-backcolor;
                            padding-right: 10px;
                        }
                    }
                }
                svg {
                    fill: $defaults-backcolor;
                }
            }

            & > div:nth-child(3) {
                @include borderRadius(0, 0, 15px, 15px);
                border-top: 0 solid $sidebar-backcolor;
                border-left: 1px solid $main-color;
                border-right: 1px solid $main-color;
                border-bottom: 1px solid $main-color;
                background: white;
                overflow: auto;
                margin-top: -5px;
                & div {
                    position: relative;
                }
                & div div {
                    color: $defaults-backcolor;
                    background: white;
                    // &:nth-child(1){
                    //     // border-top: 2px solid lighten( $sidebar-backcolor, 10% )
                    // }
                }
                & div div:hover {
                    // background: white;
                    cursor: pointer;
                    color: $main-color;
                    margin-left: -4px;
                }
            }
        }

        &.multi {
            .ant-select-selection__rendered {
                height: 100%;
            }
            > div {
                div:first-child {
                    .css-xwjg1b {
                        background-color: $color-primary12;
                        border-radius: 10px;
                        overflow: hidden;

                        .css-1alnv5e {
                            padding: 0 0px 0 7px;
                            &:hover {
                                background-color: transparent;
                                svg {
                                    fill: $pink;
                                }
                            }
                        }
                    }
                }
            }
            .css-1hwfws3 {
                div {
                    background-color: #191d29;
                    &:last-child {
                        background-color: transparent;
                        box-shadow: none !important;
                    }
                }
            }
        }

        &.select-sm {
            margin: 0 4px;
            // & > div:nth-child(1){
            //   min-height: unset;
            //   height: 26px;
            // }
            .css-vj8t7z,
            .css-162g8z5,
            .css-2o5izw,
            .css-bg1rzq-control,
            .css-3esr4u-a11yText,
            .css-1szy77t-control {
                //
                min-height: unset;
                height: 30px;

                // height: 26px;
            }
            .css-2o5izw {
                // active mode
                min-height: unset;
                // height: 26px;
            }
            .css-bl6clz {
                // extra element on top inner
                display: none;
            }
            .css-1hwfws3 {
                // text
                line-height: 14px;
                font-size: 13px;
            }
            .css-16pqwjk-indicatorContainer {
                // arrow
                padding: 2px 5px;
            }
            .css-1thkkgx-indicatorContainer {
                // active arrow
                padding: 2px 5px;
            }
            .css-1g6gooi {
                line-height: 20px;
                font-size: 12px;
                height: 20px;
                margin-top: 0;
                padding-top: 0;
            }

            &.count-perpage-filter {
                display: inline-block;
                width: 160px;
            }
            &.count-perpage-paginate {
                display: inline-block;
                width: 65px;
                .css-bg1rzq-control {
                    height: 25px;
                }
            }
            &.ant-select-sm {
                width: 160px;
                .ant-select-selection {
                    min-width: unset;
                    height: 30px;
                    .ant-select-selection__rendered {
                        line-height: 27px;
                    }
                }
            }
        }
    }
}
