.alert-new-version {
    padding: 30px;
    max-height: 70vh;
    overflow-y: auto;
    & .alert-new-version-header {
        font-size: bold;
        & .feature-list {
            font-weight: bold;
            padding-right: 20px;
            list-style: unset;
            & .feature {
                display: flex;
                & .description {
                    font-weight: bold;
                    line-height: 32px;
                }
                & .link {
                    margin-right: 16px;
                    line-height: 32px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: normal;
                }
                &::before {
                    content: "• ";
                    color: #1d8cf8;
                    font-size: 20px;
                    margin-top: -2px;
                    margin-left: 8px;
                }
            }
        }
    }
    & .detail {
    }
}
