@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';
$red:#f00;
$red-lt:lighten($red, 30%);
$red-shadow:darken($red, 10%);

$blue:rgba(0,183,229,0.9);
$blue-lt:lighten($blue, 30%);
$blue-shadow:darken($blue, 30%);

$cyan:rgba(0,229,183,0.9);
$cyan-shadow:darken($cyan, 30%);

$green:#8aff51;
$green-lt:lighten($green, 30%);
$green-shadow:darken($green, 30%);

body {
  background:#161616;
  font:12px normal Verdana, Arial, Helvetica, sans-serif;
}

@mixin animation($name, $duration:1s, $delay:0, $ease:linear, $iterations:infinite, $endState:normal){
	-webkit-animation: $name $duration $ease $iterations $endState;
	-moz-animation: $name $duration $ease $iterations $endState;
	-ms-animation: $name $duration $ease $iterations $endState;
  animation: $name $duration $ease $iterations $endState;
  @include delay($delay);
}

@mixin transform-origin($string){
  -webkit-transform-origin: $string;
  -moz-transform-origin: $string;
  -ms-transform-origin: $string;
  transform-origin: $string;
}

@mixin delay($delay){
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin box-shadow($string){
	-webkit-box-shadow: $string;
	-moz-box-shadow: $string;
	box-shadow: $string;
}

@mixin shadow-border($width:1px){
  border:$width solid #111;
  border-right-color:#333;
  border-bottom-color:#333;
}

@mixin square($xy:30px){
  width:$xy;
  height:$xy;
}

@mixin gradient($deg:90deg, $color1:#161616, $color2:#000){
  filter:  gradient(startColorstr='$color1', endColorstr='$color2');
  background-image:-webkit-linear-gradient($deg, $color1 25%, $color2);
  background-image:-moz-linear-gradient($deg, $color1 25%, $color2);
}

.stop {
  animation-play-state:paused;
  -moz-animation-play-state:paused;
  -webkit-animation-play-state:paused;
}
.trigger {
  @include gradient(top);
  @include shadow-border;
  text-decoration:none;
  color:#fff;
  padding:10px;
  font-family:Verdana, Geneva, sans-serif;
  font-size:0.8em;
  text-transform:lowercase;
  margin:10px auto;
  display:block;
  width:140px;
  border-radius:5px;
  text-align:center;
  &:hover {
    @include gradient(top, #202020, #161616);
  }
}

#ajaxloader1
{
  @include square;
  border:8px solid #fff;
  border-radius:50%;
  @include box-shadow(0 0 25px 2px);
  border-color:$red;
  color:$red-shadow;
  border-right-color:transparent;
  border-top-color:transparent;
  @include animation(adjust-hue-right);
  margin:30px auto 0;
  &:after {
    display:block;
    @include square(13px);
    margin:3px;
    border:6px solid $red;
    content:" ";
    border-radius:50%;
    border-left-color:transparent;
    border-bottom-color:transparent;
  }
}

#ajaxloader2 {
  margin:30px auto 0;
  .outer {
    border:5px solid $blue;
    opacity: 9;
    @include square(50px);
    border-top-color:transparent;
    border-bottom-color:transparent;
    border-radius:50%;
    @include box-shadow(0 0 35px $blue-shadow);
    @include animation(adjust-hue-right, .5s);
    margin:0 auto;
  }
  .inner {
    display:block;
    @include square;
    border:5px solid $blue;
    opacity: 9;
    border-radius:50%;
    border-left-color:transparent;
    border-bottom-color:transparent;
    @include box-shadow(0 0 35px $blue-shadow);
    position:relative;
    top:-50px;
    margin:0 auto;
    @include animation(adjust-hue-left, .5s);
  }
}

#ajaxloader3 {
  margin:0 auto;
  .outer {
    border:5px solid $cyan;
    opacity:9;
    border-right-color:transparent;
    border-left-color:transparent;
    @include square(50px);
    margin:0 auto;
    border-radius:50%;
    @include box-shadow(0 0 35px $cyan-shadow);
    @include animation(adjust-hue-pulse);
  }
  .inner {
    border:5px solid $cyan;
    opacity:9;
    border-left-color:transparent;
    border-right-color:transparent;
    border-radius:50%;
    @include box-shadow(0 0 15px $cyan-shadow);
    @include square;
    position:relative;
    top:-50px;
    margin:0 auto;
    @include animation(adjust-hue-right);
  }
}

#ajaxbar1 {
  margin:0 auto;
  overflow:hidden;
  width:90px;
  .barlittle {
    background-color:$green;
    @include gradient(45deg, $green, $green-lt);
    border:1px solid #111;
    @include square(10px);
    float:left;
    margin-left:5px;
    opacity:0.1;
    @include animation(pulse);
  }
  #block1 { @include delay(.3s); }
  #block2 { @include delay(.2s); }
  #block3 { @include delay(.1s); }
  #block4 { @include delay(.2s); }
  #block5 { @include delay(.3s); }
}

#ajaxwave1 {
  margin:30px auto 0;
  overflow:hidden;
  height:50px;
  .pointcircle {
    background-color:$red;
    @include gradient(90deg, $red, $red-lt);
    @include square(3px);
    border-radius:50%;
    @include box-shadow(0 0 5px $red-shadow);
    margin:0 auto;
    position:relative;
  }
  #pointcircle1 {
    @include animation(ball-circlex, 2s, 0, infinite, cubic-bezier(0,0,0.35,1));
  }
  #pointcircle2 {
    opacity:0.7;
    top:1px;
    @include animation(ball-circlex, 2s, 0.1s, infinite, cubic-bezier(0,0,0.35,1));
  }
  #pointcircle3 {
    opacity:0.5;
    top:2px;
    @include animation(ball-circlex, 2s, 0.2s, infinite, cubic-bezier(0,0,0.35,1));
  }
  #pointcircle4 {
    opacity:0.3;
    top:3px;
    @include animation(ball-circlex, 2s, 0.3s, infinite, cubic-bezier(0,0,0.35,1));
  }
  #pointcircle5 {
    opacity:0.1;
    top:4px;
    @include animation(ball-circlex, 2s, 0.4s, infinite, cubic-bezier(0,0,0.35,1));
  }
}

#facebook {
  @include square(32px);
  margin:0 auto;
  .bar {
    background:#99aaca;
    border:1px solid #96a6c9;
    float:left;
    margin:6px 4px 0 0;
    width:6px;
    height:18px;
    @include animation(facebook-pulse);
    &:nth-child(2) { @include delay(0.1s); }
    &:last-child { @include delay(0.2s); margin-right:0; }
  }
}

#loadpulse {
  width:70px;
  padding:8px 5px;
	margin:30px auto;
  overflow:hidden;
  .segment {
	  float:left;
	  position:relative;
	  @include square(8px);
	  border-radius:50px;
	  margin-left:5px;
	  border:1px solid #111;
    border-right-color:#333;
    border-bottom-color:#333; 
	  background:#000;
    &:first-child { margin-left:0; }
  }
  .ball {
  	background-color:#2187e7; 
    @include gradient(90deg, #2187e7, #2187e7);
  	@include square(8px);
  	border-radius:50px;
  	// -moz-transform:scale(0);
  	// -webkit-transform:scale(0);
  	@include animation(loadpulse-ball, 1s, 0, linear, forwards);
  }
  .pulse {
  	@include square(8px);
  	border-radius:30px;
  	border:3px solid #00c6ff;
  	@include box-shadow(0 0 5px #00c6ff);
  	position:absolute;
  	top:-1px;
  	left:-1px;
  	// -moz-transform:scale(0);
  	// -webkit-transform:scale(0);
  	@include animation(loadpulse-glow, 3s, 0, infinite, ease-out);
  }
  #layer1 { @include delay(0.5s); }
  #layer2 { @include delay(1s); }
  #layer3 { @include delay(1.5s); }
  #layer4 { @include delay(2s); }
  #layer5 { @include delay(2.5s); }
  #layer7 { @include delay(1.5s); }
  #layer8 { @include delay(2s); }
  #layer9 { @include delay(2.5s); }
  #layer10 { @include delay(3s); }
  #layer11 { @include delay(3.5s); }
}

#shadowloader {
  display: block;
  position:relative;
  overflow:hidden;
  width:5em;
  margin:30px auto;
  padding:20px 10px;
  span {
    display:block;
    float:left;
    width:0.5em;
    height:3em;
    margin:0 0.5em 0 0;
    background:#635863;
    @include gradient(top, #635863, #3d353b);
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.0), 1px 1px 1px 0 rgba(0, 0, 0, 0.0), 1px 1px 1px 0 rgba(0, 0, 0, 0.0);
    @include animation(pound, .7s, .05s, ease-in-out, infinite, alternate);
    @include transform-origin(center bottom);
    &:nth-child(2){ @include delay(0.20s); }
    &:nth-child(3){ @include delay(0.35s); }
    &:nth-child(4){ @include delay(0.50s); }
    &:nth-child(5){ @include delay(0.65s); }
  }
}

h1.loadingtext{
  text-align:center;
  text-transform:uppercase;
  font-family:'Nunito', sans-serif;
  font-size:4.6875em;
  color:transparent;
  letter-spacing:0.01em;
  span{
    text-shadow:
      0 0 2px rgba(22, 22, 22,0.9),
      0 15px 25px rgba(0, 0, 0, 0.3),
      0 -2px 3px rgba(0, 0, 0, 0.1),
      0 -5px 10px rgba(22, 22, 22, 0.5),
      0 5px 10px rgba(0, 0, 0, 0.3),
      0 3px 4px rgba(22, 22, 22, 0.2),
      0 0 20px rgba(22, 22, 22, 0.45);
    @include animation(letters, 0.85s, 0, ease-in-out, infinite, alternate);
    &:nth-child(2){ @include delay(0.15s); }
    &:nth-child(3){ @include delay(0.30s); }
    &:nth-child(4){ @include delay(0.45s); }
    &:nth-child(5){ @include delay(0.60s); }
    &:nth-child(6){ @include delay(0.75s); }
    &:nth-child(7){ @include delay(0.90s); }
  }
}




@keyframes adjust-hue-right
{
  from { transform:rotate(0deg); opacity:0.2; }
  50% { transform:rotate(180deg); opacity:1.0; }
  to { transform:rotate(360deg); opacity:0.2; }
}
@-moz-keyframes adjust-hue-right
{
  from { -moz-transform:rotate(0deg); opacity:0.2; }
  50% { -moz-transform:rotate(180deg); opacity:1.0; }
  to { -moz-transform:rotate(360deg); opacity:0.2; }
}
@-webkit-keyframes adjust-hue-right
{
  from { -webkit-transform:rotate(0deg); opacity:0.2; }
  50% { -webkit-transform:rotate(180deg); opacity:1.0; }
  to { -webkit-transform:rotate(360deg); opacity:0.2; }
}

@keyframes adjust-hue-left
{
  from { transform:rotate(0deg); opacity:0.2; }
  50% { transform:rotate(-180deg); opacity:1.0; }
  to { transform:rotate(-360deg); opacity:0.2; }
}
@-moz-keyframes adjust-hue-left
{
  from { -moz-transform:rotate(0deg); opacity:0.2; }
  50% { -moz-transform:rotate(-180deg); opacity:1.0; }
  to { -moz-transform:rotate(-360deg); opacity:0.2; }
}
@-webkit-keyframes adjust-hue-left
{
  from { -webkit-transform:rotate(0deg); opacity:0.2; }
  50% { -webkit-transform:rotate(-180deg); opacity:1.0; }
  to { -webkit-transform:rotate(-360deg); opacity:0.2; }
}

@keyframes adjust-hue-pulse
{
  from {
    transform:rotate(160deg);
    opacity:0;
    box-shadow:0 0 1px $blue-shadow;
  }
  50% { transform:rotate(145deg); opacity:1; }
  to { transform:rotate(-320deg); opacity:0; }
}
@-moz-keyframes adjust-hue-pulse
{
  from {
    -moz-transform:rotate(160deg);
    opacity:0;
    box-shadow:0 0 1px $blue-shadow;
  }
  50% { -moz-transform:rotate(145deg); opacity:1; }
  to { -moz-transform:rotate(-320deg); opacity:0; }
}
@-webkit-keyframes adjust-hue-pulse
{
  from {
    -webkit-transform:rotate(160deg);
    opacity:0;
    box-shadow:0 0 1px $blue-shadow;
  }
  50% { -webkit-transform:rotate(145deg); opacity:1; }
  to { -webkit-transform:rotate(-320deg); opacity:0; }
}

@keyframes pulse
{
  from { transform:scale(1.2); opacity:1; }
  to { transform:scale(0.7); opacity:0.1; }
}
@-moz-keyframes pulse
{
  from { -moz-transform:scale(1.2); opacity:1; }
  to { -moz-transform:scale(0.7); opacity:0.1; }
}
@-webkit-keyframes pulse
{
  from { -webkit-transform:scale(1.2); opacity:1; }
  to { -webkit-transform:scale(0.7); opacity:0.1; }
}

@keyframes ball-circlex
{
  from { transform:translateX(0px); }
	25% {
    transform:translateX(25px);
    animation-timing-function: ease-in;
  }
	50% { transform:translateX(0px); }
	75% {
    transform:translateX(-25px);
    animation-timing-function: ease-in;
  }
	to { transform:translateX(0px); }	
}
@-moz-keyframes ball-circlex
{
  from { -moz-transform:translateX(0px); }
	25% {
    -moz-transform:translateX(25px);
    -moz-animation-timing-function: ease-in;
  }
	50% { -moz-transform:translateX(0px); }
	75% {
    -moz-transform:translateX(-25px);
    -moz-animation-timing-function: ease-in;
  }
	to { -moz-transform:translateX(0px); }	
}
@-webkit-keyframes ball-circlex
{
  from { -webkit-transform:translateX(0px); }
	25% {
    -webkit-transform:translateX(25px);
    -webkit-animation-timing-function: ease-in;
  }
	50% { -webkit-transform:translateX(0px); }
	75% {
    -webkit-transform:translateX(-25px);
    -webkit-animation-timing-function: ease-in;
  }
	to { -webkit-transform:translateX(0px); }	
}

@keyframes facebook-pulse
{
  0% { }
  10% {
    margin-top:5px;
    height:22px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  20% {
    margin-top:0px;
    height:32px;
    border-color:#d1d7e2;
    background-color:#bac5db;
  }
  30% {
    margin-top:1px;
    height:30px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  40% { margin-top:3px; height:26px; }
  50% { margin-top:5px; height:22px; }
  60% { margin-top:6px; height:18px; }
  100% { }
}
@-moz-keyframes facebook-pulse
{
  0% { }
  10% {
    margin-top:5px;
    height:22px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  20% {
    margin-top:0px;
    height:32px;
    border-color:#d1d7e2;
    background-color:#bac5db;
  }
  30% {
    margin-top:1px;
    height:30px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  40% { margin-top:3px; height:26px; }
  50% { margin-top:5px; height:22px; }
  60% { margin-top:6px; height:18px; }
  100% { }
}
@-webkit-keyframes facebook-pulse
{
  0% { }
  10% {
    margin-top:5px;
    height:22px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  20% {
    margin-top:0px;
    height:32px;
    border-color:#d1d7e2;
    background-color:#bac5db;
  }
  30% {
    margin-top:1px;
    height:30px;
    border-color:#d1d8e6;
    background-color:#bac5db;
  }
  40% { margin-top:3px; height:26px; }
  50% { margin-top:5px; height:22px; }
  60% { margin-top:6px; height:18px; }
  100% { }
}

@keyframes loadpulse-ball {
    from { transform:scale(0); }
    to { transform:scale(1); }	
}
@-moz-keyframes loadpulse-ball {
    from { -moz-transform:scale(0); }
    to { -moz-transform:scale(1); }	
}
@-webkit-keyframes loadpulse-ball {
    from { -webkit-transform:scale(0); }
    to { -webkit-transform:scale(1); }	
}

@keyframes loadpulse-glow {
    from { transform:scale(0); opacity:0; }
    10% { transform:scale(1); opacity:0.5; }
    50% { transform:scale(1.75); opacity:0; }
    to { transform:scale(0); opacity:0; }  
}
@-moz-keyframes loadpulse-glow {
    from { -moz-transform:scale(0); opacity:0; }
    10% { -moz-transform:scale(1); opacity:0.5; }
    50% { -moz-transform:scale(1.75); opacity:0; }
    to { -moz-transform:scale(0); opacity:0; }  
}
@-webkit-keyframes loadpulse-glow {
    from { -webkit-transform:scale(0); opacity:0; }
    10% { -webkit-transform:scale(1); opacity:0.5; }
    50% { -webkit-transform:scale(1.75); opacity:0; }
    to { -webkit-transform:scale(0); opacity:0; }
}

@keyframes pound {
	to { transform: scale(1.2); box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.65), 2px 6px 12px 0 rgba(0, 0, 0, 0.5), 3px 8px 15px 0 rgba(0, 0, 0, 0.45) }
}
@-moz-keyframes pound {
	to { -moz-transform: scale(1.2); box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.65), 2px 6px 12px 0 rgba(0, 0, 0, 0.5), 3px 8px 15px 0 rgba(0, 0, 0, 0.45) }
}
@-webkit-keyframes pound {
	to { -webkit-transform: scale(1.2); box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.65), 2px 6px 12px 0 rgba(0, 0, 0, 0.5), 3px 8px 15px 0 rgba(0, 0, 0, 0.45) }
}

@keyframes letters {
	to {
    text-shadow:
      0 0 2px rgba(204, 208, 212,0.2),
      0 0 3px rgba(0, 0, 0, 0.02),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(255, 255, 255, 0),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(255, 255, 255, 0),
      0 0 0 rgba(255, 255, 255, 0);
  }
}
@-moz-keyframes letters {
	to {
    text-shadow:
      0 0 2px rgba(204, 208, 212,0.2),
      0 0 3px rgba(0, 0, 0, 0.02),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(255, 255, 255, 0),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(255, 255, 255, 0),
      0 0 0 rgba(255, 255, 255, 0);
  }
}
@-webkit-keyframes letters {
	to {
    text-shadow:
      0 0 2px rgba(22, 22, 22,0.2),
      0 0 3px rgba(0, 0, 0, 0.02),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(0, 0, 0, 0),
      0 0 0 rgba(0, 0, 0, 0);
  }
}



.loader {
  position: relative;
  width: 80px;
  margin: 0 auto;
  margin-top: 20px;
}

.duo {
  height: 20px;
  width: 50px;
  background: transparent;
  position: absolute;
  
}

.duo, .dot {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.duo1 {
  left: 0;
}

.duo2 {
  left: 30px
}


.dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  @include themify() {
    background : themed('loading-bg-color');
  } 
  position: absolute;
}

.dot-a {
  left: 0px;  
}

.dot-b {
  right: 0px;
}


@keyframes spin {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(180deg) }
  100% { transform: rotate(180deg) }
}

@keyframes onOff {
    0% { opacity: 0; }
   49% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 1; }
}

.duo1 {
  animation-name: spin;
}

.duo2 {
  animation-name: spin;
  animation-direction: reverse;
}

.duo2 .dot-b {
  animation-name: onOff;
}

.duo1 .dot-a {
  opacity: 0;
  animation-name: onOff;
  animation-direction: reverse;
}