@import "./../../assets/css/base/variables";
@import "./../../assets/css/base/mixins";
.applied-filters {
  .pointer {
    cursor: pointer;
  }
  .inner-content {
    display: flex;
    justify-content: space-around;
    height: 32px;
    align-items: center;
    // overflow-y: hidden;
    .select-transparent {
      > div {
        @include themify() {
          background: themed("select-bg-color");
          color: themed("select-color");
        }

        div {
          &:nth-child(1) {
            div {
              @include themify() {
                color: themed("select-color");
              }
            }
          }
        }
      }
    }
    .list {
      padding: 0 1px;
      overflow: hidden;
      display: flex;
      width: 100%;

      .ScrollbarsCustom {
        width: 100%;
        .content {
          height: auto;
          padding: 0;
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            @include themify() {
              color: themed("scrollbar-bg-color") !important;
            }
          }
        }
        .track.trackX {
          height: 4px !important;
        }
      }
      ul {
        display: flex;
        margin-bottom: 0;
        li {
          display: flex;
          @include themify() {
            background-color: themed("apply-filter-button-bg-color");
          }
          padding: 3px 15px 3px 0;
          border-radius: 20px;
          position: relative;
          margin: 0 5px;
          height: 32px;
          align-items: center;
          height: 30px;
          font-size: 13px;
          @include themify() {
            color: themed("apply-filter-button-color");
          }
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
          }
          i {
            padding: 4px 15px 0 10px;
            font-size: 9px;
            @include themify() {
              color: themed("apply-filter-icon-color");
            }
            cursor: pointer;
          }
          span {
            padding-right: 5px;
            white-space: nowrap;
          }
        }
      }

      .carousel {
        .right,
        .left {
          .icon {
            &::after {
              background-color: transparent;
            }
          }
          &.disable {
            display: none;
            .icon {
              color: $color-secondary5;
            }
          }
        }
      }
    }
    .close {
      display: flex;
      padding: 0 10px 0 3px;
      i {
        @include themify() {
          color: themed("apply-filter-icon-close-color");
        }
        font-size: 16px;
        vertical-align: sub;
        cursor: pointer;
      }
    }
    .label {
      display: flex;
      @include themify() {
        color: themed("apply-filter-label-color");
      }
      width: 60px;
      padding: 0;
      white-space: nowrap;
    }
  }
  .JDinput {
    @include themify() {
      color: themed("datepicker-input-text-color") !important;
    }
  }
}

.global-filter-header {
  .applied-filters {
    padding: 0 10px;
  }
}
.small-date-picker {
  display: flex;
  align-items: center;
  margin-right: 7px;
  .date-time-range-picker-container {
    display: flex;
    .custom-input-datepicker {
      height: 28px;
      padding: 3px 12px;
      width: 150px;
    }
  }
}
.search-in-result-view-params {
  .applied-filters {
    direction: rtl;
    padding: 5px 15px 2px 0;
    .inner-content {
      .list {
        ul {
          li {
            @include themify() {
              background-color: themed("apply-filter-button-bg-color");
            }
          }
        }
      }
    }
  }
}
