@mixin content($color) {
  background: $color-default;
  color: $color-main;
}

.mb-average {
  margin-top: -10px;
  @include themify() {
    background-color: themed("minibox-bg-color");
  }
  border-radius: 10px;
  display: flex;
  place-items: center;
  padding: 0px 10px 0px 10px;
  .mini-title {
    font-size: $font-size-medium;
    @include themify() {
        color: themed("minibox-title-color");
      }
    margin: 0;
  }
  .number {
    color: $white;
    font-size: 32px;
    line-height: 1.2;
  }
  .section {
    flex: 1;
    &.one {
      flex: 0.6;
      text-align: right;
      .title {
        font-size: $font-size-xxlarg;
        margin-top: -10px;
        @include themify() {
          color: themed("minibox-title-color");
        }
        .description {
          cursor: pointer;
          font-size: 15px;
          top: 0px;
          left: 0px;
        }
      }
      .average {
        margin-right: -10px;
        font-size: 45px;
        @include themify() {
          color: themed("minibox-pink-color");
        }
      }
    }
    &.two {
      flex: 0.4;
      text-align: left;
      white-space: nowrap;
      .count {
        font-size: 45px;
        @include themify() {
          color: themed("minibox-pink-color");
        }
      }
    }

    &.one .average.icon-like,
    &.two .count.like {
      @include themify() {
        color: themed("minibox-pink-color");
      }
    }
    &.one .average.icon-post,
    &.two .count.post {
      @include themify() {
        color: themed("minibox-main-color");
      }
    }
    &.one .average.icon-user-icon,
    &.two .count.user-icon {
      @include themify() {
        color: themed("minibox-follow-color");
      }
    }
    &.one .average.icon-follower,
    &.two .count.follower {
      @include themify() {
        color: themed("minibox-follow-color");
      }
    }
    &.one .average.icon-comment-icon,
    &.two .count.comment-icon {
      @include themify() {
        color: themed("minibox-green-color");
      }
    }
    &.one .average.icon-engagement,
    &.two .count.engagement {
      @include themify() {
        color: themed("minibox-follow-color");
      }
    }
    &.one .average.icon-impression,
    &.two .count.impression {
      @include themify() {
        color: themed("minibox-main-color");
      }
    }
  }
}
