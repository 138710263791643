.ui-errors {
    .ui-errors-lable {
        color: #b71c1c !important;
    }
    .ui-errors-more {
        color: #991919 !important;
        font-size: 12px;
        margin-right: 15px;
        cursor: pointer;
        .icon {
            margin-right: 10px;
            display: inline-flex;
        }
    }
    .ui-errors-list {
        list-style: none;
        user-select: text;
        padding: 0;
        margin: 0;
        direction: rtl;
        margin-top: 15px;
        color: #991919;
        .ui-errors-list-title {
            font-weight: bold;
        }
    }
}
.theme-dark,
.theme-light {
    .Toastify {
        .Toastify__toast-container {
            .Toastify__toast--error {
                color: #b71c1c !important;
                background: #f9c9c9 !important;
                border-color: 2px solid #ffb5b5 !important;
            }
            .Toastify__toast--success {
                color: #1d9360 !important;
                background: #cbfce7 !important;
                border-color: 2px solid #a7ffd9 !important;
            }
        }
    }
}

.Toastify__toast-body {
    margin: 0 10px !important;
}

.Toastify__close-button {
    color: gray !important;
}

.Toastify__toast {
    min-height: 45px !important;
}
