$themes: (
    light: (
        ui-form-label-color: #1d2333,
    ),
    dark: (
        ui-form-label-color: white,
    ),
);

@import "./../../assets/css/base/theme";

.ui-form {
    // border-bottom: 2px dashed silver;
    margin-bottom: 20px;
    .ui-form-row {
        display: flex;
        margin-bottom: 15px;
        align-items: baseline;
    }
    .ui-form-label {
        width: 150px;
        margin-left: 20px;
        @include themify() {
            color: themed("ui-form-label-color");
        }
        .ui-form-equired {
            color: red;
            margin-left: 2px;
        }
    }
    .ui-form-col {
        flex: 1;
        .ui-form-error-message {
            color: red;
            font-size: 12px;
            margin-top: 5px;
            margin-right: 15px;
            .icon-warning{
                margin-left: 5px;
            }
        }
    }
    .ui-form-uploader {
        width: max-content;
    }
    .ui-form-uploader-card {
    }
}
