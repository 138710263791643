.search-inner-collapse {
    .ui-collapse-custom {
        .ui-collapse-header {
            width: 100% !important;
            padding: 12px 16px 12px 40px;
            font-size: 1.25rem;
        }
        .ui-collapse-body {
            padding: 16px;
            border-right: 1px solid #757575;
            margin-right: -12px;
        }
    }
}
.ui-range-slider-search-filter {
    height: 32px;
    display: flex;
    align-items: center;
}
