.ui-popover {
    text-align: right;
    .ui-popover-icon {
        margin-left: 10px;
    }
    .ui-popover-seprate {
        margin: 5px 0;
    }
    *{
        color: #333;
    }
}
