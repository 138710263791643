$themes: (light: (ui-collapse-panel-color:#191d29,
        ui-collapse-header-color: #000000,
        ui-collapse-header-dotted-color: #000000,
    ),
    dark: (ui-collapse-panel-color:#191d29,
        ui-collapse-header-color: #ffffff,
        ui-collapse-header-dotted-color: #d1d1d1,
    ),
);

@import "./../../assets/css/base/theme";
@import "./../../assets/css/base/utils";

.ui-collapse {
    width: 100%;
    padding: 0 10px 0 0;

    .ui-collapse-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        padding: 10px 0 10px 10px;
        position: relative;


        // @include themify() {
        //     color: themed("ui-collapse-header-color") !important;
        // }

        i {
            transition: all 0.3s ease-in;
            line-height: 30px;
            float: left;
            height: 22px;
            width: 22px;
            text-align: center;

            @include themify() {
                color: themed("ui-collapse-header-dotted-color");
            }
        }

        &.active {
            .icon-arrow-down {
                transform: rotate(-180deg);
            }

            .icon-arrow-right {
                transform: rotate(-90deg);
            }
        }

        &.dotted {
            &:after {
                position: absolute;
                content: "";

                @include themify() {
                    border: 1px solid themed("ui-collapse-header-dotted-color");
                    border-style: dashed;
                    opacity: 0.5;
                }

                top: 20px;
            }
        }

        .dotted {
            width: 100%;
            margin-right: 5px;

            @include themify() {
                border-bottom: 1px dashed themed("ui-collapse-header-dotted-color");
                // border-style: dashed;
                opacity: 0.5;
            }
        }


    }

    .ui-collapse-body {
        padding-top: 15px;

        &.prewrap {
            white-space: pre-wrap;
        }

        // @include themify() {
        //     color: themed("ui-collapse-header-color");
        // }
    }

    &.accordion {
        .ui-collapse-panel {
            font-size: 14px;
            line-height: 1.5;
            background-color: #fafafa;
            border: 1px solid #d9d9d9;
            border-bottom: 0;
            border-radius: 4px;

            .ui-collapse-header {
                border-radius: 0 0 4px 4px;
                padding: 12px 16px 12px 10px;
                color: rgba(0, 0, 0, 0.85) !important;
                line-height: 22px;
                cursor: pointer;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                font-size: 14px;
            }

            .ui-collapse-body {
                border-radius: 0 0 4px 4px;
                overflow: hidden;
                color: rgba(0, 0, 0, 0.65);
                background-color: #fff;
                border-top: 1px solid #d9d9d9;
                font-size: 14px;
                line-height: 1.5;
                transition: all 0.3s;
                padding: 20px 16px 16px 16px;
            }
        }
    }
}