.tab-ant-table {
  direction: rtl;
  list-style-type: none;
  border-bottom: none;
  position: relative;
  color: $white;
  &:last-child {
    border-radius: 10px;
  }

  &.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  &:hover:not(.ant-table-expanded-row) > td,
  &.ant-table-row-selected td {
    background: #1f273e;
  }
}

.paginationTopMargin {
  margin-top: 115px !important;
}
.tab-mb-list {
  overflow-x: hidden;
  overflow-y: hidden;
  .main {
    height: 100%;
  }
  .pagination-wrapper {
    bottom: -60px;
    position: relative;
  }
  .jump-text span {
    color: $white !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      .selected {
        background: $main-color;
      }
      .fill {
        color: $white;
        cursor: pointer;
      }
      .empty {
        color: $regent-gray;
        cursor: auto;
      }
    }
  }
  .titleBG {
    .icon {
      line-height: 0.5;
      flex: 0.1;
      font-size: 29px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
  .tab-ant-table {
    .row {
      padding-right: 10px;
      text-align: right;
      @include themify() {
        color: themed("mb-tab-row-number-color");
      }
      &.row1 {
        @include themify() {
          color: themed("mb-tab-title-color") !important;
        }
        text-align: right;
      }
      &.row2 {
        text-align: left;
        padding-left: 20px;
        @include themify() {
          color: themed("mb-tab-number-color");
        }
      }
    }

    .col {
      margin-top: 5px;
      margin-bottom: 5px;
      height: 40px;
      font-size: 14px;
      padding-right: 10px;
      line-height: 40px;
      @include themify() {
        background: themed("mb-tab-row-bg-color");
      }
      border-radius: 10px;
    }
  }
}

// .content:has(> div) {
//     overflow-y: hidden !important;
// }

// .content{

//     .tab-mb-list & {
//         overflow-y: hidden !important;
//     }
// }

.tab-mb-list-no-scroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
