$themes: (light: (ui-message-close-icon-color:#757575,

        ui-message-success-bg-color: #CBFCE7,
        ui-message-success-color: #1D9360,
        ui-message-success-border-color: #A7FFD9,
        ui-message-success-icon-color: #1D9360,

        ui-message-error-bg-color: #F9C9C9,
        ui-message-error-color: #B71C1C,
        ui-message-error-border-color: #FFB5B5,
        ui-message-error-icon-color: #B71C1C,

        ui-message-warning-bg-color: #FFD5C8,
        ui-message-warning-color: #FF5722,
        ui-message-warning-border-color: #FFC0AC,
        ui-message-warning-icon-color: #FF5722,

        ui-message-info-bg-color: #D1E8FF,
        ui-message-info-color: #1D8CF8,
        ui-message-info-border-color: #B0D7FE,
        ui-message-info-icon-color: #1D8CF8,


    ),
    dark: (ui-message-close-icon-color: #D1D1D1,


        ui-message-success-bg-color: #1A3D38,
        ui-message-success-color: #FFFFFF,
        ui-message-success-border-color: #0c5033,
        ui-message-success-icon-color: #00E676,

        ui-message-error-bg-color: #321D27,
        ui-message-error-color: #FFFFFF,
        ui-message-error-border-color: #540F0F,
        ui-message-error-icon-color: #B71C1C,


        ui-message-warning-bg-color: #3B2628,
        ui-message-warning-color: #FFFFFF,
        ui-message-warning-border-color: #87361C,
        ui-message-warning-icon-color: #FF5722,

        ui-message-info-bg-color: #19385C,
        ui-message-info-color: #FFFFFF,
        ui-message-info-border-color: #f7e2e2,
        ui-message-info-icon-color: #D1E8FF,


    ),
);

@import "./../../assets/css/base/theme";


.ui-message {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    line-height: 1.5715;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 0 0 5px 5px;
    border: 2px solid;
    min-width: 200px;


    .ui-message-close-icon {
        display: none;
        position: absolute;
        top: 10px;
        left: 15px;
        padding: 0;
        overflow: hidden;
        font-size: 17px;
        height: 20px;
        width: 20px;
        text-align: center;
        border-radius: 20px;
        line-height: 20px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s;
        transition-property: color, background;

        @include themify() {
            color: themed("ui-message-close-icon-color");
        }

        background-color:#7575752e;
    }

    &:hover {
        .ui-message-close-icon {
            display: block;
        }

    }


    .ui-message-description {
        font-size: 14px;
        padding: 0px 15px;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
    }

    .ui-message-icon {
        position: absolute;
        top: 10px;
        right: 5px;

        line-height: 20px;
        font-size: 18px;

    }

    &.info {
        @include themify() {
            background-color: themed("ui-message-info-bg-color");
            border-color: themed("ui-message-info-border-color");
        }

        .ui-message-icon {
            @include themify() {
                color: themed("ui-message-info-icon-color");
            }
        }

        .ui-message-description {
            @include themify() {
                color: themed("ui-message-info-color");
            }
        }
    }

    &.success {
        @include themify() {
            background-color: themed("ui-message-success-bg-color");
            border-color: themed("ui-message-success-border-color");
        }

        .ui-message-description {
            @include themify() {
                color: themed("ui-message-success-color");
            }
        }

        .ui-message-icon {
            @include themify() {
                color: themed("ui-message-success-icon-color");
            }
        }
    }

    &.warning {
        @include themify() {
            background-color: themed("ui-message-warning-bg-color");
            border-color: themed("ui-message-warning-border-color");
        }

        .ui-message-description {
            @include themify() {
                color: themed("ui-message-warning-color");
            }
        }

        .ui-message-icon {
            @include themify() {
                color: themed("ui-message-warning-icon-color");
            }
        }
    }

    &.error {
        @include themify() {
            background-color: themed("ui-message-error-bg-color");
            border-color: themed("ui-message-error-border-color");
        }

        .ui-message-description {
            @include themify() {
                color: themed("ui-message-error-color");
            }
        }

        .ui-message-icon {
            @include themify() {
                color: themed("ui-message-error-icon-color");
            }
        }
    }
}