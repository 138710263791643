@import "./variables";
//
@import "./../hacks/antoverride";
@import "./../hacks/datepicker";
@import "./../base/global-styles";
@import "./../layout/sidebar";
@import "./../module/modal";
@import "./../module/Notification-sidebar";
@import "./../hacks/Evan-calender";
@import "./../vendor/video-react";
@import "./../../../shared/miniBox/mb-tab/MbTab";
@import "./../../../shared/miniBox/mb-obj-details/MiniBoxObjectDetails";
@import "./../../../shared/miniBox/mb-hashtag/MbHashtag";
@import "./../../../shared/miniBox/mb-stats/MiniBoxStats";
@import "./../../../shared/miniBox/mbRelease/MbRelease";
@import "./../../../shared/miniBox/mbTags/MbTags";
@import "./../../../shared/miniBox/MbList";
@import "./../../../shared/miniBox/MiniBox";

@font-face {
    font-family: $font-family-default;
    src: url("./../../fonts/IRANSansWeb(FaNum).ttf");
}

@font-face {
    font-family: "anticon";
    src: url("./../../fonts/font_zck90zmlh7hf47vi.eot");
    /* IE9*/
    src: url("./../../fonts/font_zck90zmlh7hf47vi.eot?#iefix") format("embedded-opentype"),
        /* chrome、firefox */
        url("./../../fonts/font_zck90zmlh7hf47vi.woff") format("woff"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("./../../fonts/font_zck90zmlh7hf47vi.ttf") format("truetype"),
        /* iOS 4.1- */
        url("./../../fonts/font_zck90zmlh7hf47vi.svg#iconfont") format("svg");
}

html {
    font-size: $font-size-default;
}

body {
    position: fixed;
    direction: rtl;
    font-family: $font-family-default;

    @include themify() {
        background-color: themed("body-bg-color") !important;
    }

    // color: $white;
    font-size: $font-size-default;
    height: 100vh;
    overflow: hidden;
}

body * {
    font-family: $font-family-default;
}

a {
    color: $link-color;
    border-bottom: 1px solid transparent;

    &:hover,
    :focus,
    :active {
        color: $link-color;
        border-bottom: 1px dotted $link-color;
    }
}

.full-height {
    min-height: calc(100vh - 60px);
    height: 100%;
    position: relative;

    &::after {
        min-height: calc(100vh - 60px);

        @include themify() {
            background-color: themed("sidebar-bg-color");
        }

        z-index: 0;
        position: absolute;
        top: 0;
        right: 100%;
        width: 300%;
        content: "";
    }
}

.default-sidebar-background {
    min-height: calc(100vh - 70px);
    height: 100%;

    @include themify() {
        background-color: themed("sidebar-bg-color");
    }

    // position: relative;
    z-index: 0;
}

.no-padding {
    padding: 0px !important;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: $blue;
    background-color: $logo-backcolor;
    height: 100%;
    text-align: center;

    img {
        width: auto;
        height: 70%;
    }
}

.logo-container img {
    width: auto;
    height: 70%;
}

.tether-element.tether-element-attached-top.tether-element-attached-center.tether-target-attached-bottom.tether-target-attached-center.tether-enabled {
    color: $black;

    button[disabled] {
        color: $color-secondary7;
        cursor: not-allowed;
        background-color: $gray-light;
    }
}

.JDatePicker {
    color: $black;

    .JDheader .right .number {
        border: 1px solid transparent;
    }
}

/* Table */
.ant-table-content {
    font-size: 12px;

    .ant-table-tbody {
        .list-actions-container {
            white-space: pre;
        }
    }
}

/* رینج پیکر */
.jdtrp {
    &>div {
        margin: 0;

        &:nth-child(2n + 1) {
            text-align: center;
            border: 1px solid $gradient-cyan-right-color;
            border-radius: 15px;
            padding: 5px 15px;
            background: transparent;
            width: 150px;
            font-family: $font-family-default;
            display: inline-block;
        }

        &:nth-child(2) {
            @include opacity(0);
            margin: 0;
        }
    }

    input {
        width: 100%;
        background: transparent;
        border: none;
        color: $white;
    }

    &::placeholder {
        color: $white;
        @include opacity(1);
    }
}

// STYLE FOR SIDEBAR
.show {
    display: block;
}

.hide {
    display: none !important;
}

.confirmHolder {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999 !important;

    &.invisible {
        opacity: 0;
        z-index: -1;
        pointer-events: none;

        .backDrop {
            @include transition(background 0.3s ease);
            background: rgba(0, 0, 0, 0);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    &.visible {
        opacity: 1;
        z-index: 100;
        pointer-events: all;

        .backDrop {
            @include transition(background 0.3s ease);
            background: rgba(0, 0, 0, 0.87);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    .confirmBox {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 600px;
        padding: 0;
        text-align: right;
        z-index: 2000;
        @include borderRadius(20px);

        @include themify() {
            background-color: themed("confirmbox-bg-color");
        }

        .isRequired {
            color: red;
            bottom: 6px;
            position: relative;
        }

        .confirmBox-container {
            padding: 0 5px;

            .introduction {
                overflow-x: hidden;

                @include themify() {
                    background-color: themed("introduction-bg-color") !important;
                }

                border: unset !important;
                margin-bottom: 0;
                word-wrap: break-word;

                &:focus {
                    border: 1px none !important;
                }

                &::-webkit-scrollbar-track {
                    margin: 20px !important;
                    left: 20px !important;
                }
            }

            .icon-delete {
                color: $red-delete;
            }
        }

        input {
            width: 100%;
            border-radius: 30px;
            padding: 10px;
            border: 0px none;
            margin: 10px 0 20px 0;
            color: $mine-shaft;

            @include themify() {
                background-color: themed("confirmbox-input-bg-color");
            }

            &:focus {
                outline: none;
            }
        }

        h2 {
            .icon {
                font-size: 20px;
                float: right;
                margin-left: 12px;
                color: $color-warning7;
                line-height: 32px;
                border: 2px solid;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                text-align: center;

                &:before {
                    margin: 0 !important;
                }
            }
        }

        h2 {
            @include themify() {
                color: themed("confirmbox-title-color");
            }
        }

        p {
            @include themify() {
                color: themed("confirmbox-description-color");
            }

            margin-bottom: 20px;
        }

        .button {
            min-width: 120px;
            padding: 0.3rem 1.2rem 0.4rem 1.2rem !important;
        }

        .buttons-area {
            text-align: center;
            direction: rtl;
        }

        .select-transparent {
            input {
                margin: 0;
            }
        }
    }
}

.ui-modal-root {
    .confirmBox {
        .isRequired {
            color: red;
            bottom: 6px;
            position: relative;
        }

        .confirmBox-container {
            padding: 0 5px;

            .introduction {
                overflow-x: hidden;

                @include themify() {
                    background-color: themed("introduction-bg-color") !important;
                }

                border: unset !important;
                margin-top: 10px;
                margin-bottom: 5px;
                word-wrap: break-word;

                &:focus {
                    border: 1px none !important;
                }

                &::-webkit-scrollbar-track {
                    margin: 20px !important;
                    left: 20px !important;
                }
            }

            .icon-delete {
                color: $red-delete;
            }
        }

        h2 {
            .icon {
                font-size: 20px;
                float: right;
                margin-left: 12px;
                color: $color-warning7;
                line-height: 32px;
                border: 2px solid;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                text-align: center;

                &:before {
                    margin: 0 !important;
                }
            }
        }

        h2 {
            @include themify() {
                color: themed("confirmbox-title-color");
            }
        }

        p {
            @include themify() {
                color: themed("confirmbox-description-color");
            }

            margin-bottom: 20px;
        }

        .button {
            min-width: 120px;
            padding: 0.3rem 1.2rem 0.4rem 1.2rem !important;
        }

        .buttons-area {
            text-align: center;
            direction: rtl;
        }

        .select-transparent {
            input {
                margin: 0;
            }
        }
    }
}

.content-container {
    text-align: center;
    width: auto;
    height: calc(100vh - 70px);
    overflow: auto;
    min-height: calc(100vh - 70px);
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
    font-size: $font-size-default !important;
}

// Results Menu in search Results
.resultsMenuItem {
    display: flex;
    padding: 16px 20px 16px 15px;
    cursor: pointer;
    position: relative;

    h4 {
        flex: 1;

        @include themify() {
            color: themed("sidebar-icon-title");
        }

        margin: 0;

        i {
            line-height: 2rem;
            font-size: 1.9rem;
            vertical-align: top;

            @include themify() {
                color: themed("result-sidebar-icon-color");
            }

            margin-left: 1px;
            display: inline-flex;
        }

        span {
            @include themify() {
                color: themed("result-sidebar-menu-color");
            }
        }

        .menu-subtitle {
            position: absolute;
            bottom: 3px;
            color: #44a3ff;
            border-radius: 17px;
            padding: 1px 7px;
            font-size: 9px;
            text-indent: 20px;
        }
    }

    span {
        @include themify() {
            color: themed("result-sidebar-number-color");
        }
    }

    &.selected {
        @include themify() {
            background-color: themed("sidebar-selected-bg-color");
        }

        position: relative;
        align-items: center;

        >span {
            font-weight: bold;

            @include themify() {
                color: themed("result-sidebar-number-selected-color");
            }
        }

        &:after {
            position: absolute;
            width: 6px;
            top: 0;
            bottom: 0;
            left: 0;

            @include themify() {
                background-color: themed("sidebar-selected-left-bg-color");
            }

            content: "";
        }
    }
}

/* width */
::-webkit-scrollbar {
    @include themify() {
        color: themed("scrollbar-bg-color") !important;
    }

    width: 4px !important;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color-secondary7 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    @include themify() {
        background: themed("scrollbar-thumb-bg-color") !important;
    }

    border-radius: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @include themify() {
        background: themed("scrollbar-hover-bg-color") !important;
    }
}

.ant-select-dropdown-menu-root {
    &::-webkit-scrollbar {
        width: 5px;
        margin-left: 10px;
        margin-bottom: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $scroll-track;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        @include themify() {
            background: themed("scrollbar-thumb-bg-color") !important;
        }

        border-radius: 10px;
    }
}

// .anticon {
//   svg {
//     display: none;
//   }
// }

.anticon-loading {
    svg {
        display: block;
    }

    &::before {
        display: none;
    }
}

.ant-select-selection--multiple {
    .ant-select-selection__choice__remove {
        svg {
            display: none !important;
        }
    }
}

.black-bg.pagination-wrapper {
    text-align: center;
    position: relative;
    z-index: 1;

    .pagination li.active a {
        color: #1d8cf8 !important;
    }

    .pagination li a,
    .jump-text {
        @include themify() {
            color: themed("pagination-number-color");
        }
    }

    .jump-input {
        background-color: transparent;
        height: 20px;
        width: 37px;
        border-radius: 10px;
        line-height: 20px;
        margin-top: 10px;
        color: $cyan;
        text-align: center;
    }
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.ant-layout-sider.ant-layout-sider-dark {
    .ant-layout-sider-children {
        .non-style.sidebar {
            li {

                a.active:after,
                a:hover:after {
                    border-radius: 0;
                    width: 6px;
                    left: 0px;

                    @include themify() {
                        background: themed("mini-sidebar-a-left-bg-color");
                    }
                }

                .popup {
                    display: none;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                }

                &:hover .popup {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 7px;
                    transform: translate(-98%, 0%);
                    height: 102%;
                    z-index: 99999;

                    @include themify() {
                        background: themed("mini-sidebar-a-bg-color") !important;
                    }

                    padding: 0 20px;
                    flex-direction: row;
                    align-items: center;
                    white-space: nowrap;
                    width: max-content;
                    font-size: $font-size-xlarg;

                    &:after {
                        position: absolute;
                        z-index: 100;
                        left: -6px;
                        border-radius: 0;
                        top: 0;
                        bottom: 0;
                        width: 6px;

                        @include themify() {
                            background: themed("mini-sidebar-a-left-bg-color");
                        }

                        content: "";
                    }

                    transition: all 0.3s;
                }

                &:hover a {
                    position: relative;
                    width: auto;
                    z-index: 99999;

                    &:hover {
                        @include themify() {
                            background: themed("mini-sidebar-a-bg-color") !important;
                        }
                    }
                }
            }
        }
    }
}

// @media (max-width: 1440px) {
//   .ant-layout-sider.ant-layout-sider-dark {
//     .ant-layout-sider-children {
//       .non-style.sidebar {
//         li {
//           &:hover .popup {
//             right: 20px;
//             height: 102%;
//             line-height: 2.6;
//             font-size: $font-size-larg;
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 1080px) {
//   .ant-layout-sider.ant-layout-sider-dark {
//     .ant-layout-sider-children {
//       .non-style.sidebar {
//         li {
//           &:hover .popup {
//             right: 35px;
//             height: 102%;
//             line-height: 2.4;
//             font-size: $font-size-larg;
//           }
//         }
//       }
//     }
//   }
// }

.app-page-not-access {
    display: table;
    height: 100vh;
    width: 100%;
    background-color: $black-haze;

    .page-inner {
        display: table-cell;
        vertical-align: middle;
        padding: 0 100px;
    }
}

.app-section-not-access {
    .not-access-container {
        display: table;
        width: 100%;
        height: 100%;
        background-color: #ebecec;

        .page-inner {
            display: table-cell;
            vertical-align: middle;
            padding: 20px;

            .message-box-container {
                height: 100%;
                min-height: 250px;
            }
        }
    }
}

.app-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    z-index: 9999;
    color: #444;
    opacity: 1;
    transition-property: all;
    transition-delay: 1;
    transition-duration: 0.5;
    transition-timing-function: ease-in;

    .app-loading-main {
        display: flex;
        flex-direction: column;
    }

    &.hidden {
        opacity: 0;
        display: none;
    }
}

//******** offline mode ****//

.is-cache {
    .no-style:first-child {
        display: flex;

        .ant-col:first-child {
            flex: 1;
        }
    }
}

@media (max-width: 1600px) {
    .full-height {
        &::after {
            width: 400%;
        }
    }
}

@media (max-width: 1440px) {
    .logo-container {
        padding: 0 10px;

        // height: 70px;
        img {
            width: auto;
            // height: 60px;
        }

        .brand {
            font-size: $font-size-32larg;
            margin-right: 15px;
        }
    }
}

@media (max-width: 1080px) {
    .logo-container {
        padding: 0 10px;

        // height: 70px;
        img {
            width: auto;
            // height: 50px;
        }

        .brand {
            font-size: $font-size-26larg;
            margin-right: 15px;
        }
    }
}

.g2-tooltip {
    background-color: rgba(38, 58, 100, 0.7) !important;
    color: #fff !important;
    box-shadow: none !important;

    .g2-tooltip-value {
        float: none !important;
        margin-left: 0 !important;
        margin-right: 30px;
    }

    span.g2-tooltip-marker {
        margin-right: 0 !important;
        margin-left: 8px !important;
        background-color: $color-secondary6;
    }
}

.ant-select-selection--multiple:before {
    /* content: ''; */
    display: flex;
    content: "\E61D";
    font-family: "anticon" !important;
    position: absolute;
    left: 15px;
    top: 0;
    align-items: center;
    height: 100%;
}

.inner-menu.defaults-menu {
    min-height: calc(100vh - 60px) !important;

    @include themify() {
        border-left: 1px solid themed("sidebar-border-left-color");
    }

    li {
        a {
            @include themify() {
                color: themed("sidebar-title-color") !important;
            }
        }
    }
}

.button.circle-button {
    @include themify() {
        color: themed("box-header-btn-color");
        border-color: themed("box-header-btn-color");
    }
}

.samane-box {
    @include themify() {
        border: 1px solid themed("box-header-border-color");
    }

    &.box-large {
        &:hover .button.circle-button {
            @include themify() {
                color: themed("box-header-btn-color-hover");
                border-color: themed("box-header-btn-color-hover");
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    @include themify() {
        -webkit-box-shadow: 0 0 0 30px themed("login-input-bg-color") inset !important;
        -webkit-text-fill-color: 0 0 0 30px themed("login-input-color") !important;
    }
}

input:focus {
    box-shadow: 0 0 3px white !important;
}

.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    margin-right: 30px !important;

    .assign-item-info {
        color: #472F2F;
        display: flex;
        padding-left: 100%;

        .department-name {
            color: #757575;
        }
    }

    &:hover {
        .assign-item-info {
            animation: marquee 5s linear infinite;
        }
    }
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@include fadeIns();

// در صورت کوچک بودن اسکرین در شناسنامه ها این باکس اخطار نمایش داده می‌شود
.forbidden-mobile-cover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background: #14858ae0;
    z-index: 20;
    color: #fff;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;

    .text {
        color: #fff;
    }
}

@media (max-width: 1100px) {
    .forbidden-mobile-cover {
        display: flex;
    }
}

.wrapper-assign-meun {
    background-color: #ffffff;
    box-shadow: 0px 0px 1px 2px #eceff1;
    width: 260px !important;
    height: 260px !important;
    max-height: 260px !important;
    overflow: hidden !important;

    .ant-menu {
        max-height: 210px !important;
    }

    .ant-menu-item {
        border-radius: 7px;
        padding: 0 5px !important;

        &:hover {
            background-color: #f4f4f4;
        }

        &.active {
            background-color: #ebf5ff;
        }

        .assign-item-img {
            border-radius: 50%;
        }

        .marquee.assign-item-text {
            margin-right: 7px !important;
        }
    }
}