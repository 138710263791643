@import "./../../assets/css/base/variables";
@import "./../../assets/css/base/mixins";

.new-version-bar {
    min-height: 56px;
    background-color: #199da3;
    width: 100%;
    display: flex;
    line-height: 56px;

    &-close-container {
        display: flex;

        &-icon {
            border: 2px solid #eeeeee;
            border-radius: 5px;
            padding: 10px;
            width: 36px;
            height: 36px;
            display: flex;
            margin: auto 32px;
            cursor: pointer;

            .icon {
                margin: auto;
                display: flex;
                color: white;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-title {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: white;
        }

        &-actions {
            display: flex;
            padding-left: 50px;
            align-items: center;

            button {
                padding: 15px 45px !important;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}
