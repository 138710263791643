@charset "UTF-8";

@font-face {
  font-family: "fontello";
  src: url("./../../fonts/icon/fontello.eot");
  src: url("./../../fonts/icon/fontello.eot?#iefix") format("embedded-opentype"),
    url("./../../fonts/icon/fontello.woff") format("woff"),
    url("./../../fonts/icon/fontello.ttf") format("truetype"),
    url("./../../fonts/icon/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  //margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-exclamation:before { content: '\e800'; } /* '' */
.icon-export:before { content: '\e801'; } /* '' */
.icon-eye:before { content: '\e802'; } /* '' */
.icon-facebook-post:before { content: '\e803'; } /* '' */
.icon-facebook-users:before { content: '\e804'; } /* '' */
.icon-filter:before { content: '\e805'; } /* '' */
.icon-add:before { content: '\e806'; } /* '' */
.icon-add-dashbord:before { content: '\e807'; } /* '' */
.icon-advertisment:before { content: '\e808'; } /* '' */
.icon-alarm:before { content: '\e809'; } /* '' */
.icon-arrow-down:before { content: '\e80a'; } /* '' */
.icon-arrow-left:before { content: '\e80b'; } /* '' */
.icon-arrow-right:before { content: '\e80c'; } /* '' */
.icon-arrow-up:before { content: '\e80d'; } /* '' */
.icon-bar-chart:before { content: '\e80e'; } /* '' */
.icon-blog-post:before { content: '\e80f'; } /* '' */
.icon-boltan:before { content: '\e810'; } /* '' */
.icon-box-list:before { content: '\e811'; } /* '' */
.icon-box-list-full:before { content: '\e812'; } /* '' */
.icon-calendar:before { content: '\e813'; } /* '' */
.icon-camera-icon:before { content: '\e814'; } /* '' */
.icon-channel-icon:before { content: '\e815'; } /* '' */
.icon-checkbox-active:before { content: '\e816'; } /* '' */
.icon-clock:before { content: '\e817'; } /* '' */
.icon-close-icon:before { content: '\e818'; } /* '' */
.icon-comment-icon:before { content: '\e819'; } /* '' */
.icon-comparison:before { content: '\e81a'; } /* '' */
.icon-copy:before { content: '\e81b'; } /* '' */
.icon-delete:before { content: '\e81c'; } /* '' */
.icon-diagram:before { content: '\e81d'; } /* '' */
.icon-dumper-post:before { content: '\e81e'; } /* '' */
.icon-dunro-post:before { content: '\e81f'; } /* '' */
.icon-edit:before { content: '\e820'; } /* '' */
.icon-excel:before { content: '\e821'; } /* '' */
.icon-facebook:before { content: '\e822'; } /* '' */
.icon-facebook-group:before { content: '\e823'; } /* '' */
.icon-facebook-group-post:before { content: '\e824'; } /* '' */
.icon-folders:before { content: '\e825'; } /* '' */
.icon-follower:before { content: '\e826'; } /* '' */
.icon-gauge:before { content: '\e827'; } /* '' */
.icon-male-1:before { content: '\e828'; } /* '' */
.icon-influence-1:before { content: '\e829'; } /* '' */
.icon-other:before { content: '\e82a'; } /* '' */
.icon-info:before { content: '\e82b'; } /* '' */
.icon-instagram:before { content: '\e82c'; } /* '' */
.icon-instagram-post:before { content: '\e82d'; } /* '' */
.icon-instagram-users:before { content: '\e82e'; } /* '' */
.icon-ketab-aval-post:before { content: '\e82f'; } /* '' */
.icon-language:before { content: '\e830'; } /* '' */
.icon-like:before { content: '\e831'; } /* '' */
.icon-line-state:before { content: '\e832'; } /* '' */
.icon-link:before { content: '\e833'; } /* '' */
.icon-list:before { content: '\e834'; } /* '' */
.icon-list-file:before { content: '\e835'; } /* '' */
.icon-list-full:before { content: '\e836'; } /* '' */
.icon-media-count:before { content: '\e837'; } /* '' */
.icon-microphone:before { content: '\e838'; } /* '' */
.icon-more:before { content: '\e839'; } /* '' */
.icon-more-2:before { content: '\e83a'; } /* '' */
.icon-new:before { content: '\e83b'; } /* '' */
.icon-news-post:before { content: '\e83c'; } /* '' */
.icon-oficial-news-post:before { content: '\e83d'; } /* '' */
.icon-pasword-icon:before { content: '\e83e'; } /* '' */
.icon-pause:before { content: '\e83f'; } /* '' */
.icon-percentage:before { content: '\e840'; } /* '' */
.icon-post:before { content: '\e841'; } /* '' */
.icon-reload:before { content: '\e842'; } /* '' */
.icon-resources:before { content: '\e843'; } /* '' */
.icon-retweet:before { content: '\e844'; } /* '' */
.icon-rules:before { content: '\e845'; } /* '' */
.icon-setting:before { content: '\e846'; } /* '' */
.icon-share:before { content: '\e847'; } /* '' */
.icon-sign-out:before { content: '\e848'; } /* '' */
.icon-speaker:before { content: '\e849'; } /* '' */
.icon-star:before { content: '\e84a'; } /* '' */
.icon-table:before { content: '\e84b'; } /* '' */
.icon-tag:before { content: '\e84c'; } /* '' */
.icon-telegram:before { content: '\e84d'; } /* '' */
.icon-telegram-channel:before { content: '\e84e'; } /* '' */
.icon-telegram-group:before { content: '\e84f'; } /* '' */
.icon-telegram-group-post:before { content: '\e850'; } /* '' */
.icon-telegram-post:before { content: '\e851'; } /* '' */
.icon-telegram-users:before { content: '\e852'; } /* '' */
.icon-twitter:before { content: '\e853'; } /* '' */
.icon-twitter-post:before { content: '\e854'; } /* '' */
.icon-twitter-users:before { content: '\e855'; } /* '' */
.icon-user:before { content: '\e856'; } /* '' */
.icon-user-icon:before { content: '\e857'; } /* '' */
.icon-video:before { content: '\e858'; } /* '' */
.icon-warning:before { content: '\e859'; } /* '' */
.icon-wikileaks:before { content: '\e85a'; } /* '' */
.icon-word-key:before { content: '\e85b'; } /* '' */
.icon-search:before { content: '\e85c'; } /* '' */
.icon-search-result:before { content: '\e85d'; } /* '' */
.icon-home:before { content: '\e85e'; } /* '' */
.icon-newspaper:before { content: '\e85f'; } /* '' */
.icon-list-not-full:before { content: '\e860'; } /* '' */
.icon-location:before { content: '\e861'; } /* '' */
.icon-map:before { content: '\e862'; } /* '' */
.icon-mark:before { content: '\e863'; } /* '' */
.icon-mention:before { content: '\e864'; } /* '' */
.icon-mines:before { content: '\e865'; } /* '' */
.icon-mini-box-list:before { content: '\e866'; } /* '' */
.icon-mini-box-release:before { content: '\e867'; } /* '' */
.icon-mobile:before { content: '\e868'; } /* '' */
.icon-move:before { content: '\e869'; } /* '' */
.icon-national-code:before { content: '\e86a'; } /* '' */
.icon-news-group:before { content: '\e86b'; } /* '' */
.icon-others:before { content: '\e86c'; } /* '' */
.icon-pdf:before { content: '\e86d'; } /* '' */
.icon-pdf-export:before { content: '\e86e'; } /* '' */
.icon-pdf-no-picture:before { content: '\e86f'; } /* '' */
.icon-phone:before { content: '\e870'; } /* '' */
.icon-pin:before { content: '\e871'; } /* '' */
.icon-post-1:before { content: '\e872'; } /* '' */
.icon-postal-code:before { content: '\e873'; } /* '' */
.icon-print:before { content: '\e874'; } /* '' */
.icon-search-engine:before { content: '\e875'; } /* '' */
.icon-selesct:before { content: '\e876'; } /* '' */
.icon-signed-out-graph:before { content: '\e877'; } /* '' */
.icon-signed-in-graph:before { content: '\e878'; } /* '' */
.icon-skills:before { content: '\e879'; } /* '' */
.icon-slide:before { content: '\e87a'; } /* '' */
.icon-tag-graph:before { content: '\e87b'; } /* '' */
.icon-targeted-collection:before { content: '\e87c'; } /* '' */
.icon-two-nodes-connection:before { content: '\e87d'; } /* '' */
.icon-url:before { content: '\e87e'; } /* '' */
.icon-web:before { content: '\e87f'; } /* '' */
.icon-website:before { content: '\e880'; } /* '' */
.icon-xls:before { content: '\e881'; } /* '' */
.icon-add-1:before { content: '\e882'; } /* '' */
.icon-alexa-rating:before { content: '\e883'; } /* '' */
.icon-alexa-rating-iran:before { content: '\e884'; } /* '' */
.icon-all:before { content: '\e885'; } /* '' */
.icon-analyze:before { content: '\e886'; } /* '' */
.icon-analyzer:before { content: '\e887'; } /* '' */
.icon-archive:before { content: '\e888'; } /* '' */
.icon-attache-nod:before { content: '\e889'; } /* '' */
.icon-audio:before { content: '\e88a'; } /* '' */
.icon-box-content:before { content: '\e88b'; } /* '' */
.icon-box-tab:before { content: '\e88c'; } /* '' */
.icon-comment-to-like:before { content: '\e88d'; } /* '' */
.icon-connection:before { content: '\e88e'; } /* '' */
.icon-csv:before { content: '\e88f'; } /* '' */
.icon-email:before { content: '\e890'; } /* '' */
.icon-file:before { content: '\e891'; } /* '' */
.icon-gif:before { content: '\e892'; } /* '' */
.icon-flag:before { content: '\e893'; } /* '' */
.icon-graph:before { content: '\e894'; } /* '' */
.icon-hashtag:before { content: '\e895'; } /* '' */
.icon-img:before { content: '\e896'; } /* '' */
.icon-interests:before { content: '\e897'; } /* '' */
.icon-ip:before { content: '\e898'; } /* '' */
.icon-language-1:before { content: '\e899'; } /* '' */
.icon-like-to-comment:before { content: '\e89a'; } /* '' */
.icon-profile:before { content: '\e89b'; } /* '' */
.icon-wide:before { content: '\e89c'; } /* '' */
.icon-mini-box-tag:before { content: '\e89d'; } /* '' */
.icon-mini-box-statistics:before { content: '\e89e'; } /* '' */
.icon-news-station:before { content: '\e89f'; } /* '' */
.icon-linkedin-user:before { content: '\e8a0'; } /* '' */
.icon-robot:before { content: '\e8a1'; } /* '' */
.icon-linkedin-post:before { content: '\e8a2'; } /* '' */
.icon-kiosk-box:before { content: '\e8a3'; } /* '' */
.icon-linkedin-group:before { content: '\e8a4'; } /* '' */
.icon-linkedin-job:before { content: '\e8a5'; } /* '' */
.icon-google-plus-user:before { content: '\e8a6'; } /* '' */
.icon-google-plus-post:before { content: '\e8a7'; } /* '' */
.icon-total-news:before { content: '\e8a8'; } /* '' */
.icon-news-agency:before { content: '\e8a9'; } /* '' */
.icon-newspaper-1:before { content: '\e8aa'; } /* '' */
.icon-news-source:before { content: '\e8ab'; } /* '' */
.icon-forwarded-posts-telegram:before { content: '\e8ac'; } /* '' */
.icon-text-posts-telegram:before { content: '\e8ad'; } /* '' */
.icon-one-post:before { content: '\e8ae'; } /* '' */
.icon-submit-comment:before { content: '\e8af'; } /* '' */
.icon-video-posts:before { content: '\e8b0'; } /* '' */
.icon-whois:before { content: '\e8b1'; } /* '' */
.icon-slide-posts:before { content: '\e8b2'; } /* '' */
.icon-image-posts:before { content: '\e8b3'; } /* '' */
.icon-views-telegram:before { content: '\e8b4'; } /* '' */
.icon-channels-telegram:before { content: '\e8b5'; } /* '' */
.icon-post-comment:before { content: '\e8b6'; } /* '' */
.icon-total-posts:before { content: '\e8b7'; } /* '' */
.icon-word-export:before { content: '\e8b8'; } /* '' */
.icon-checkbox-tick:before { content: '\e8b9'; } /* '' */
.icon-assign:before { content: '\e8ba'; } /* '' */
.icon-status:before { content: '\e8bb'; } /* '' */
.icon-all-in-one:before { content: '\e8bc'; } /* '' */
.icon-back:before { content: '\e8bd'; } /* '' */
.icon-instagram-direct:before { content: '\e8be'; } /* '' */
.icon-download:before { content: '\e8bf'; } /* '' */
.icon-category:before { content: '\e8c0'; } /* '' */
.icon-attach:before { content: '\e8c1'; } /* '' */
.icon-live:before { content: '\e8c2'; } /* '' */
.icon-done:before { content: '\e8c3'; } /* '' */
.icon-no-nead:before { content: '\e8c4'; } /* '' */
.icon-pending:before { content: '\e8c5'; } /* '' */
.icon-replyed:before { content: '\e8c6'; } /* '' */
.icon-emoji:before { content: '\e8c7'; } /* '' */
.icon-like-1:before { content: '\e8c8'; } /* '' */
.icon-liked:before { content: '\e8c9'; } /* '' */
.icon-quote:before { content: '\e8ca'; } /* '' */
.icon-reply:before { content: '\e8cb'; } /* '' */
.icon-search-1:before { content: '\e8cc'; } /* '' */
.icon-send:before { content: '\e8cd'; } /* '' */
.icon-sort:before { content: '\e8ce'; } /* '' */
.icon-tweet:before { content: '\e8cf'; } /* '' */
.icon-instagram-mention:before { content: '\e8d0'; } /* '' */
.icon-instagram-post-1:before { content: '\e8d1'; } /* '' */
.icon-instagram-search:before { content: '\e8d2'; } /* '' */
.icon-instagram-home:before { content: '\e8d3'; } /* '' */
.icon-twitter-tweet:before { content: '\e8d4'; } /* '' */
.icon-twitter-direct:before { content: '\e8d5'; } /* '' */
.icon-twitter-home:before { content: '\e8d6'; } /* '' */
.icon-twitter-mention:before { content: '\e8d7'; } /* '' */
.icon-twitter-retweet:before { content: '\e8d8'; } /* '' */
.icon-twitter-search:before { content: '\e8d9'; } /* '' */
.icon-analytic:before { content: '\e8da'; } /* '' */
.icon-report:before { content: '\e8db'; } /* '' */
.icon-history:before { content: '\e8dc'; } /* '' */
.icon-channel-phone:before { content: '\e8dd'; } /* '' */
.icon-back-step-1:before { content: '\e8de'; } /* '' */
.icon-upload:before { content: '\e8df'; } /* '' */
.icon-mark-1:before { content: '\e8e0'; } /* '' */
.icon-source-tag:before { content: '\e8e1'; } /* '' */
.icon-negative:before { content: '\e8e2'; } /* '' */
.icon-neutral:before { content: '\e8e3'; } /* '' */
.icon-tools:before { content: '\e8e4'; } /* '' */
.icon-positive:before { content: '\e8e5'; } /* '' */
.icon-female-1:before { content: '\e8e6'; } /* '' */
.icon-mute:before { content: '\e8e7'; } /* '' */
.icon-unmute:before { content: '\e8e8'; } /* '' */
.icon-block:before { content: '\e8e9'; } /* '' */
.icon-shared-by-me:before { content: '\e8ea'; } /* '' */
.icon-shared-to-me:before { content: '\e8eb'; } /* '' */
.icon-support:before { content: '\e8ec'; } /* '' */
.icon-360:before { content: '\e8ed'; } /* '' */
.icon-color-picker:before { content: '\e8ee'; } /* '' */
.icon-content-tag:before { content: '\e8ef'; } /* '' */
.icon-unknown-1:before { content: '\e8f0'; } /* '' */
.icon-tick-circle:before { content: '\e8f1'; } /* '' */
.icon-new-post:before { content: '\e8f2'; } /* '' */
.icon-hierarchy:before { content: '\e8f3'; } /* '' */
.icon-import:before { content: '\e8f4'; } /* '' */
.icon-influence:before { content: '\e8f5'; } /* '' */
.icon-hate:before { content: '\e8f6'; } /* '' */
.icon-laugh:before { content: '\e8f7'; } /* '' */
.icon-conversation-close:before { content: '\e8f8'; } /* '' */
.icon-sad:before { content: '\e8f9'; } /* '' */
.icon-like-2:before { content: '\e8fa'; } /* '' */
.icon-fake-user:before { content: '\e8fb'; } /* '' */
.icon-engagement:before { content: '\e8fc'; } /* '' */
.icon-middle-aged:before { content: '\e8fd'; } /* '' */
.icon-old:before { content: '\e8fe'; } /* '' */
.icon-teenager:before { content: '\e8ff'; } /* '' */
.icon-young:before { content: '\e900'; } /* '' */
.icon-impression:before { content: '\e902'; } /* '' */
.icon-unassigned-1:before { content: '\e903'; } /* '' */
.icon-conversation-see:before { content: '\e904'; } /* '' */
.icon-new-direct:before { content: '\e905'; } /* '' */
.icon-twitter-wall:before { content: '\e906'; } /* '' */
.icon-direct-wall:before { content: '\e907'; } /* '' */
.icon-instagram-wall:before { content: '\e908'; } /* '' */
.icon-advertise:before { content: '\e909'; } /* '' */
.icon-chart:before { content: '\e90a'; } /* '' */
.icon-neutral-1:before { content: '\e90b'; } /* '' */
.icon-pie-chart:before { content: '\e90c'; } /* '' */
.icon-positive-1:before { content: '\e90d'; } /* '' */
.icon-table-1:before { content: '\e90e'; } /* '' */
.icon-trend:before { content: '\e90f'; } /* '' */
.icon-cloud:before { content: '\e910'; } /* '' */
.icon-first-source:before { content: '\e911'; } /* '' */
.icon-follower-1:before { content: '\e912'; } /* '' */
.icon-growth-chart:before { content: '\e913'; } /* '' */
.icon-guide:before { content: '\e914'; } /* '' */
.icon-influencer:before { content: '\e915'; } /* '' */
.icon-negative-1:before { content: '\e916'; } /* '' */
.icon-cover-1:before { content: '\e918'; } /* '' */
.icon-alarm-side:before { content: '\e93a'; } /* '' */