.mb-tags{
    
    display: grid;
    border-radius: 10px;
    background-color: $color-primary12;
    .header{
        border-radius: 10px 10px 0 0;
        padding:10px;
        background-color: $mirage;
        color: $white  ;
        .title{
            text-align: right;  
            
                padding-right: 10px;
            
        }
        .icon{
            text-align: left;
            padding: 5px;
        }
    }

    .main{
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-flow: wrap;
        .tag{
            color: $chambray !important;
            margin-left: 10px;
            margin-bottom: 5px;
        }
    }


}
