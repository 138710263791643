@import "./../../../assets/css/base/variables";
@import "./../../../assets/css/base/mixins";
.tag-content {
    .ant-select {
        .ant-select-search__field__placeholder {
            text-align: right;
        }
        .ant-select-selection--multiple {
            min-height: 41px;

            .ant-select-selection__placeholder {
                color: #b5b5b5;
                margin-top: -7px;
            }
            .ant-select-selection__rendered {
                ul {
                    .ant-select-selection__choice {
                        height: 32px;
                        background-color: #eceff1 !important;
                        line-height: unset;
                        border-radius: 16px;
                        padding: 0px 10px 0 20px !important;
                        color: #2f3447;
                        .select-with-circle-img-item {
                            img {
                                width: 25px;
                                height: 25px;
                                position: relative;
                                &.platform-badge {
                                    background-color: green;
                                    position: absolute;
                                    border: 1px solid green;
                                    border-radius: 50%;
                                    width: 12px;
                                    height: 12px;
                                    right: 25px;
                                    bottom: 2px;
                                    text-align: center;
                                }
                            }
                        }
                        .ant-select-selection__choice__remove {
                            left: 0;
                            svg {
                                display: inline !important;
                                color: #9e9e9e;
                            }
                        }
                    }
                }
            }
        }
    }
}

li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
    @include themify() {
        color: themed("tag-content-multiselect-tag-category-color") !important;
    }
    &.ant-select-tree-title {
        color: gray !important;
    }
}
.ant-select-selection--multiple {
    .ant-select-selection__choice {
        padding: 3px 10px 3px 20px !important;
        height: 30px;
        line-height: 24px;
        border-radius: 30px !important;
        border-color: transparent !important;
        @include themify() {
            color: themed("tag-content-multiselect-tag-color");
            background-color: themed("tag-content-multiselect-tag-bg-color") !important;
        }
    }
    .ant-select-selection__choice__remove {
        svg {
            display: inline !important;
            color: #9e9e9e;
        }
    }
}

.ant-select-tree-dropdown.ant-select-dropdown--multiple.ant-select-dropdown-placement-bottomLeft {
    max-height: 300px !important;
}

.ui-modal.filters-modal.white-modal .ant-select-selection {
    height: auto;
    min-height: 38px;
}
