@import "./../../../assets/css/base/variables";
@import "./../../../assets/css/base/mixins";
.custom-collapse {
    .header {
        .ui-collapse-header {
            width: 100% !important;
            padding: 10px 0 10px 10px !important;
            &:after {
                left: 30px !important;
                right: 115px !important;
            }
        }
        .ui-collapse-body {
            padding: unset !important;
            border-right: unset !important;
            margin-right: unset !important;
        }

        // display: flex;
        // justify-content: space-between;
        // position: relative;
        // &:after {
        //     position: absolute;
        //     content: "";
        //     left: 20px;
        //     right: 110px;
        //     @include themify() {
        //         border: 1px solid themed('planning-alert-box-collapse-header-color');
        //         border-style: dashed;
        //         opacity: .5;
        //     }

        //     top: 10px;
        // }
    }
}
