.ui-modal-root {

    .ui-modal {
        @include themify() {
            color: themed("pagination-color");
        }
    }

    .ui-modal-close {
        left: 0;
        right: auto;
    }

    .ui-modal-footer {
        text-align: center;

        button.ant-btn {
            margin-left: 10px;
        }
    }

    .leftPart {
        .ui-collapse {
            border: 0 none;
        }

        .ui-collapse-content {
            margin-right: 0;
        }

        .ui-collapse-item {
            border: 0 transparent;
        }

        .ui-collapse-item-active {
            .ui-collapse-content-active {
                border-right: 1px solid $disabled-button-color;
            }
        }
    }


    // برای مودال های سفیدرنگ مثل صفحه ریزالت سرچ تنظیمانت نمایشی پلتفرمها تغییر میکند
    &.white-modal {
        .custom-date-picker-form-item .custom-input-datepicker input.JDinput {
            color: black !important;
        }

        .ui-collapse-header {
            font-size: $font-size-larg !important;

            i {
                color: black !important;
            }
        }

        .search-inner-collapse {
            margin-right: 0;
            width: auto;

            .ui-collapse-content,
            .ant-spin-container {
                margin-right: 0;

                .ant-row {
                    display: flex;

                    &>div {
                        margin: auto 0 auto;
                    }
                }

                .ui-collapse-content-box {
                    min-height: 90px;
                }
            }

            .JDinput {
                color: inherit;
            }
        }

        &.excell-modal {
            .ui-collapse-content {
                &.ui-collapse-content-active {
                    overflow: visible;
                }
            }

            .ui-modal-body {
                margin: 20px;

                .ant-row {
                    .ant-input {
                        color: $black !important;
                    }
                }

                .ui-collapse-content {
                    font-size: $font-size-medium !important;

                    .ant-row,
                    .ant-row-flex {
                        margin-top: 10px;
                    }
                }
            }
        }

        .css-10nd86i {

            // select
            &.multi {
                >div {
                    div:first-child {
                        .css-xwjg1b {
                            background-color: $gray-light;
                            border-radius: 10px;
                            overflow: hidden;

                            .css-1alnv5e {
                                padding: 0 0px 0 7px;

                                &:hover {
                                    background-color: transparent;

                                    svg {
                                        fill: $pink;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.filters-modal {
        .ant-checkbox-wrapper {
            color: rgba(0, 0, 0, 0.65) !important;
            font-size: 14px !important;
            //   min-width: 100px;
        }

        .filters-modal-content {
            .css-1hwfws3 {

                .css-es53b3-multiValue,
                .css-151xaom-placeholder {
                    background-color: transparent !important;

                    div {
                        background-color: transparent !important;
                    }
                }
            }

            min-height: 90px;
            padding: 25px;

            .ant-row-flex {
                margin: 15px 0;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                box-shadow: 0 0 0 30px $white inset !important;
            }

            input:-webkit-autofill {
                -webkit-text-fill-color: $scroll-track !important;
            }

            label.ant-checkbox-wrapper {
                color: #000000a6 !important;
                font-size: 14px !important;
                font-family: IranSans;
            }

            .rangeslider-field {
                .ant-input-affix-wrapper {
                    z-index: 0;

                    input {
                        color: $black !important;
                        padding-left: 11px;
                        padding-right: 20px;
                    }

                    .ant-input-prefix {
                        right: 5px;
                    }
                }
            }
        }
    }

    &.filters-modal.white-modal {
        .create-query-content {
            a.action {
                button {
                    margin-right: 25px;
                }

                &:hover,
                &:focus,
                &:active {
                    border-bottom: none;
                }
            }
        }

        .custom-query-filed-container {
            .parsed-query {
                color: #000000;
            }
        }

        input.ant-input-number-input,
        input[type="number"],
        .ant-select-selection {
            background: $white !important;
            // color: inherit !important;
            color: #191d29;
            position: relative;
            z-index: 0;
        }

        .ant-select-selection {
            height: 38px;

            .ant-select-selection__rendered {
                line-height: 37px;
            }
        }

        .ant-select-selection__clear {
            i {
                display: none;
            }
        }

        .ant-select-arrow-icon {
            color: $mirage !important;
            font-weight: bolder;
        }

        .ant-select-dropdown {
            background: $white !important;

            li {
                border-bottom: none;
                color: $mirage !important;
            }
        }

        .ant-select-selection--multiple {
            .ant-select-selection__clear {
                background: inherit !important;

                &:before {
                    background: inherit !important;
                }
            }
        }
    }
}

.custom-query-filed-container {
    &.block-style {
        .create-query-container {
            margin-top: 20px;
        }
    }
}

.modal-shadow {
    .ui-modal-content {
        @include boxShadow(0, 0, 6px, $disabled-btn);
    }
}

.search-setting {
    .ui-collapse {
        background: transparent;
        border: 1px solid transparent;
    }

    .ui-collapse-header {
        border-bottom: 1px solid transparent;
    }

    .ui-collapse-content {
        background: transparent;
        border-top: 1px solid transparent;
    }

    .ui-collapse-item {
        background: transparent;
        border: 1px solid transparent;
    }
}