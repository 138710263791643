$themes: (
    light: (
        share-query-description-color: #000000,
    ),
    dark: (
        share-query-description-color: #d1d1d1,
    ),
);

@import "./../../../../assets/css/base/theme";

.share-query {
    .share-query-title {
        font-size: 12px;
        @include themify() {
            color: themed("share-query-description-color");
        }
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .share-query-errors {
        color: red;
        padding: 10px;
    }
    .scroll {
        .ant-checkbox-group {
            width: 100%;
        }
        .ui-collapse-body {
            padding-top: 0;
            .item {
                margin: 10px 0;
                padding-right: 20px;
            }
        }
    }
}
