/********* Random Background Colors *******/
.rand-bg-0 {
    background-color: $red;
    color: $white;
}

.rand-bg-1 {
    background-color: $color-warning7;
    color: $white;
}

.rand-bg-2 {
    background-color: $cyan;
    color: $white;
}

.rand-bg-3 {
    background-color: $fountain-blue;
    color: $white;
}

.rand-bg-4 {
    background-color: $spring-green;
    color: $white;
}

.rand-bg-5 {
    background-color: $torch-red;
    color: $white;
}

.rand-bg-6 {
    background-color: $chambray;
    color: $white;
}

.rand-bg-7 {
    background-color: $purple;
    color: $white;
}

.rand-bg-8 {
    background-color: $pink;
    color: $white;
}

.rand-bg-9 {
    background-color: $blue-purple;
    color: $white;
}

.rand-bg-10 {
    background-color: $amber;
    color: $white;
}

/******************************************/
#root {
    user-select: none;
}

.little-account-list {
    border-radius: 0 0 5px 5px !important;

    .ant-select-dropdown-menu-root {
        // ul
        .ant-select-dropdown-menu-item {
            // li
            font-size: 12px !important;
            padding: 4px 2px 4px 2px !important;
            margin: 0;

            .avatar {
                border-radius: 50%;
            }

            &.ant-select-dropdown-menu-item-active {
                // display: flex !important;
            }

            &.ant-select-dropdown-menu-item-selected {
                // display: flex;
                @include themify() {
                    background-color: themed("planning-header-little-account-list-menu-bg") !important;
                }
            }

            .anticon-check {
                display: flex !important;
                left: unset;
                right: -3px !important;
                height: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.fullpage-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #ffffff3b;
    position: absolute;
    bottom: 0;
    top: 0;
}

.container {
    padding: 15px;
}

.brand {
    font-size: $font-size-36larg;
    margin-right: 15px;
    //padding-right: 75px;
    background: url("./../../img/logo.png") no-repeat scroll right top / 75px 60px;
}

.inner-menu {
    @include themify() {
        background-color: themed("sidebar-bg-color");
    }

    height: 100%;
    padding: 0 15px;
}

.inner-menu-title {
    font-size: $menu-font-size;

    @include themify() {
        color: themed("sidebar-title-color");
    }

    border-bottom: 1px solid $color-secondary7;
    text-align: center;
    padding: 15px 0;
    margin-right: 10px;
    margin-left: 10px;
}

.icon-menu {
    color: $white;
    display: block;
}

.non-style {
    list-style: none;
    padding: 0;
    margin: 0;
}

.flex {
    display: flex;

    &.vertical-center {
        align-items: baseline;

        & > *:first-child {
            margin-left: 10px;
        }
    }

    .ant-select {
        width: 300px;
    }
}

.ant-select {
    .ant-select-selection__clear {
        opacity: 1;
        z-index: 0;

        &::before {
            // custom
            content: "\e818";
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            text-align: center;
            color: #757575 !important;
            transition: all in 0.2s linear;
        }

        .anticon-close-circle {
            display: none;
        }

        &:hover {
            &::before {
                color: #9e9e9e !important;
            }
        }
    }
}

.txt-blue {
    color: $blue;
}

.txt-gray {
    color: $color-secondary7;
}

.fl-r {
    float: right;
}

.fl-l {
    float: left;
}

.flex-10 {
    flex-basis: 10%;
}

.flex-20 {
    flex-basis: 20%;
}

.flex-22 {
    flex-basis: 22%;
}

.flex-30 {
    flex-basis: 30%;
}

.flex-40 {
    flex-basis: 40%;
}

.flex-50 {
    flex-basis: 50%;
}

.flex-60 {
    flex-basis: 60%;
}

.flex-70 {
    flex-basis: 70%;
}

.flex-78 {
    flex-basis: 78%;
}

.flex-80 {
    flex-basis: 80%;
}

.flex-90 {
    flex-basis: 90%;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    &.icon-lg {
        font-size: 1.7rem;
        line-height: 1rem;
        vertical-align: middle;
        margin: 0 5px 0 0;
        display: inline-flex;
    }
}

.flex-1 {
    flex: 1;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.dir-rtl {
    direction: rtl;
}

.pt-35 {
    padding-top: 35px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.row-label {
    margin: 10px 0;
    display: inline-block;

    @include themify() {
        color: themed("row-label-color");
    }
}

.txt-red {
    color: $red;
}

//انواع دکمه های سامانه
.button {
    min-width: 200px;
    outline: none;
    position: relative;

    &.bolder {
        font-weight: bolder;
    }

    &.orange-gradient {
        border: 2px solid transparent;
        @include gradient($button-orange-gradient-start, $button-orange-gradient-end, horizontalRight);
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        @include boxShadow(0, 0, 0, transparent);
        cursor: pointer;
        position: relative;
        // width: 100% ;
        min-width: unset;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, $button-orange-filled);
        }

        i.anticon.anticon-loading {
            margin-right: -14px;
            padding-left: 10px;
            margin-left: auto !important;
        }
    }

    &.red-filled {
        border: 2px solid transparent;
        background-color: $red-delete;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        @include boxShadow(0, 0, 0, transparent);
        cursor: pointer;
        position: relative;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, $red-delete);
        }
    }

    &.orange-filled {
        border: 2px solid transparent;
        background-color: $button-orange-filled;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        @include boxShadow(0, 0, 0, transparent);
        cursor: pointer;
        position: relative;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, $button-orange-filled);
        }
    }

    &.orange-border {
        @include themify() {
            border: 2px solid themed("button-orange-filled-border-color");
        }

        background-color: transparent;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;

        @include themify() {
            color: themed("button-orange-filled-color");
        }

        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        cursor: pointer;
        position: relative;

        // width: 100%;
        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, $button-orange-border);
        }
    }

    &.blue-filled {
        border: 2px solid transparent;
        background-color: $main-color;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        position: relative;
        @include borderRadius(25px);
        cursor: pointer;
        @include boxShadow(0, 0, 0, transparent);

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, $main-color);
        }
    }

    &.white-border {
        @include themify() {
            border: 2px solid themed("search-sidebar-cancel-btn-border-color");
        }

        background-color: transparent;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;

        @include themify() {
            color: themed("button-cancel-color");
        }

        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        position: relative;
        cursor: pointer;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, rgba(167, 170, 179, 0.88));
        }
    }

    &.blue-border {
        border: 2px solid #1d8cf8;
        background-color: transparent;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;

        @include themify() {
            color: themed("button-blue-border-color");
        }

        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        position: relative;
        cursor: pointer;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 10px, rgba(167, 170, 179, 0.88));
        }
    }

    &.gray-border {
        @include themify() {
            border: 2px solid themed("box-classification");
        }

        background-color: transparent;
        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $color-secondary7;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        @include boxShadow(0, 0, 0, transparent);
        cursor: pointer;

        &:hover {
            outline: none;
            @include boxShadow(0, 0, 4px, $defaults-searchbar);
        }
    }

    &.disabled {
        @include themify() {
            border: 2px solid themed("disabled-color");
            background-color: themed("disabled-color");
        }

        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(25px);
        cursor: not-allowed;
        pointer-events: none;
    }

    &.grey-filled {
        @include themify() {
            border: 2px solid themed("disabled-button-bg-color");
            background-color: themed("disabled-button-bg-color");
        }

        padding: 0.6rem 1.2rem 0.6rem 1.2rem;
        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        cursor: pointer;
        @include borderRadius(25px);
    }

    &.button-default {
        @include themify() {
            border: 2px solid themed("button-default-border");
            background-color: themed("button-default-bg-color");
        }

        color: $white;
        line-height: 2em;
        margin: 7px 0 7px 20px;
        @include borderRadius(15px);
        cursor: pointer;
    }

    &.sidebar_bottom_button {
        position: absolute;
        right: 10%;
        width: 80%;
        height: 48px;
        bottom: 30px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.ant-btn-loading {
            position: absolute !important;
        }

        &:focus {
            text-decoration: none !important;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .plus,
            .icon {
                font-size: 22px;
            }
        }

        span {
            &:active {
                color: inherit;
                border: none;
            }
        }
    }

    &.sidebar_bottom_relative_button {
        position: absolute;
        right: 5%;
        width: 90%;
        height: 48px;
        bottom: 80px;
    }

    &.circle-button {
        min-width: auto;
        width: 20px;
        text-align: center;
        padding: 0;
        border-radius: 50%;
        line-height: 20px;
        height: 20px;
        margin: auto 3px;
        background: transparent;

        @include themify() {
            border-color: themed("button-circle-border");
        }

        &:hover {
            @include themify() {
                background: themed("button-circle-hover") !important;
            }
        }
    }

    &.inline-button {
        display: inline-block;
    }

    &.three-dot-button {
        width: 30px !important;
    }

    &.small-button {
        min-width: auto;
        padding: 0 0.2rem;
        margin-left: 0.3rem;
        width: 110px;
        margin-bottom: 5px;
        font-size: 1rem;
        border-width: 1px;
        margin-top: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 2.1rem;
        vertical-align: top;
    }

    &.medium-button {
        min-width: auto;
        padding: 0 0.2rem;
        margin-left: 0.3rem;
        width: 110px;
        margin-bottom: 5px;
        font-size: 1rem;
        margin-top: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 34px;
        vertical-align: top;
    }
}
//استایل پاپ اور
.popover {
    padding: 0;
    .ant-popover-inner-content {
        padding: 0;
    }
    .accountinfo {
        padding: 10px 0 0;
        display: flex;
        justify-content: space-between;
        button {
            border: 1px solid $main-color;
            color: $main-color;
        }
        &.large-button {
            font-size: 14px;
            font-weight: normal;
            line-height: 28px;
        }

        &.full-width {
            width: 100% !important;
        }

        &.notif-circular-button {
            width: 25px;
            min-width: auto;
            line-height: 20px;
            text-align: center;
            padding: 3px 0 0 0;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 50%;
            margin: 0 5px 0 0;
            cursor: pointer;

            &.icon_green {
                display: block;
                border-color: $noti-dl-compl;
                color: $noti-dl-compl;

                &:hover {
                    @include boxShadow(0, 0, 3px, lighten($noti-dl-compl, 20%));
                    color: lighten($noti-dl-compl, 20%);
                }
            }

            &.icon_red {
                border-color: $noti-dl-cancel;
                color: $noti-dl-cancel;

                &:hover {
                    @include boxShadow(0, 0, 3px, lighten($noti-dl-cancel, 20%));
                    color: lighten($noti-dl-cancel, 20%);
                }
            }

            &.icon_grey {
                border-color: $noti-bg;
                color: $noti-bg;

                &:hover {
                    @include boxShadow(0, 0, 3px, lighten($noti-bg, 20%));
                    color: lighten($noti-bg, 20%);
                }

                span {
                    display: inline-block;
                    transform: translateY(-2px);
                }
            }

            &.icon_blue {
                display: block;
                border-color: $noti-dl-reload;
                color: $noti-dl-reload;

                &:hover {
                    @include boxShadow(0, 0, 3px, lighten($noti-dl-reload, 20%));
                    color: lighten($noti-dl-reload, 20%);
                }
            }
        }

        &.center-align {
            text-align: center !important;
        }

        .loading {
            position: absolute;
            left: 12px;
            top: 12px;

            &.loading-light {
                .ant-spin-dot i {
                    background-color: #a0d5f9;
                }
            }
        }
    }
}

button.noborder-button {
    padding: 2px 10px;
    line-height: 20px;
    margin: 0 8px 0 2px;
    background: transparent;
    border-color: transparent;
    color: white;

    &:hover {
        color: white !important;
        border-color: white !important;
        background: transparent !important;
    }

    & i {
        vertical-align: middle;
        margin: 0 3px;
        font-size: 1.5rem;
    }

    &:focus,
    &:active {
        background: transparent !important;
        border-color: white;
    }
}

button.simpleButton {
    line-height: 1em;
    background: transparent;
    border: none;

    @include themify() {
        color: themed("simple-Button-color");
    }

    @include themify() {
        color: themed("simple-Button-border-color");
    }

    cursor: pointer;
    padding: 7px 12px 3px 12px;
    @include borderRadius(4px);
    @include transition(all 0.3s ease);
    margin-right: 20px;
    margin-bottom: 2px;

    &:hover,
    &:focus,
    &:active {
        // background: #555 !important;
        @include themify() {
            color: themed("simple-Button-color");
            border-color: themed("simple-Button-active-border-color");
        }

        box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 0.25);
    }

    & i {
        vertical-align: text-bottom;
        margin: 0 3px;
    }
}

.sidebar {
    &.inner-menu {
        padding: 10px 0 0;
    }

    .sidebar-list {
        li {
            line-height: 3.3rem;
            margin: 3px 0 0 0;
            position: relative;
            transition: all 0.4s;
            background: transparent;

            a {
                display: flex;
                justify-content: space-between;
                color: $white;

                &:hover,
                &:focus,
                &:active {
                    border-bottom: 1px solid transparent !important;
                    color: $white !important;
                    text-decoration: none;

                    span {
                        border-bottom: 0 none !important;

                        @include themify() {
                            color: themed("sidebar-inner-menu-hover-text-color") !important;
                        }
                    }
                }

                &.active,
                &:hover {
                    @include themify() {
                        background: themed("sidebar-inner-menu-hover-bg-color");
                    }

                    &:after {
                        position: absolute;
                        z-index: 100;
                        left: 0px;
                        top: 0;
                        bottom: 0;
                        width: 10px;

                        @include themify() {
                            background: themed("sidebar-inner-menu-after-bg-color");
                        }

                        content: "";
                    }
                }

                &:hover {
                    .remove-icon {
                        opacity: 1;
                    }
                }
            }

            span {
                font-size: 12px;
                margin: auto;
                margin-right: 15px;
                display: flex;
                align-items: center;

                .icon {
                    font-size: 25px;
                    display: flex;
                }
            }

            .remove-icon {
                margin-left: 10px;
                margin-right: auto;
                width: 40px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                opacity: 0;
                transition: all 0.2s linear;
            }
        }
    }

    a {
        &.sidebar_bottom_button {
            text-align: right;
            right: 7%;
            min-width: auto !important;
            width: 86%;
            margin: 0;

            .plus {
                font-size: $font-size-36larg;
                float: left;
                margin-top: 2px;
                display: flex;
                height: 100%;
                align-items: center;
            }
        }
    }
}

.whiteBackground {
    background: $white-backcolor;
}

// رپ کردن تصویر که توی هر سایزی عکس لود بشه و دفرمه نشه
.img-wrap {
    overflow: hidden;
    margin: 0;
    position: relative;
    width: 50px;
    height: 50px;

    &:after {
        content: "";
        display: block;
        padding-top: 57%;
    }

    img {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        margin: auto;
        height: auto;
        max-width: 120%;
        min-width: 100%;
        max-height: 120%;
        min-height: 100%;
        position: absolute;
    }
}

.hideMore {
    display: none;
    opacity: 0;
    text-align: justify;
    visibility: hidden;
    white-space: pre;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
}

.showMore {
    display: inline-block;
    visibility: visible;
    opacity: 1;
    text-align: justify;
    white-space: pre-line;
}

.data {
    .box-content-title {
        h3 {
            font-size: 1.1em;
            font-weight: bold;
            color: $main-color;
        }
    }

    .extacted {
        font-size: 13px;
        padding-top: 8px;

        h3 {
            font-size: 15px;
            font-weight: bold;
            color: $main-color;

            i {
            }
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .padding {
        padding: 8px 0 0 0;

        // margin: 10px 0 0 0;
        &:first-child {
            padding-top: 0;
            margin-top: 0;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: bold;
            color: $main-color;

            i {
                font-size: 0.6rem;
            }
        }
    }
}

.small {
    font-size: 1rem;
}

//باکس نمایش اطلاعات به کاربر مثل تعداد باکسهای انتخاب شده
.infobox {
    padding: 10px;
    text-align: center;

    @include themify() {
        color: themed("global-info-box-color");
    }

    margin: 20px;
    @include borderRadius(10px);

    &.error {
        @include themify() {
            background-color: themed("box-error-color");
        }
    }

    &.info {
        @include themify() {
            background-color: themed("global-info-box-bg-color");
        }
    }

    & i {
        font-size: 40px;
        margin: 20px;
    }
}

//pagination
.pagination-wrapper {
    margin-bottom: 25px;

    .pagination {
        margin: 0 0 20px 0;
        padding: 0;
        display: inline-block;
        list-style: none;
        font-size: 1.2em;

        li {
            display: inline-block;
            margin: 3px 7px 0 7px;
            vertical-align: top;

            a {
                @include themify() {
                    color: themed("pagination-number-color");
                }

                height: 18px;

                &:focus {
                    border-color: transparent !important;
                }
            }
        }

        .break {
            a {
            }
        }

        .active {
            pointer-events: none;

            &:focus {
                border-color: transparent !important;
            }

            a {
                color: #1d8cf8 !important; //$cyan;

                &:focus {
                    border-color: transparent !important;
                    display: contents;
                }
            }
        }

        .previous {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            position: relative;

            @include themify() {
                background-color: themed("previous-bg-color");
            }

            &:before {
                font-family: "fontello" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                -webkit-font-smoothing: antialiased;
                font-size: 10px;
                // custom
                content: "\e80c";
                color: $white;
            }

            a {
                display: block;
                border-radius: 50%;
                position: relative;
                z-index: 123;
                height: 100%;
            }
        }

        .next {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            position: relative;

            @include themify() {
                background-color: themed("next-bg-color");
            }

            &:before {
                font-family: "fontello" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                -webkit-font-smoothing: antialiased;
                font-size: 10px;
                // custom
                content: "\e80b";
                color: $white;
            }

            a {
                display: block;
                border-radius: 50%;
                position: relative;
                z-index: 123;
                height: 100%;
            }
        }
    }

    span {
        @include themify() {
            color: themed("pagination-title-color");
        }

        margin: 0 15px 0 7px;
    }

    .pagination_input {
        height: 26px;

        @include themify() {
            color: themed("pagination-input-number-color") !important;
        }

        width: 5rem;
        text-align: center;
        border-color: black;
        @include borderRadius(20px);
        vertical-align: -3px;
    }

    .ant-input {
        @include themify() {
            border-color: themed("pagination-input-border-color") !important;
        }

        &:active {
            box-shadow: #fff;
        }
    }
}

.popupInfo {
    @include themify() {
        background-color: themed("popupinfo-bg-color");
    }

    @include borderRadius(20px, 20px, 0, 0);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 20%;
    left: 20%;
    bottom: -40px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    z-index: 3;

    &.vis {
        opacity: 1;
        bottom: 0;
        pointer-events: all;
    }

    p {
        flex: 1;
        display: flex;
        margin: auto 15px auto 0;
    }

    .margin-auto {
        margin: auto;
    }

    .ant-checkbox-wrapper {
        @include themify() {
            color: themed("popupinfo-checkbox-wrapper-color") !important;
        }
    }

    .ant-checkbox-wrapper .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #fff !important;
    }

    .information-area {
        margin: auto 0;
        display: flex;

        @include themify {
            color: themed("popupinfo-text-color") !important;
        }

        .group-bulletin {
            margin-right: 25px;

            input.ant-input-number-input,
            input[type="number"],
            .ant-select-selection {
                background: $white !important;
                // color: inherit !important;
                color: #191d29;
            }
        }
    }

    .buttons-area {
    }

    &.popupInfo-small {
        flex-direction: column;
        width: 84%;
        right: 8%;

        .information-area {
            display: block;
            text-align: center;
            padding: 8px 0 15px;

            .checkbox {
                display: inline-block;
            }

            .message {
                display: inline-block;
                font-size: 15px;
            }
        }

        .buttons-area {
            display: block;
            text-align: center;

            .button {
                min-width: unset;
            }
        }
    }
}

//Collapse Border styles for All Collapses
.collapse-no-border {
    & > .ant-collapse-item > .ant-collapse-content {
        border-color: transparent;
    }
}

//استایل پاپ اور
.popover {
    padding: 0;

    .ant-popover-inner-content {
        padding: 0;
    }

    .accountinfo {
        min-width: 350px;
        padding: 10px 0 0;
        display: flex;
        justify-content: space-between;

        button {
            border: 1px solid $main-color;
            color: $main-color;
        }

        p {
            flex: 1;
        }

        &-right {
            float: right;
            color: #333;
            padding: 10px 10px 0;

            .icon {
                font-size: 18px;
                float: right;
            }
        }

        &-toolbar {
            float: left;
            padding: 7px 10px 5px;

            .circle-toolbar-icon {
                float: left;
                height: 25px;
                width: 25px;
                border: 1px solid #1d8cf8;
                text-align: center;
                line-height: 27px;
                font-size: 18px;
                border-radius: 50%;

                .icon {
                    color: #1d8cf8;
                }
            }
        }
    }

    .userinfo {
        @include gradient($gradient-cyan-left-color, $gradient-cyan-right-color, horizontalRight);
        padding: 5px 6px;
        display: flex;
        color: transparent;

        .img-wrap {
            position: relative;
            width: 185px;
            height: 140px;
            @include borderRadius(3%);
            border: 2px solid $box-classification;
            overflow: hidden;

            &-text {
                z-index: 0;
                width: 185px;
                height: 140px;
                position: absolute;
                top: 0;
                text-align: center;
                line-height: 140px;
                font-size: 2em;
            }

            .userinfo-avatar-loading {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                // width: 50px;
                // height: 50px;
                // @include borderRadius(50%);
            }
        }

        & > div {
            color: $white;
            margin: 2px;
            flex: 1;
        }

        .userinfo-data {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        & .major {
            flex: 1;
            margin: auto 10px auto 0;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 132px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        & .minor {
            // max-width: 60px;
            text-align: center;
            font-size: 1rem;
            margin: auto 10px auto 0;
            flex-direction: column;
            justify-content: center;
        }
    }

    .moreinfo {
        padding: 15px;
        border-bottom: 1px solid rgba($disabled-color, 0.3);
        width: 350px;
        text-align: justify;
    }

    .mediainfo {
        padding: 15px;

        div {
            display: flex;
            max-width: 330px;
            overflow: hidden;

            i {
                font-size: 2rem;
                color: $color-cyan;
            }

            p {
                flex: 1;
                text-align: left;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 15px;

                span {
                    background: white;
                    position: relative;
                    z-index: 3;
                    padding-right: 10px;
                    display: inline-block;

                    a {
                        overflow: hidden;
                        max-height: 2rem;
                        text-overflow: ellipsis;
                        max-width: 220px;
                        display: inline-block;
                    }
                }

                &:after {
                    position: absolute;
                    left: 0;
                    right: 10px;
                    content: "";
                    border-top: 1px dashed $disabled-color;
                    top: 48%;
                }
            }
        }
    }
}
.avatar-influence .avatar-influence-img {
    margin-right: 0 !important;
}
.user-avatar-circle {
    position: relative;
    width: 50px;
    height: 50px;
    @include borderRadius(50%);
    border: 1px solid $disabled-color;
    overflow: hidden;

    &-text {
        z-index: 0;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        text-align: center;
        line-height: 50px;
        font-size: 2em;
    }

    &-img {
        z-index: 1;
        width: 50px;
        height: 50px;
        position: relative;
        background: unset;
        z-index: 0;
    }

    & img {
        z-index: 1;
        width: 50px;
        height: 50px;
        position: relative;
        z-index: 0;
    }
}

// استایل برای اسگرول بار
.track.trackY {
    @include themify() {
        background-color: themed("scrollbar-bg-color") !important;
    }

    width: 2px !important;
    -webkit-transition: width 300ms;
    transition: width 300ms;

    &:hover {
        width: 8px !important;
    }
}

.thumb.thumbY,
.thumb.thumbX {
    @include themify() {
        background-color: themed("scrollbar-thumb-bg-color") !important;
    }
}

//notification overwrite
.Toastify {
    z-index: 9999999999 !important;
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;

    .Toastify__toast-container {
        padding: 0 !important;
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;

        &--bottom-center {
            bottom: 0 !important;
            left: 25% !important;
            right: 25% !important;
            width: auto;
        }

        .Toastify__toast {
            margin-bottom: 0 !important;
            padding: 15px !important;
            border-top-right-radius: 10px !important;
            border-top-left-radius: 10px !important;
            display: flex;

            &-body {
                flex: 1;
            }

            &--info {
                background: $main-color !important;
            }

            &--success {
                @include themify() {
                    background: themed("toast-success-bg-color") !important;
                }
            }

            &--warning {
                @include themify() {
                    background: themed("button-orange-border") !important;
                }
            }

            &--error {
                @include themify() {
                    background: themed("toast-error-bg-color");
                }
            }
        }
    }

    &__close-button {
        background: none;
        border: 0 none;
        transition: transform 0.5s;

        &:hover {
            cursor: pointer;
            transform: scale(1.25);
        }
    }
}

// برای کنترل عدم خروج نوار پروگرس ویدئو از کادر
.input-player {
    overflow-x: hidden;

    // نمایش تام لاین به صورت سطری
    .video-react-mouse-display {
        &::after {
            white-space: nowrap;
            min-width: fit-content;
        }
    }

    .video-react-play-progress {
        &::after {
            white-space: nowrap;
            min-width: fit-content;
        }
    }
}

// منوی سرچ در صفحه نتایج جستجو
span.search-box {
    width: 260px;
    border-radius: 15px;
    overflow: hidden;

    .ant-input-suffix {
        left: 0;
        right: auto !important;

        .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
            border-radius: 0;
            background: red;
        }
    }

    input.ant-input {
        padding-right: 15px !important;
        padding-left: 50px;
        @include borderRadius(0, 15px, 15px, 0);

        button {
            @include borderRadius(0);
        }
    }
}

.search-inner-collapse.popup-platform {
    .ant-collapse {
        border: 0;
    }

    .ant-collapse-item {
        border: 0;
        border-bottom-color: transparent;

        .ant-collapse-content {
            //   position: absolute;
            //   width: 40vw;
            //   left: 0;
            //   z-index: 1000;
            //   min-height: 250px;
            //   border-radius: 0 0 8px 8px;
            //   top: 40px;
            color: $white;

            //   border: 1px solid $disabled-btn;
            //   border-top: 0;
            @include themify() {
                background: themed("sidebar-backcolor");
            }

            overflow: visible;

            .ant-row {
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        .ant-collapse-header {
            float: left;
            padding: 0 !important;
            font-size: 1rem;
            margin-top: -3px;

            .anticon {
                font-size: $font-size-medium !important;
                right: auto !important;
                left: -20px !important;
            }
        }

        .ant-select-selection__rendered {
            color: white;
        }

        .ant-select-selection-selected-value {
            float: right;
        }

        .anticon-down.ant-select-arrow-icon {
            color: white;
        }
    }

    &--small {
        .ant-collapse-item {
            .ant-collapse-content {
                width: 20vw;
            }
        }
    }
}

.lbl-left {
    .ant-form-item-label {
        text-align: left !important;
        margin-left: 10px;
    }
}

.search-opstions {
    max-width: 900px;

    .ant-input-affix-wrapper .ant-input {
        z-index: 0;
    }
}

.kiosk-container {
    height: 100%;

    .image-gallery {
        height: 100%;

        .image-gallery-content {
            height: 100%;
            display: flex;
            flex-direction: column;

            .image-gallery-slide-wrapper {
                flex: 1;

                &::after {
                    content: " ";
                    height: 52px;
                    width: 100%;
                    background: rgba(7, 13, 23, 0.4);
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                .image-gallery-swipe {
                    height: 100%;

                    .image-gallery-slides {
                        height: 100%;

                        .image-gallery-slide {
                            height: 100%;
                            background-color: $mirage;

                            .image-gallery-image {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .kiosk-list {
        .kiosk-list-item {
            margin-bottom: 15px;
            padding: 15px 15px 5px;
            background: $color-primary12;
            border-radius: 6px;

            img {
                width: 100%;
                height: auto;
            }

            div {
                text-align: center;
                padding: 4px;
            }
        }
    }
}

// برداشتن دکمه اینپوت نامبر برای مرورگهای گوناگون
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $sidebar-backcolor inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $scroll-track !important;
}

.checkboxList-container {
    border-radius: 15px;

    .checkboxList {
        box-sizing: border-box;
        padding: 10px;
        width: 100%;

        & > label {
            display: block;
            margin: 7px 2px;
        }
    }
}

//Removing buttons from input boxes
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.fullScreen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 999 !important;
    transform: none !important;
}

.ltr {
    direction: ltr;
    text-align: left;
}

// .chart-tooltip{
//   background-color: rgba(59,89,152, 0.6) !important;
// }

.chart-tooltip-container {
    position: absolute;
    visibility: hidden;
    z-index: 8;
    transition: visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,
        top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: rgba(59, 89, 152, 0.6) !important;
    border: 1px solid rgba(59, 89, 152, 1) !important;
    border-radius: 11px !important;
    color: $white !important;
    font-size: $font-size-medium;
    font-family: $font-family-default;
    line-height: 20px;
    padding: 10px 10px 6px;
    left: 405px;
    top: 452px;
    box-shadow: unset !important;
}

.css-10nd86i {
    // select
    &.multi {
        > div {
            div:first-child {
                > div {
                    // background: transparent;
                }

                .css-xwjg1b {
                    @include themify() {
                        background-color: themed("select-selected-bg-color") !important;
                    }

                    border-radius: 10px;
                    overflow: hidden;

                    .css-12jo7m5 {
                    }

                    .css-1alnv5e {
                        padding: 0 0px 0 7px;

                        &:hover {
                            background-color: transparent;

                            svg {
                                fill: $pink;
                            }
                        }
                    }
                }
            }
        }
    }
}

.select-transparent {
    > div {
        @include borderRadius(30px);

        @include themify() {
            background: themed("select-bg-color");
        }

        // width: 300px;
        color: $white;
        overflow: hidden;
        margin: 0;
        border-color: $main-color;

        div {
            &:nth-child(1) {
                div {
                    @include themify() {
                        color: themed("select-selected-color");
                    }

                    padding-right: 5px;
                }

                + div {
                    span {
                        display: none;
                    }
                }

                .ant-select-selection__choice__remove {
                    line-height: 25px;
                    font-size: 14px;
                }
            }
        }

        svg {
            @include themify() {
                fill: themed("select-selected-icon-color");
            }
        }

        &:hover {
            border-color: $main-color;
            cursor: pointer;
        }
    }

    // span{
    //   + div{
    //     border-bottom: none !important;
    //     box-shadow: unset;
    //     @include borderRadius(15px, 15px, 0, 0 );
    //   }
    // }
    .css-kj6f9i-menu {
        box-shadow: none;
    }

    & > div:nth-child(3) {
        @include borderRadius(0, 0, 15px, 15px);

        @include themify() {
            border-top: 10px solid themed("select-bg-color");
            background: themed("select-bg-color");
        }

        border-left: 2px solid $main-color;
        border-right: 2px solid $main-color;
        border-bottom: 2px solid $main-color;
        overflow: auto;
        margin-top: -10px;

        & div {
            position: relative;
        }

        & div div {
            @include themify() {
                color: themed("select-value-color");
                background: themed("select-bg-color");
            }

            // &:nth-child(1){
            //   // border-top: 2px solid lighten( $sidebar-backcolor, 10% )
            // }
        }

        & div div:hover {
            // background: white;
            cursor: pointer;
            color: $main-color;
            margin-left: -4px;
        }
    }

    &.disabled {
        & div {
            background: transparent;
            border-color: $disabled-btn;
            color: $disabled-btn !important;

            span {
                background: $disabled-btn;
            }

            svg {
                fill: $disabled-btn !important;
            }
        }
    }

    > span + div {
        position: relative;
        z-index: 4;
        overflow: visible;
    }

    .ui-modal-body & {
        > div {
            background: white;

            div {
                &:nth-child(1) {
                    div {
                        color: $defaults-backcolor;
                        padding-right: 10px;
                    }
                }
            }

            svg {
                fill: $defaults-backcolor;
            }
        }

        & > div:nth-child(3) {
            @include borderRadius(0, 0, 15px, 15px);
            border-top: 0 solid $sidebar-backcolor;
            border-left: 1px solid $main-color;
            border-right: 1px solid $main-color;
            border-bottom: 1px solid $main-color;
            background: white;
            overflow: auto;
            margin-top: -5px;

            & div {
                position: relative;
            }

            & div div {
                color: $defaults-backcolor;
                background: white;
                // &:nth-child(1){
                //     // border-top: 2px solid lighten( $sidebar-backcolor, 10% )
                // }
            }

            & div div:hover {
                // background: white;
                cursor: pointer;
                color: $main-color;
                margin-left: -4px;
            }
        }
    }

    &.multi {
        .ant-select-selection__rendered {
            height: 100%;
        }

        > div {
            div:first-child {
                .css-xwjg1b {
                    background-color: $color-primary12;
                    border-radius: 10px;
                    overflow: hidden;

                    .css-1alnv5e {
                        padding: 0 0px 0 7px;

                        &:hover {
                            background-color: transparent;

                            svg {
                                fill: $pink;
                            }
                        }
                    }
                }
            }
        }

        .css-1hwfws3 {
            div {
                @include themify() {
                    background-color: themed("select-multi-tag-bg-color");
                }

                background-color: transparent;

                &:last-child {
                    background-color: transparent;
                    box-shadow: none !important;
                }
            }
        }
    }

    &.select-sm {
        margin: 0 4px;

        // & > div:nth-child(1){
        //   min-height: unset;
        //   height: 26px;
        // }
        .css-vj8t7z,
        .css-162g8z5,
        .css-2o5izw,
        .css-bg1rzq-control,
        .css-3esr4u-a11yText,
        .css-1szy77t-control {
            //
            min-height: unset;
            height: 30px;

            // height: 26px;
        }

        .css-2o5izw {
            // active mode
            min-height: unset;
            // height: 26px;
        }

        .css-bl6clz {
            // extra element on top inner
            display: none;
        }

        .css-1hwfws3 {
            // text
            line-height: 14px;
            font-size: 13px;
        }

        .css-16pqwjk-indicatorContainer {
            // arrow
            padding: 2px 5px;
        }

        .css-1thkkgx-indicatorContainer {
            // active arrow
            padding: 2px 5px;
        }

        .css-1g6gooi {
            line-height: 20px;
            font-size: 12px;
            height: 20px;
            margin-top: 0;
            padding-top: 0;
        }

        &.count-perpage-filter {
            display: inline-block;
            width: 160px;
        }

        &.count-perpage-paginate {
            display: inline-block;
            width: 65px;

            .css-bg1rzq-control {
                height: 25px;
            }
        }

        &.ant-select-sm {
            width: 160px;

            .ant-select-selection {
                min-width: unset;
                height: 30px;

                .ant-select-selection__rendered {
                    line-height: 27px;
                }
            }
        }
    }
}

.small-filters {
    .select-transparent {
        .css-vj8t7z,
        .css-162g8z5,
        .css-2o5izw {
            //
            min-height: unset;
            // height: 26px;
        }

        .css-2o5izw {
            // active mode
            min-height: unset;
            // height: 26px;
        }

        .css-bl6clz {
            // extra element on top inner
            display: none;
        }

        .css-1hwfws3 {
            // text
            line-height: 14px;
            font-size: 13px;
        }

        .css-1ep9fjw {
            // arrow
            padding: 2px 5px;
        }

        .css-1uq0kb5 {
            // active arrow
            padding: 2px 5px;
        }

        .css-1g6gooi {
            line-height: 20px;
            font-size: 12px;
            height: 20px;
            margin-top: 0;
            padding-top: 0;
        }
    }

    .ant-input {
        padding: 0 5px;
        height: 26px;
    }

    .ant-row-flex {
        margin: 5px 0;
    }

    .rangeslider-field {
        .ant-input-affix-wrapper {
            input {
                padding-left: 5px;
                padding-right: 15px;
                position: relative;
                z-index: 0;
            }

            .ant-input-prefix {
                right: 3px;
            }
        }
    }

    &.search-container {
        overflow: visible;
    }
}

.rangeslider-field {
    .ant-input-affix-wrapper .ant-input {
        z-index: 0;
    }
}

/*** Table ****/
.table-full-page {
    height: calc(100vh - 130px);
}

.clear-table-tree-padding {
    padding-left: 0 !important;
    padding-right: 20px !important;
}

.full-width {
    width: 100%;
}

.text {
    &--warning {
        color: $button-orange-filled;

        & i {
            font-size: 1.7rem;
        }
    }

    &--success {
        color: $main-color;

        & i {
            font-size: 1.7rem;
        }
    }
}

//planning alert box
.planning-alert-box {
    @include themify() {
        border: 0 solid themed("planning-alert-box-border-color");
    }

    border-radius: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &.ant-alert-error {
        @include themify() {
            background-color: themed("planning-alert-box-error-bg-color");
        }

        .ant-alert-icon {
            @include themify() {
                color: themed("planning-alert-box-error-icon-color") !important;
            }

            position: unset;
            margin-left: 10px;
            font-size: 20px;
        }

        &.ant-alert-closable {
            padding: 10px;

            .ant-alert-close-icon {
                position: initial;
                float: left;
            }
        }
    }

    &.ant-alert-success {
        @include themify() {
            background-color: themed("planning-alert-box-success-bg-color");
        }

        .ant-alert-icon {
            @include themify() {
                color: themed("planning-alert-box-success-icon-color") !important;
            }

            position: unset;
            margin-left: 10px;
            font-size: 20px;
        }

        &.ant-alert-closable {
            padding: 10px;

            .ant-alert-close-icon {
                position: initial;
                float: left;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    //.sidebar_bottom_button{line-height: 1em !important;}
}

@media (max-width: 1200px) {
    .inner-menu {
        .inner-menu-title {
            font-size: 17px !important;
            padding: 10px 0;
        }

        .button {
            // min-width: 140px !important;
            height: 35px;
            line-height: 1em !important;
            font-size: 12px;

            .plus {
                font-size: 25px !important;
            }
        }
    }

    .ant-table table .ant-table-tbody tr td {
        padding: 10px 8px;
    }
}

/* large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
    .sidebar_bottom_button {
        line-height: 1.3em !important;
    }

    .inner-menu {
        .button {
            min-width: 200px;
        }
    }

    a {
        &.button {
            &.sidebar_bottom_button {
                line-height: 25px !important;
            }
        }
    }
}

@media (max-width: 1600px) {
}

/* Extra large devices (large laptops and desktops, 1600px and up) */
@media (min-width: 1600px) {
    .sidebar_bottom_button {
        line-height: 2em !important;
    }
}

input.disabled {
    border-color: $color-secondary7 !important;
}

.mask-back {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 10;

    &.visible {
        display: block;
    }

    &.hidden {
        display: none;
    }
}

.text-mute {
    color: #757575 !important;
}

.collapse-general {
    .ant-collapse-item-active {
        .ant-collapse-arrow {
            transform: rotate(180deg) !important;
            transition: transform 0.3s linear !important;
        }
    }

    .ant-collapse-item {
        .ant-collapse-arrow {
            transition: transform 0.3s linear !important;
        }
    }
}

.select-with-circle-img {
    .ant-select-selection {
        border-radius: 50px;
        height: 42px;

        .ant-select-selection__rendered {
            margin-right: 0 !important;
            width: 100%;

            .ant-select-selection-selected-value {
                margin-right: 0;
                padding-right: 0;

                .generator-selected {
                    color: #333;
                    margin-right: 25px;
                }
            }

            .ant-select-selection__choice {
                height: unset;
                background-color: transparent !important;
                display: flex;
                align-items: center;
                margin-right: 0;
                padding-right: 0;
            }
        }

        .ant-avatar {
            margin-left: 10px;
            margin-right: -24px;
            position: absolute;
            width: 33px;
            height: 33px;
        }
    }
}

.planning-generator-select-with-circle-img-menu {
    .select-with-circle-img-item.circle-image {
        @include themify() {
            color: themed("planning-generator-select-platform-selected");
        }
    }
}

.select-with-circle-img-item {
    color: initial;

    &.circle-image {
        img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-left: 10px;
        }
    }
}

[disabled],
.btn[disabled] {
    cursor: no-drop !important;
    color: #a3a3a3 !important;

    * {
        color: #a3a3a3 !important;
    }
}

.count-perpage-paginate {
    &.ant-select-dropdown-menu-item {
        border-bottom: 0 !important;
    }

    &.ant-select-dropdown-menu-item-selected {
        color: #1d8cf8 !important;
    }
}
.ant-table table .ant-table-tbody tr td {
    user-select: text;
}

.image-gallery {
    .image-gallery-content {
        .image-gallery-slide-wrapper {
            .image-gallery-fullscreen-button {
                z-index: 1 !important;
            }
        }
    }
}
.time-ago{
    direction: rtl;
}
