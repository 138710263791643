.hashtag-mb-list {
  margin-top: -10px;
  .hashtag-ant-table {
    direction: rtl;
    list-style-type: none;
    background-color: transparent; /*rgba(47, 52, 71, 0.4)*/
    border-bottom: none;
    position: relative;
    color: $white;
    &:last-child {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    &.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    &:hover:not(.ant-table-expanded-row) > td,
    &.ant-table-row-selected td {
      background: red;
    }
  }

  .titleBG {
    @include themify() {
      background-color: themed("hashtag-header-bg-color") !important;
    }
    .title {
      @include themify() {
        color: themed("hashtag-header-color");
      }
    }
  }

  .paginationTopMargin {
    margin-top: 115px !important;
  }
  .header {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 12px;
  }
  .ant-table:first-child:before {
    background-color: transparent;
  }
  .ant-table {
    li {
      padding: 2px 0;
    }
  }
  .main {
    height: 100%;
  }
  .pagination-wrapper {
    bottom: -60px;
    position: relative;
  }
  .jump-text span {
    color: $white !important;
  }
  .row {
    padding-right: 10px;
    text-align: right;
    @include themify() {
      color: themed("hashtag-row-number-color");
    }
    &.row1 {
      @include themify() {
        color: themed("hashtag-title-color") !important;
      }
      text-align: right;
      display: flex;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &.row2 {
      text-align: left;
      padding-left: 20px;
      @include themify() {
        color: themed("hashtag-number-color");
      }
    }
  }
  .pointer {
    cursor: pointer;
  }

  .col {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 40px;
    font-size: 14px;
    padding-right: 10px;
    line-height: 40px;
    @include themify() {
      background: themed("hashtag-row-bg-color");
    }
    border-radius: 10px;
  }
}
