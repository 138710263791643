.ui-switch {
    display: flex;
    align-items: center;

    .ui-switch-input {
        height: 0 !important;
        width: 0 !important;
        visibility: hidden;
    }

    .ui-switch-toggle {
        cursor: pointer;
        text-indent: -9999px;
        width: 44px;
        height: 22px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
        &.ui-switch-disabled {
            cursor: not-allowed !important;
        }
        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            transition: 0.3s;
        }
        &:active:after {
            width: 20px;
        }
    }

    .ui-switch-input:checked + .ui-switch-toggle {
        background: #1890ff;
    }

    .ui-switch-input:checked + .ui-switch-toggle:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }
}
