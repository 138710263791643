.customized-toastify {
    &.Toastify__toast-container {
        &.Toastify__toast-container--bottom-left {
            margin-bottom: 40px !important;
        }
        .Toastify__toast {
            padding: 5px !important;
        }
    }
    .Toastify__toast {
        border-radius: 10px !important;
        opacity: 0.5;
        animation: zoomOut 3s !important;
        &:hover {
            opacity: 1 !important;
            animation-play-state: paused !important;
        }
    }
    .theme-light .Toastify .Toastify__toast-container .Toastify__toast--error {
        &:hover {
            opacity: 1;
        }
    }

    .ant-collapse-borderless {
        background-color: transparent !important;
    }

    .ant-collapse-content {
        color: #ffffff;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: #ffffff;
        &.ant-collapse-arrow {
            color: #ffffff;
        }
    }
    .toast-wrapper {
        padding-right: 10px;
        .ui-collapse-wrapper {
            .ui-collapse-header {
                align-items: normal;
                .icon {
                    &.icon-warning {
                        font-size: 20px !important;
                    }
                }
            }
        }
        .Toastify__close-button {
            margin-left: 4px;
            margin-top: 4px;
        }
    }
    @keyframes zoomOut {
        from {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        70% {
            opacity: 0.8;
        }
        to {
            opacity: 0.5;
        }
    }
    @-moz-keyframes zoomOut {
        from {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        70% {
            opacity: 0.8;
        }
        to {
            opacity: 0.5;
        }
    }
    @-webkit-keyframes zoomOut {
        from {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        70% {
            opacity: 0.8;
        }
        to {
            opacity: 0.5;
        }
    }
    @-ms-keyframes zoomOut {
        from {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        70% {
            opacity: 0.8;
        }
        to {
            opacity: 0.5;
        }
    }
    .icon-arrow-down,
    .icon-arrow-up {
        padding-top: 10px;
    }
}
.emoji-box-wrapper {
    .emoji-picker-react {
        height: 300px !important;
    }

    .emoji-search {
        margin: 30px 15px 10px 20px;
    }

    .skin-tones-list {
        top: 5px !important;
        right: 20px !important;
    }
}
