$themes: (light: (ui-menu-color: #243447,
        ui-menu-bg-color: #ffffff,
        ui-menu-item-hover-bg-color: #eceff1,
    ),
    dark: (ui-menu-color: #ffffff,
        ui-menu-bg-color: #2f3447,
        ui-menu-item-hover-bg-color: #1d2333,
    ),
);

@import "./../../assets/css/base/theme";





.ui-menu {

    .ui-tooltip-content {
        @include themify() {
            background-color: themed("ui-menu-bg-color") !important;
            color: themed("ui-menu-color") !important;
        }

        &:hover {
            @include themify() {
                background-color: themed("ui-menu-item-hover-bg-color") !important;
            }

            span {
                @include themify() {
                    background-color: themed("ui-menu-item-hover-bg-color") !important;
                }
            }
        }

        span {
            @include themify() {
                background-color: themed("ui-menu-bg-color") !important;
                color: themed("ui-menu-color") !important;
            }


        }

        padding: 0;

        &:after {

            @include themify() {
                border-color: themed("ui-menu-bg-color") transparent transparent transparent;
                box-shadow: 0 2px 8px #00000000;
            }

        }
    }

    .ui-menu-content {
        overflow: hidden;
        text-align: center;
        padding: 0;
        width: max-content;
        max-width: 500px;
        white-space: break-spaces;
        margin: 0;
        border-radius: 5px;
        list-style-type: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

        @include themify() {
            background-color: themed("ui-menu-bg-color");
            color: themed("ui-menu-color");
        }

        .ui-menu-item-content {
            display: flex;
            width: 100%;
            padding: 8px 12px;
            text-align: right;
            cursor: pointer;
            font-size: 14px;

            @include themify() {
                color: themed("ui-menu-color");
            }

            div {
                width: 100%;
            }

            &:hover {
                @include themify() {
                    background-color: themed("ui-menu-item-hover-bg-color");
                }
            }

            i {
                margin-left: 10px;
                position: relative;
                line-height: 20px;
                font-size: 18px;
                top: 3px;
                display: inline !important;

            }

        }
    }

    &.dark {
        .ui-menu-content {
            background-color: #2f3447;
            color: #ffffff;

            .ui-menu-item-content {
                color: #ffffff;

                &:hover {
                    background-color: #1d2333;
                }
            }
        }
    }

    &.light {
        .ui-menu-content {
            background-color: #ffffff;
            color: #243447;

            .ui-menu-item-content {
                color: #243447;

                &:hover {
                    background-color: #eceff1;
                }
            }
        }
    }

}