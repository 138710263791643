$themes: (
    light: (
        ui-label-color: #070d17,
    ),
    dark: (
        ui-label-color: #d1d1d1,
    ),
);

@import "./../../assets/css/base/theme";

.ui-label {
    display: flex;
    margin: 10px 0;
    font-size: 12px;
    @include themify() {
        color: themed("ui-label-color");
    }

    &.light {
        color: #070d17;
    }

    &.dark {
        color: #ffffff;
    }
    &.xs {
        font-size: 10px;
    }
    &.sm {
        font-size: 12px;
    }
    &.md {
        font-size: 14px;
    }
    &.lg {
        font-size: 18px;
    }
    &.xl {
        font-size: 22px;
    }
}
