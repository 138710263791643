.mb-list {
  display: grid;
  border-radius: 10px;
  @include themify() {
    background-color: themed("mb-list-bg-color");
  }
  .header {
    border-radius: 10px 10px 0 0;
    padding: 10px;
    background-color: $mirage;
    color: $white;
    .title {
      text-align: right;

      padding-right: 10px;
    }
  }

  .main {
    margin: 5px 5px 15px 14px;
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
      @include themify() {
        color: themed("scrollbar-bg-color") !important;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @include themify() {
        background: themed("scrollbar-thumb-bg-color") !important;
      }
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      @include themify() {
        background: themed("scrollbar-hover-bg-color") !important;
      }
    }
    .mb-body {
      display: flex;
      align-items: center;
      padding: 12px;
      color: $white;
      direction: rtl;
      text-align: right;
      .avatar {
        flex: 0.1;

        .samane-box--content--User-avatar {
          width: 50px;
          height: 50px;
          .user-avatar-circle-img {
            background-color: transparent;
            width: 100%;
              height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }

        .image {
          float: right;
        }
      }
      .info {
        // flex: 0.6;
        flex: 1;
        width: calc(50% - 50px);
        .text {
          margin: 0 10px;
          text-align: right;
          @include themify() {
            color: themed("mb-list-text-color");
          }
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.name {
            color: $main-color !important;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .resource {
        flex: 1;
        text-align: left;
        width: calc(50% - 50px);
        direction: ltr;
        a {
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $main-color !important;
          font-size: 14px;
          font-weight: bold;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
}
