@import './../../assets/css/base/variables';
@import './../../assets/css/base/mixins';
.carousel {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  display: flex;
  flex: 1 100%;
  align-items: center;

  .right,
  .left {
    line-height: 2.5;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    &.disable {
      display: none;
      cursor: unset !important;
      opacity: 0;
    //   display: none;
    
    }
    .icon {
      @include themify() {
        color: themed('carousel-icon-left-right') !important;
      }
      &::after {
        position: absolute;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        content: "";
        top: 0;
        left: 10%;
        width: 80%;
        height: 100%;
      }
    }
  }

  .main {
    width: 100%;
    overflow: hidden;
    .tag {
      margin-left: 10px;
      padding: 5px 10px;
      background-color: rgba(0, 0, 0, 0.5);
      line-height: 30px;
      * {
        display: inline;
      }
    }
    .scroll {
      transition: all 0.3s;
      display: block;
      white-space: nowrap;
      width: 100%;
    }
  }
}
