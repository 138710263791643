.mb-release {
    display: grid;
    border-radius: 15px;
    background-color: $color-primary12;
    .header {
        border-radius: 10px 10px 0 0;
        padding-bottom: 10px;
        background-color: $mirage;
        color: $white;
        .title {
            text-align: right;
            padding-right: 10px;
            font-size: 18px;
        }
        .title-detail {
            color: #000;
            text-align: left;
        }
        .icon {
            text-align: left;
            padding: 5px;
        }
    }

    & .wrapper-size .wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .main {
        margin: 10px 0px -10px 0px;
        overflow: auto;
        /* width */
        &::-webkit-scrollbar {
            width: 3px;
            @include themify() {
                color: themed("scrollbar-bg-color") !important;
            }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $white;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            @include themify() {
                background: themed("scrollbar-thumb-bg-color") !important;
            }
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            @include themify() {
                background: themed("scrollbar-hover-bg-color") !important;
            }
        }
        .mb-body {
            padding: 0 10px;
            margin-bottom: 25px;
            color: $white;
            direction: rtl;
            text-align: right;
            bdi.text {
                margin: 0 0 0 10px;
                &.name {
                    color: $chambray;
                }
            }
        }
    }
    .timeline {
        direction: ltr;
        padding-left: 10px;
        .ant-timeline-item-head {
            background-color: #3b5998;
            border: none;
            width: 8px;
            height: 8px;
            top: 4px;
            left: 1px;
        }
        .time-line-element {
            padding: 0px 0 30px 10px;
        }
        &.direction-rtl {
            direction: rtl;
        }
        .time {
            .ant-timeline-item-tail {
                border-left: 2px solid $chambray;
            }
        }

        .date {
            float: left;
            margin-top: -4px;
            color: $chambray;
            text-align: left;
            margin-left: -10px;
        }
        .image {
            float: right;
            margin-left: 8px;
        }
        .description {
            text-align: justify;
        }
    }
}

.white-template {
    width: 400px;
    background-color: white;

    .header {
        background-color: white;
        color: #1d8cf8;
    }
    hr {
        border-bottom: 1px solid #757575;
    }

    .timeline {
        .mb-body {
            color: #333;

            .image {
                border: 1px solid $dim-gray;
            }

            .title-row {
                bdi.text {
                    margin: 0 0 0 10px;

                    &.name {
                        color: $ebony;
                        font-size: 13px;
                    }

                    &.email {
                        color: $chambray;
                        font-size: 13px;
                    }
                }

                .time {
                    .ant-timeline-item-tail {
                        border-left: 2px solid #1d8cf8;
                    }
                }

                .date {
                    color: #1d8cf8;
                }
            }
            .description {
                color: #555;
                font-size: 14px;
            }

            // .description{
            //   color: #333;
            // }
        }
    }
}
