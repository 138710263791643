.ui-input-number {
    position: relative;
    .ui-input-number-btns {
        display: flex;
        flex-direction: column;
        color: #1d8cf8;
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 0;
        padding: 5px 0;
        justify-content: space-between;
        opacity: 0.2;

        &:hover,
        &:focus {
            opacity: 1;
        }

        .icon {
            display: flex;
            cursor: pointer;
        }
    }
    .ui-input {
        padding: 0 30px 0 10px !important;
        .ui-input-text{
            text-align: left;
        }
        &.dark {
            input[type="number"] {
                color: white !important;
            }
        }
        &.light {
            input[type="number"] {
                color: black !important;
            }
        }
    }
}
