@import "./../../assets/css/base/variables";
@import "./../../assets/css/base/mixins";

$themes: (light: (ui-tab-color: #243447,
        // ui-tab-bg-color:#ffffff,
        ui-tab-border-color: #d1d1d1,

        ui-tab-item-color: #243447,
        ui-tab-item-bg-color:#ffffff,
        ui-tab-item-hover-bg-color: #eceff1,
        ui-tab-content-border-color: #ffffff),
    dark: (ui-tab-color: #ffffff,
        ui-tab-bg-color: #2f3447,
        ui-tab-border-color: #1a1c26,

        ui-tab-item-color: #ffffff,
        ui-tab-item-bg-color: #2f3447,
        ui-tab-item-hover-bg-color: #1d2333,
        ui-tab-content-border-color: #2f3447),
);

.ui-tab {
    display: flex;
    flex-direction: column;

    &.line {
        .ui-tab-list {
            display: flex;
            justify-content: flex-start;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
            }

            @include themify() {
                color: themed("ui-tab-color");
                background-color: themed("ui-tab-bg-color");
                border-bottom: 2px solid themed("ui-tab-border-color");
            }

            .ui-tab-item {

                cursor: pointer;
                display: flex;
                padding: 15px 15px;

                &:hover {
                    @include themify() {
                        background-color: themed("ui-tab-item-hover-bg-color");
                    }
                }

                &.ui-tab-item-active {
                    border-bottom: 2px solid #1d8cf8;
                    opacity: 1;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

    &.card {
        padding: 10px 0px;

        .ui-tab-list {
            display: flex;
            justify-content: flex-start;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
            }

            .ui-tab-item {
                display: flex;
                cursor: pointer;
                border-radius: 5px 5px 0 0;
                line-height: 100%;

                .ui-tab-item-title {
                    padding: 10px 15px;
                }

                @include themify() {
                    color: themed("ui-tab-item-color");
                }

                &:hover {
                    @include themify() {
                        background-color: themed("ui-tab-item-hover-bg-color");
                    }
                }

                .ui-tab-item-remove {
                    margin: auto;

                    i {
                        font-size: 10px;
                        padding-left: 10px;
                    }

                }

                &.ui-tab-item-active {
                    @include themify() {
                        color: themed("ui-tab-item-color");
                        background-color: themed("ui-tab-item-bg-color");
                    }

                    opacity: 1;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }

        .ui-tab-content {
            padding: 15px;
            border-radius: 0 0 5px 5px;

            @include themify() {
                border: 3px solid themed("ui-tab-content-border-color");
            }
        }
    }



    .ui-tab-add {
        margin: auto 0;

        .icon {
            background-color: #1890ff;
            color: #fff;
            border-radius: 5px;
            height: 25px;
            width: 25px;
            display: flex;
            margin: 0 10px;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 5px 1px #1d8cf8;
            }
        }
    }

    &.light {
        &.line {
            .ui-tab-list {
                color: #243447 !important;
                background-color: #ffffff !important;
                border-bottom: 2px solid #d1d1d1 !important;

                .ui-tab-item {
                    &:hover {
                        background-color: #eceff1 !important;
                    }
                }
            }
        }

        &.card {
            .ui-tab-list {
                .ui-tab-item {
                    color: #243447 !important;

                    &:hover {
                        background-color: #eceff1 !important;
                    }

                    &.ui-tab-item-active {
                        color: #243447 !important;
                        background-color: #ffffff !important;
                    }
                }
            }

            .ui-tab-content {
                // color: #243447 !important;
                // background-color:  #ffffff;
                border: 3px solid #ffffff !important;
            }
        }
    }

    &.dark {
        &.line {
            .ui-tab-list {
                color: #ffffff !important;
                background-color: #2f3447 !important;
                border-bottom: 2px solid #1a1c26 !important;

                .ui-tab-item {
                    &:hover {
                        background-color: #1d2333 !important;
                    }
                }
            }
        }

        &.card {
            .ui-tab-list {
                .ui-tab-item {
                    color: #ffffff !important;
                    background-color: #1d2333 !important;
                    margin-left: 2px;

                    &.ui-tab-item-active {
                        color: #ffffff !important;
                        background-color: #2f3447 !important;
                    }

                    &:hover {
                        background-color: #1d2333 !important;
                    }
                }
            }

            .ui-tab-content {
                // color: #ffffff !important;
                // background-color: #2f3447;
                border: 3px solid #2f3447 !important;
            }
        }
    }

}