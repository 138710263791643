.sidebar{
    a{
        color: $white;
    }
    .buttons-container{
        position: absolute;
        bottom: 0;
        right: 0;
        &.set-distance{
            bottom: 23px;
            right: 7%;
            left: 7%;
        }
        &.row-btn{
            display: flex;
            flex-direction: column;
        }
    }
}