//btn structures varibale
//سیستم رنگی اولیه
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757575 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$grays: () !default;
// مقادیر پیش فرض رنگی که برای رنگ های مختلف مورد استافده قرار می گیرند
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    ),
    $grays
);
$blue: #1d8cf8 !default;
$orange: #ff7043 !default;
$colors: () !default;
// مقادیر پیش فرض رنگی که برای رنگ های مختلف مورد استافده قرار می گیرند
$colors: map-merge(
    (
        "blue": $blue,
        "orange": $orange,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
    ),
    $colors
);
$primary: $blue !default;
$secondary: $gray-600 !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$orange: $orange;
$theme-colors: (
    dark: (
        "primary": #1d8cf8,
        "success": #00e676,
        "secondary": #757575,
        "orange": #ff5722,
        "light": #fff,
        "danger": #b71c1c,
        "dark": #000,
    ),
    light: (
        "primary": #1d8cf8,
        "success": #00e676,
        "secondary": #757575,
        "orange": #ff5722,
        "light": #fff,
        "danger": #b71c1c,
        "dark": #000,
    ),
);
//color theme-color("primary") !default;
$component-active-bg: #1d8cf8 !default;
$link-color: #1d8cf8 !default;
$body-bg: $white !default;
$body-color: $gray-900 !default;
$yiq-text-dark: $gray-900 !default;
// برای حالت های رنگی
$theme-color-interval: 8% !default;
// متغیر های اولیه که باری سایز های مختلف کاریرد دارد
$btn-font-weight: 400 !default;
$line-height-base: 1.5 !default;
$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
// مقداری که برای تابع تعیین می کند در چه درجه ای برای تیرگی یا روشنی رنگی قارا گیرد مقادیر بین 0 تا 255
$yiq-contrasted-threshold: 150 !default;
// سفارشی کردن دکمه ها رنگ متن دکمه ها
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
$btn-link-disabled-color: $gray-600 !default;
//برای دکمه ها border radius
$btn-border-radius-default: 0.25rem !default;
$btn-border-radius-lg: 0.3rem !default;
$btn-border-radius-sm: 0.2rem !default;
$btn-border-radius: 3rem !default;
$btn-border-radius-left: 3rem 0 0 3rem !default;
$btn-border-radius-right: 0 3rem 3rem 0 !default;
// سایز نرمال دکمه ها
$btn-padding-y: 0.5rem !default;
$btn-padding-x: 3.9rem !default;
$btn-font-family: null !default;
$btn-font-size: $font-size-base !default;
$btn-line-height: $line-height-base !default;
$btn-block-spacing-y: 0.5rem !default;
$btn-heghit-normal: 1.5 !default;
//دکمه ها در سایز کوچک
$btn-padding-y-sm: 0.25rem !default;
$btn-padding-x-sm: 0.5rem !default;
$btn-font-size-sm: $font-size-sm !default;
$btn-line-height-sm: 1.5 !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-heghit-sm: 20px !default;

//دکمه ها در سایز بزرگ
$btn-padding-y-lg: 0.7rem !default;
$btn-padding-x-lg: 1.6rem !default;
$btn-font-size-lg: $font-size-lg !default;
$btn-line-height-lg: 0 !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-heghit-lg: 48px !default;
//متغیر های استافده شده برای حالن های focus , shadow ها
$enable-gradients: false !default;
$enable-shadows: false !default;
$btn-focus-width: 0.2rem !default;
$btn-focus-color: rgba($component-active-bg, 0.25) !default;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $btn-focus-color !default;
$btn-box-shadow: 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

@mixin themify() {
    transition: all 0.3s;
    transition-property: color, background;
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}
