@import "./../../../../../../../../assets/css/base/variables";
@import "./../../../../../../../../assets/css/base/mixins";
.planning-warning {
    width: 100%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, 60%);
    margin: 0;
    &.no-absolute {
        position: unset;
        transform: unset;
    }
    .ant-empty-image {
        height: unset;
        img {
            height: 250px;
            width: 334px;
        }
    }
    .ant-empty-description {
        @include themify() {
            color: themed("data-empty-text") !important;
        }
    }
}
