.notification--sidebar {
    position: fixed;
    left: 0;
    width: 440px;
    top: 60px;
    bottom: 0;
    background-color: $mirage;
    overflow: hidden;
    transition: all 0.4s;
    z-index: 90;
    .icon-close-icon {
        cursor: pointer;
    }
    .downloads-list {
        .ui-spin {
            width: auto;
        }
        .message-container {
            padding: 25px;
            .empty-messages {
                font-size: $font-size-larg;
                @include themify() {
                    color: themed("notification-empty-message") !important;
                }
            }
        }
    }
    &--contents {
        height: 100%;
        position: relative;
        z-index: 20;
    }
    .title {
        display: flex;
        align-items: center;
        font-size: $font-size-xxxlarg;
        color: $gray-light;
        padding: 25px 10px 0px 0px;
        .icon {
            color: $main-color;
            line-height: 26px;
            border: 2px solid $main-color;
            border-radius: 50%;
            height: 27px;
            width: 27px;
            text-align: center;
        }
        .icon-arrow-left {
            font-size: 25px;
            cursor: pointer;
        }
        .icon-delete {
            font-size: 18px;
            cursor: pointer;
        }
        .icon-alarm {
            font-size: 25px;
        }
        .icon-filter {
            font-size: 25px;
            line-height: 40px;
        }
        span:nth-child(2) {
            flex: 1;
        }
        i {
            margin-left: 10px;
        }
    }
    .download-status-box {
        background-color: $noti-dl-bg;
        padding: 10px;
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        @include borderRadius(5px);
        &.canceled {
            color: $color-secondary5;
        }
        & > .icon {
            font-size: 2rem;
        }
        .progress-header {
            display: flex;
            align-items: center;
            .icon {
                @include themify() {
                    color: themed("notification-empty-message");
                }
            }
            .ui-button-icon {
                color: #6a6c71 !important;
                position: absolute;
                left: 20px;
                top: 10px;
            }
            .type-text {
                color: silver;
            }
        }
        .text {
            flex: 1;
            padding-right: 5px;
            p {
                margin: 0;
            }
            @include themify() {
                color: themed("notification-empty-message") !important;
            }
        }

        .icon-pdf,
        .icon-csv,
        .icon-folders {
            font-size: 30px;
        }

        .icon-word-export {
            font-size: 30px;
        }

        .actions {
            display: flex;
            align-items: center;
            .icon-import,
            .iconreload,
            .iconclose-icon {
                font-size: 15px;
            }
            .actions-text {
                margin-right: 5px;
            }
            .noti-dl-compl {
                color: $noti-dl-compl;
            }
            .noti-dl-cancel {
                color: $noti-dl-cancel;
            }
            .noti-dl-reload {
                color: $noti-dl-reload;
            }
        }
        .progress-wrapper {
            flex-basis: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            @include themify() {
                color: themed("notification-empty-message") !important;
            }
            & .progress-bg {
                background-color: $defaults-searchbar;
                height: 5px;
                border-radius: 5px;
                overflow: hidden;
                flex: 1;
                margin: 5px 5px 0 7px;
            }
            & .progress-fg {
                background-color: $main-color;
                height: 5px;
                border-radius: 5px;
                overflow: hidden;
                &.full {
                    background-color: $noti-dl-compl;
                }
            }
            & .grey {
                background-color: $boston-blue !important;
            }
        }
        .progress-footer {
            flex-basis: 100%;
            display: flex;
            align-items: center;
            & .progress-bg {
                background-color: $defaults-searchbar;
                height: 5px;
                border-radius: 5px;
                overflow: hidden;
                flex: 1;
                margin: 5px 5px 0 7px;
            }
            & .progress-fg {
                background-color: $main-color;
                height: 5px;
                border-radius: 5px;
                overflow: hidden;
                &.full {
                    background-color: $noti-dl-compl;
                }
            }
            & .grey {
                background-color: $boston-blue !important;
            }
        }
    }
    &.hidden {
        left: -440px;
        .back-drop {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            display: none;
            transition: all 0.3s;
        }
    }
    &.visible {
        left: 0;
        border-top: 1px solid $main-color;
        .back-drop {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: all;
            display: block;
            transition: all 0.3s;
        }
    }
    .post-notification {
        margin: 10px;
        background-color: #2f3447;
        padding: 10px;
        border-radius: 5px;
        a,
        &:hover,
        &:active {
            border-bottom: none !important;
        }
        .calendar {
            display: flex;
            color: #9e9e9e;
            .right-section,
            .left-section {
                flex: 1;
            }

            .right-section {
                text-align: right;
            }
            .left-section {
                text-align: left;
                .icon-close-icon {
                    font-size: 18px;
                }
            }
        }
        .body {
            display: flex;
            align-items: center;
            .avatar {
                .username {
                    margin-right: 5px;
                }
            }
            .other {
                text-align: left;
                display: flex;
                align-items: center;
                * {
                    flex: 1;
                    color: #8dc7ff;
                }
                .icon-alarm {
                    font-size: 23px;
                    margin-left: 5px;
                    color: #1d8cf8;
                }
            }
            div {
                flex: 1;
            }
        }
        .title {
            font-size: 14px;
        }
        .assign-notif-body {
            color: #eceff1;
            .prewrap {
                white-space: pre-wrap;
            }
            
            .icon {
                font-size: 29px;
                margin-left: 5px;
                color: #707070;
            }
            .assign {
                display: flex;
                .assign-by {
                    color: #b5b5b5;
                }
            }
            .row {
                display: flex;
                margin-bottom: 5px;
                margin-top: 5px;
                .label {
                    color: #b5b5b5;
                    margin-left: 20px;
                }
            }
            .description {
                color: #b5b5b5;
            }
            .view {
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }
    .wave-alarm-notification {
        margin: 10px;
        background-color: #2f3447;
        padding: 10px;
        border-radius: 5px;
        a,
        &:hover,
        &:active {
            border-bottom: none !important;
        }
        .calendar {
            display: flex;
            color: #9e9e9e;
            .right-section,
            .left-section {
                flex: 1;
            }

            .right-section {
                text-align: right;
            }
            .left-section {
                text-align: left;
                .icon-close-icon {
                    font-size: 18px;
                }
            }
        }
        .wave-title {
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .body {
            color: #eceff1;
            display: flex;
            .wave-level {
                flex: 1;
                align-self: center;
            }
        }
    }
}

//اواتار و اطلاعات مشتری
.ant-popover-content {
    .ant-popover-arrow {
        @include themify() {
            background-color: themed("header-profile-modal-arrow-bg-color") !important;
        }
        width: 22px;
        height: 16px;

        &:after {
            border-bottom-color: #2f3447 !important;
            background-color: #2f3447 !important;
        }
    }
}
//
.reciving-noti {
    &::before {
        content: "";
        background: rgba(255, 87, 34, 0.18);
        animation-name: reciving-noti-anim;
        animation-iteration-count: infinite;
        animation-duration: 0.8s;
        animation-timing-function: ease-out;
        animation-direction: alternate;
        z-index: -1;
        // animation-delay: 0.2s;
    }
}
@keyframes reciving-noti-anim {
    0% {
        transform: scale(0.6, 0.6);
        // background: rgba(256, 0, 0, 0.0);
    }
    100% {
        transform: scale(1.2, 1.2);
        // background: #c00;
    }
}

// $noti-bg : #61636A;
// $noti-dl-bg : $ebony-clay;
// $noti-dl-prog : $main-color;
// $noti-dl-compl : #00E676;
// $noti-dl-cancel : $box-warning-color;
